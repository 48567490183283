<div class="dataset-wrapper">
    <div class="spinner-wrapper" *ngIf="!datasetList">
        <mat-spinner color="primary"></mat-spinner>
    </div>
    <div class="main" *ngIf="datasetList">
        <div class="flex-between">
            <mat-form-field class="main-template-search">
                <input class="inner-search-bar" [(ngModel)]="searchText" (input)="filterDatasets()" matInput type="text"
                    placeholder="Search for dataset" />
                <mat-icon (click)="closeSearch()" class="close-button">{{(!searchText)?'search':'close'}}</mat-icon>
            </mat-form-field>

            <div class="create-btn gap-2">
                <button mat-flat-button color="primary" (click)="openCreateDialog(DatasetsTypes.DATASET)">
                    <mat-icon class="text-lg">add</mat-icon> Dataset
                </button>
            </div>
        </div>

        <div class="dataset-container">
            <p class="flex justify-center items-center w-full text-2xl font-bold" *ngIf="!datasetList.length">No
                Datasets Found</p>
            <ng-container *ngFor="let dataset of datasetList">
                <div [ngClass]="(dataset.Type === DatasetsTypes.PIPELINE_CHOOSER) ? 'full-card chooser-color' : 'half-card dataset-card-media'" class="dataset-card" (click)="navigateToSchema(dataset)">
                    <div class="left">
                        <div class="dataset-name"> {{ dataset?.Title }} </div>
                        <div class="dataset-email">
                            {{ dataset?.EmailAlias }}
                            <mat-icon class="copy-icon" *ngIf="!dataset.IsCopied" (click)="copyEmail(dataset ,$event)"
                                matTooltip="Copy">content_copy</mat-icon>
                            <mat-icon class="copy-icon" *ngIf="dataset.IsCopied">check</mat-icon>
                        </div>
                    </div>
                    <div class="right">
                        <div class="badge">
                            <p>PROCESSED</p>
                            <span [ngClass]="{'gray': !dataset?.ProcessedDocs}" class="badge-proccessed" #tooltip="matTooltip" matTooltip="Documents Processed"
                                matTooltipPosition="above">
                                {{ dataset?.ProcessedDocs || "0" }}
                            </span>
                        </div>
                        <div class="badge">
                            <p>QUEUED</p>
                            <span [ngClass]="{'gray': !dataset?.ProcessingDocs}" class="badge-queued" #tooltip="matTooltip" matTooltip="Documents Processing"
                                matTooltipPosition="above">
                                <p class="parsed">
                                    {{ dataset?.ProcessingDocs || "0" }}
                                </p>
                            </span>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>
