
import { Injectable } from '@angular/core';
import { AbstractNavigationItemsService, Navigation } from '@discoverer/app-core';
import { FuseNavigationItem } from '@discoverer/fuse/components/navigation';
import { Observable, Subject, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { cloneDeep } from 'lodash';

export const navItems: FuseNavigationItem[] = [
    {
        id: 'configuration',
        title: 'Configuration',
        type: 'group',
        icon: '',
        children: [
            {
                id: 'schema',
                title: 'Schema',
                type: 'basic',
                icon: 'heroicons_outline:chart-bar',
                link: 'schema/:id',
            }
            ,
            {
                id: 'action',
                title: 'Action',
                type: 'basic',
                icon: 'heroicons_outline:action',
                link: 'action/:id',
            }
        ]
    },
    {
        id: 'pipeline',
        title: 'pipeline',
        type: 'group',
        icon: '',
        children: [
            {
                id: 'queue',
                title: 'Queue',
                badge: {
                    title: '',
                    classes: 'ai-badge'
                },
                type: 'basic',
                icon: 'heroicons_outline:clock',
                link: 'queue/:id',
            },
            {
                id: 'results',
                title: 'Results',
                badge: {
                    title: '',
                    classes: 'ai-badge'
                },
                type: 'basic',
                icon: 'feather:database',
                link: 'results/:id',
            }
        ]
    },
];

const queueFilter = {
    "type": "facet",
    "fields": [
        "DatasetKey"
    ],
    "filters": [],
    "filterMode": "All",
    "hidden": true,
    "openFacet": false,
    "expression": []
};

const queueStatusFilter = {
    "type": "facet",
    "fields": [
        "Status"
    ],
    "filters": [],
    "filterMode": "All",
    "hidden": true,
    "openFacet": false,
    "expression": []
};
const query = {
    "start": 0,
    "size": 1,
    "filters": [],
    "facets": [],
    "stats": [],
    "sorts": [],
    "fields": [],
    "groups": [],
    "dataFlattenBy": "",
    "countSubRecords": false
}


@Injectable()
export class NavigationItemsService extends AbstractNavigationItemsService {

    currentItems
    private navigation$: Subject<Navigation> = new Subject<Navigation>();
    constructor(
        private HttpClient: HttpClient
    ) {
        super()
    }

    private async _getDataLengthForTab(tabId: string, query: any, method: string) {
        const repo = (await this.HttpClient.post(
            `api/parsed-search/${method}/docpipeline/${tabId}?data=grid-${tabId}&qname=grid`,
            query).toPromise()) as any
        if (repo?.response?.numFound) {
            return repo.response.numFound
        }
        return 0
    }

    public override async loadItems(datasetId: string): Promise<Navigation> {
        try {
            this.currentItems = cloneDeep(navItems)
            const queueProcessingQuery = Object.assign({}, query,
                {
                    filters: [{
                        ...queueFilter,
                        expression: [datasetId]
                    },
                    {
                        ...queueStatusFilter,
                        expression: [datasetId]
                    }]
                })

            const queueLength = await this._getDataLengthForTab('queue', queueProcessingQuery, 'SearchData')
            const resultLength = await this._getDataLengthForTab(datasetId, query, 'SearchResults')

            this.currentItems.forEach(item => {
                item.children.forEach((x: FuseNavigationItem) => {
                    x.link = x.link.replace(`:id`, datasetId);
                    if (x.id === 'queue') {
                        x.badge.title = `${queueLength}`
                    } else if (x.id === 'results') {
                        x.badge.title = resultLength
                    }
                })
            })

            const nav = { mainTitle: 'Parsd', items: this.currentItems };
            this.navigation$.next(nav)
            return { mainTitle: 'Parsd', items: this.currentItems }
        } catch (error) {
            this.currentItems.forEach(item => {
                item.children.forEach((x: FuseNavigationItem) => {
                    x.link = x.link.replace(`:id`, datasetId);
                    if (x.id === 'queue') {
                        x.badge.title = `0`
                    } else if (x.id === 'results') {
                        x.badge.title = "0"
                    }
                })
            })
            this.navigation$.next({ mainTitle: 'Parsd', items: this.currentItems })
            return { mainTitle: 'Parsd', items: this.currentItems }
        }
    }

    public override getNavigationItems(): Observable<Navigation> {
        return of({ mainTitle: 'Doc Pipeline', items: this.currentItems })
    }
}

