export enum IntegrationType {
    Webhook = 1,
    Make = 2,
    Zapier = 3

}

export enum FileViewerType {
    Normal = 1,
    Downloaded = 2,
    Nested = 3,
    Custom = 4
}

export enum NodeType {
    file = 'file',
    folder = 'folder'

}

export enum FileStatusEnum {
    PROCESSING = 'PROCESSING',
    PROCESSED = 'PROCESSED',
    FAILED = 'FAILED'
}