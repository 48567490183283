import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';


@Component({
    selector: 'app-document-header-navigation',
    templateUrl: './document-header-navigation.component.html',
    styleUrls: ['./document-header-navigation.component.scss']
})
export class DocumentHeaderNavigationComponent implements OnInit {

    @Input() isEditMode :boolean;
    @Input() start: number = 0;
    @Input() total: number = 0;

    @Output() moveToPrevious = new EventEmitter<void>();
    @Output() moveToNext = new EventEmitter<boolean>();

    constructor() {
    }
    public async ngOnInit() {

    }
    onMoveToPrevious() {
        this.moveToPrevious.emit();
    }
    onMoveToNext() {
        this.moveToNext.emit();
    }


}
