import { IntegrationType } from "./enums";

export interface IIntegrationDetailsDialogData {
    id: string;
    datasetId: string;
    typeId: IntegrationType;

}

export class IntegrationModel {
    id: string;
    datasetId: string;
    imageUrl: string;
    typeId: IntegrationType;
    name: string;
    apiToken: string;
    isActive: boolean;
    configuration: IIntegrationConfigurationModel;
}

export interface IIntegrationConfigurationModel {


}

export class WebhookConfigurationModel implements IIntegrationConfigurationModel {
    post_url: string;
    headers: [{ Key: string, Value: object }]
} 