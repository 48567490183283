import { Injectable } from '@angular/core';
import { EditorState, Plugin, PluginKey } from 'prosemirror-state';
import { Decoration, DecorationSet } from 'prosemirror-view';

@Injectable({
    providedIn: 'root'
})

export class MentionsPlugin {
    private pluginKey = new PluginKey('autosuggestions');


    private getRegexp(mentionTrigger: string, hashtagTrigger: string, allowSpace: boolean) {
        const mention = allowSpace ? new RegExp(`(^|\\s)${mentionTrigger}([\\w-\\+]*\\s?[\\w-\\+]*)$`) : new RegExp(`(^|\\s)${mentionTrigger}([\\w-\\+]*)$`);
        const tag = new RegExp(`(^|\\s)${hashtagTrigger}([\\w-]*)$`);
        return { mention, tag };
    }

    private getMatch($position, opts) {
        const parastart = $position.before();
        const text = $position.doc.textBetween(parastart, $position.pos, "\n", "\0");
        const regex = this.getRegexp(opts.mentionTrigger, opts.hashtagTrigger, opts.allowSpace);
        const mentionMatch = text.match(regex.mention);
        const tagMatch = text.match(regex.tag);
        const match = mentionMatch || tagMatch;

        let type;
        if (mentionMatch) type = "mention";
        else if (tagMatch) type = "tag";
        if (match) {
            match.index = match[0].startsWith(" ") ? match.index + 1 : match.index;
            match[0] = match[0].startsWith(" ") ? match[0].substring(1) : match[0];
            const from = $position.start() + match.index;
            const to = from + match[0].length;
            const queryText = match[2];
            return { range: { from, to }, queryText, type };
        }
    }

    private debounce(func, timeout, context) {
        let timeoutId: ReturnType<typeof setTimeout> | undefined;
        return function () {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => func.apply(context || this, arguments), timeout);
            return timeoutId;
        };
    }

    private getNewState() {
        return {
            active: false,
            range: { from: 0, to: 0 },
            type: "",
            text: "",
            suggestions: [],
            index: 0
        };
    }

    public getMentionsPlugin(opts) {
        const defaultOpts = {
            mentionTrigger: "@",
            hashtagTrigger: "#",
            allowSpace: true,
            getSuggestions: (type, text, cb) => cb([]),
            getSuggestionsHTML: items => `<div class="suggestion-item-list">${items.map(i => `<div class="suggestion-item">${i.name}</div>`).join("")}</div>`,
            activeClass: "suggestion-item-active",
            suggestionTextClass: "prosemirror-suggestion",
            maxNoOfSuggestions: 10,
            delay: 500
        };
        opts = { ...defaultOpts, ...opts };
        let showListTimeoutId: ReturnType<typeof setTimeout> | undefined;
        const el = document.createElement("div");

        const showList = (view, state, suggestions, opts) => {
            el.innerHTML = opts.getSuggestionsHTML(suggestions, state.type);
            if (suggestions.length) {
                el.querySelectorAll(".suggestion-item").forEach((itemNode, index) => {
                    itemNode.addEventListener("click", () => {
                        select(view, state, opts);
                        view.focus();
                    });
                    itemNode.addEventListener("mouseover", () => setIndex(index, state, opts));
                    itemNode.addEventListener("mouseout", () => setIndex(index, state, opts));
                });
                addClassAtIndex(state.index, opts.activeClass);
                const node = view.domAtPos(view.state.selection.$from.pos);
                const paraDOM = node.node;
                const textDOM = paraDOM.querySelector(`.${opts.suggestionTextClass}`);
                const offset = textDOM.getBoundingClientRect();
                document.body.appendChild(el);
                el.classList.add('suggestion-item-container');
            } else {
                document.body.appendChild(el);
                el.classList.add('suggestion-item-container');
            }

            // el.style.position = "fixed";
            // el.style.left = `${offset.left}px`;
            // el.style.top = `${textDOM.offsetHeight + offset.top}px`;
            // el.style.display = "block";
            // el.style.zIndex = "999999";
        };

        const hideList = () => {
            el.style.display = "none";
        };

        const removeClassAtIndex = (index, className) => {
            const suggestionList = el.querySelector(".suggestion-item-list");
            if (!suggestionList) return;
            const itemList = suggestionList.childNodes;
            const prevItem = itemList[index] as HTMLElement;
            prevItem.classList.remove(className);
        };

        const addClassAtIndex = (index, className) => {
            const suggestionList = el.querySelector(".suggestion-item-list");
            if (!suggestionList) return;
            const itemList = suggestionList.childNodes;
            const prevItem = itemList[index] as HTMLElement;

            prevItem.classList.add(className);
        };

        const setIndex = (index, state, opts) => {
            removeClassAtIndex(state.index, opts.activeClass);
            state.index = index;
            addClassAtIndex(state.index, opts.activeClass);
        };

        const goNext = (view, state, opts) => {
            removeClassAtIndex(state.index, opts.activeClass);
            state.index++;
            state.index = state.index === state.suggestions.length ? 0 : state.index;
            addClassAtIndex(state.index, opts.activeClass);
            scrollIntoView(state.index);
        };

        const goPrev = (view, state, opts) => {
            removeClassAtIndex(state.index, opts.activeClass);
            state.index--;
            state.index = state.index === -1 ? state.suggestions.length - 1 : state.index;
            addClassAtIndex(state.index, opts.activeClass);
            scrollIntoView(state.index);
        };

        const scrollIntoView = (index) => {
            const item = el.querySelectorAll(".suggestion-item")[index] as HTMLElement;
            const container = item.parentElement;
            const containerRect = container.getBoundingClientRect();
            const itemRect = item.getBoundingClientRect();

            if (itemRect.top < containerRect.top) {
                container.scrollTop -= (containerRect.top - itemRect.top);
            } else if (itemRect.bottom > containerRect.bottom) {
                container.scrollTop += (itemRect.bottom - containerRect.bottom);
            }
        };

        const select = (view, state, opts) => {
            const item = state.suggestions[state.index];
            const attrs = state.type === "mention" ? { name: item.display, id: item.tag, email: item.key } : { promptDisplay: item?.promptDisplay, field: item?.tag };
            const node = view.state.schema.nodes[state.type].create(attrs);
            const tr = view.state.tr.replaceWith(state.range.from, state.range.to, node);
            view.dispatch(tr);
        };

        return new Plugin({
            key: this.pluginKey,
            state: {
                init: () => this.getNewState(),
                apply: (tr, state) => {
                    const newState = this.getNewState();
                    const selection = tr.selection;
                    if (selection.from !== selection.to) return newState;
                    const $position = selection.$from;
                    const match = this.getMatch($position, opts);
                    if (match) {
                        newState.active = true;
                        newState.range = match.range;
                        newState.type = match.type;
                        newState.text = match.queryText;
                    }
                    return newState;
                }
            },
            props: {
                handleKeyDown: (view, e) => {
                    let state = this.pluginKey.getState(view.state);
                    if (!state.active && !state.suggestions.length) return false;
                    const down = e.keyCode === 40;
                    const up = e.keyCode === 38;
                    const enter = e.keyCode === 13;
                    const esc = e.keyCode === 27;
                    const tab = e.keyCode === 9;
                    if (down) {
                        goNext(view, state, opts);
                        return true;
                    } else if (up) {
                        goPrev(view, state, opts);
                        return true;
                    } else if (enter || tab) {
                        select(view, state, opts);
                        return true;
                    } else if (esc) {
                        clearTimeout(showListTimeoutId);
                        hideList();
                        state = this.getNewState();
                        return true;
                    } else {
                        return false;
                    }
                },
                decorations: (editorState) => {
                    const { active, range } = this.pluginKey.getState(editorState);
                    if (!active) return null;
                    return DecorationSet.create(editorState.doc, [Decoration.inline(range.from, range.to, { nodeName: "span", class: opts.suggestionTextClass })]);
                }
            },
            view: () => ({
                update: view => {
                    const state = this.pluginKey.getState(view.state);
                    if (!state.active) {
                        hideList();
                        clearTimeout(showListTimeoutId);
                        return;
                    }
                    showListTimeoutId = this.debounce(() => {
                        opts.getSuggestions(state.type, state.text, (suggestions: any) => {
                            state.suggestions = suggestions;
                            showList(view, state, suggestions, opts);
                        });
                    }, opts.delay, this)();
                }
            })
        });
    }

    getState(state: EditorState) {
        return this.pluginKey.getState(state);
    }
}