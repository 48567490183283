
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { debounceTime, first, switchMap, takeUntil } from 'rxjs/operators';
import { FacetFieldAccumulator } from '../classes/facet-field-accumulator.class';
import { LoadingState } from '../classes/loading-state';
import { DiscovererDataService } from './discoverer-data-service.service';
import { DiscovererQueryService } from './discoverer-query-service.service';

function getLastFillStartLessThanOrEqual(arr: CachedRequest[], value: number): number {
    for (let i = arr.length - 1; i >= 0; i--) {
        if (arr[i].fillStart <= value) {
            return arr[i]?.start || 0;
        }
    }
    return 0; // If no match is found
}

class CachedRequest {
    start: number;
    fillStart: number;
    filled: boolean;
    requestedOn: Date;
}

@Injectable()
export class CachedIgniteDataService<T> implements OnDestroy {

    public oData: Observable<T[]>;
    protected _sData: Subject<T[]>;

    public oFacetResults: Observable<FacetFieldAccumulator[]>;
    public oResultLength: Observable<number>;
    public oLoadingStatusResult: Observable<LoadingState>;

    protected _bsLoadingStatus$: BehaviorSubject<LoadingState>;



    protected _cache: any[] = [];
    protected _cacheRequests: CachedRequest[] = [];

    protected _currentPage: number = 0;
    protected _fetchSize: number = 0;

    protected _requestStart: number = 0;
    protected _requestSize: number = 20;
    protected _unsubscribeAll: Subject<any> = new Subject<any>();
    private get hasFlatten() {
        return !!this.dataService.baseQueryService.getMergedDataFlattenBy();
    }


    constructor(public dataService: DiscovererDataService<T>) {
        this._sData = new ReplaySubject(1);
        this.oData = this._sData.asObservable();

        this._bsLoadingStatus$ = new BehaviorSubject<LoadingState>({ status: "Busy" });
        this.oLoadingStatusResult = this._bsLoadingStatus$.asObservable();

        this.oFacetResults = dataService.oFacetResults;
        this.oResultLength = dataService.oResultLength;
        this.dataService.enabled = false;


        this.dataService.oData
            .pipe(takeUntil(this._unsubscribeAll)).subscribe(async data => {
                var resultLength = await this.dataService.oResultLength.pipe(first()).toPromise();
                var matchRequest = this._cacheRequests.find(x => x.start === (this.dataService.start - 1));
                if (!!matchRequest) {
                    matchRequest.filled = true;
                }
                const filleStart = matchRequest?.fillStart || 0;
                for (let i = 0; i < this._fetchSize; i++) {
                    this._cache[i + filleStart] = data[i];
                }

                if (resultLength === -1) {
                    var cacheTotal = this._cache.filter(x => !!x)?.length;
                    var length = Math.max(cacheTotal, (data.length + filleStart));
                    this.dataService.setResultLength(-1 * (length));
                }
                else if (data.length === 0) {
                    this.dataService.setResultLength(data.length + filleStart);
                } else {
                    this.dataService.setResultLength(resultLength);
                }

                this.refresh();

            });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(true);
    }
    public init(serviceUrl: string, queryService: DiscovererQueryService, name?: string) {
        this.dataService.init(serviceUrl, queryService, name);
        this.dataService.enabled = false;


        this.dataService.baseQueryService.oQuery
            .pipe(debounceTime(0), takeUntil(this._unsubscribeAll)).subscribe(s => {
                this.refresh(true)
            });
        this._cache = Array.apply(null, Array(5000))
        this.resetCache();

        this.dataService.oLoadingStatusResult.pipe(takeUntil(this._unsubscribeAll)).subscribe(state => {
            this._bsLoadingStatus$.next(state);
        });
    }

    public resetCache() {
        this._requestStart = 0;
        this._currentPage = 0;
        this._fetchSize = 40;
        this._requestSize = 40;
        this._cacheRequests = [];
    }
    public async initCacheLength(length: number) {
        this._cache.length = length || 0;
    }

    public setPageParams(pageNumber: number, pageSize: number) {
        this.setPageStart((pageSize * (pageNumber - 1)), pageSize);
    }

    public setPageStart(start: number, pageSize: number) {
        console.log(';;; start=' + start + ', pageSize=' + pageSize);

        this._requestStart = start;
        this._requestSize = pageSize;
    }

    public refresh(refreshOldPage: boolean = false) {

        let fillStart = this._requestStart < 0 ? 0 : this._requestStart;

        let sizeOfItems = this._requestStart + this._requestSize;
        sizeOfItems = Math.min(this._cache.length, sizeOfItems);
        if (sizeOfItems > 0) {
            const cacheData = this._cache.slice(this._requestStart, sizeOfItems);
            const firstNull = cacheData.findIndex(x => !x);
            if (firstNull < 0) {
                this._handleFullCacheHit(cacheData, fillStart, refreshOldPage);
            } else if (firstNull > 0) {
                this._handlePartialCacheHit(cacheData, firstNull, fillStart, refreshOldPage);
            } else {
                // Clear the _sData
                this._sData.next([]);
                this.fetchNextPage(fillStart);
            }
        }

    }
    private _handlePartialCacheHit(cacheData, firstNull, fillStart, refreshOldPage) {
        // Partial cache hit: Push partial data and fetch the remaining
        this._sData.next(cacheData.slice(0, firstNull));
        fillStart = firstNull + this._requestStart;
        this._setCorrectFlattenByPage(refreshOldPage);
        // Cache miss, fetch the next page
        (this.hasFlatten && this._currentPage > 0) && this.fetchNextPage(fillStart);
    }
    private _handleFullCacheHit(cacheData: any[], fillStart: number, refreshOldPage: boolean) {
        this._sData.next(cacheData);
        !this.hasFlatten && this.fetchNextPage(fillStart);
        this._setCorrectFlattenByPage(refreshOldPage);
    }

    private _setCorrectFlattenByPage(refreshOldPage: boolean) {
        if (refreshOldPage) {
            const pageStart = getLastFillStartLessThanOrEqual(this._cacheRequests, this._requestStart);
            this.dataService.setPageStart(pageStart + 1, this._fetchSize);
            this.dataService.refresh();
        }
    }
    private fetchNextPage(fillStart: number) {
        if (!this.hasFlatten) {
            this._currentPage = fillStart > 0 ? Math.ceil(fillStart / this._fetchSize) : 0;
        }
        const fetchStart = this._currentPage * this._fetchSize;

        if (!!this._cacheRequests.find(x => x.fillStart === fillStart))
            return;

        this.dataService.setPageStart(
            fetchStart + 1,
            this._fetchSize
        );
        if (this.hasFlatten) {
            this._currentPage++;
        }
        this._cacheRequests.push({ start: fetchStart, fillStart: fillStart, filled: false, requestedOn: new Date() });
        this.dataService.refresh();
    }
}

