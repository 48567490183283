import { Component, OnInit } from '@angular/core';
import { DatasetService } from '../../services/api/dataset.service';
import { ActivatedRoute } from '@angular/router';
import { ToolbarService } from '../../services/toolbar.service';
import { EmitterService } from '../../services/emitter.service';
import { DatasetTypes } from '../../models/dataset.model';

@Component({
    selector: 'app-schema-handler',
    templateUrl: './schema-handler.component.html',
    styleUrls: ['./schema-handler.component.scss']
})
export class SchemaHandlerComponent implements OnInit {
    DatasetsTypes = DatasetTypes;

    constructor(private _activated: ActivatedRoute, private _toolbarService: ToolbarService, private _emitterService: EmitterService, public datasetService: DatasetService) { }

    async ngOnInit() {
        const datasetId = this._activated.snapshot.params['datasetId'];
        this._emitterService.setDatasetId(datasetId);
        await this._toolbarService.setSettings(datasetId, this._activated.snapshot.routeConfig.data)
    }

}
