import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { Field } from '../helpers/docpipeline-function';
import { DatasetService } from './api/dataset.service';

@Injectable({ providedIn: 'root' })
export class SchemaService {
    private _currentSchema$ = new ReplaySubject<({ [key: string]: Field } |  any[])>(1);
    public oCurrentSchema = this._currentSchema$.asObservable();

    constructor(private datsetService: DatasetService) { }

    public async updateDatasetSchema(datasetId: string): Promise<any> {
        const fields = (await this.datsetService.getFields(datasetId).toPromise())?.result;
        this._currentSchema$.next(fields);
        return fields;
    }
    public updateSchema(fields: { [key: string]: Field }) {
        this._currentSchema$.next(fields);
    }
    public getCurrentSchema() {
        return this.oCurrentSchema.pipe(take(1)).toPromise();
    }

    public async setCurrentDocumentFields(fields?: { [key: string]: Field }) {
        this._currentSchema$.next(fields);
    }
}
