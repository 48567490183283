import { Component, Input, OnInit, OnChanges, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { DatasetFileService } from '../../../../services/api/dataset-file.service';
import { FileLog } from '../../../../models/datasetFile.models';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
    selector: 'app-document-logs-content',
    templateUrl: './document-logs-content.component.html',
    styleUrls: ['./document-logs-content.component.scss']
})
export class DocumentLogsViewerComponent implements OnInit, OnChanges {

    @Input() fileId: string
    @Input() datasetId: string
    @Input() isLoading: boolean = false;
    @Output() toggleShowLogs = new EventEmitter<boolean>();
    public logSteps: FileLog[] = [];
    public stepsLog: FileLog[] = [];
    public actionsLog: FileLog[] = [];
    public showLogs: boolean = true;
    isLoadingLogs: boolean = false;


    constructor(
        private _datasetFileService: DatasetFileService,
        private _clipboard: Clipboard
    ) { }

    async ngOnInit(): Promise<void> {
        this.setIsLoadingLogs(true);
        await this.updateLogFile();
        this.setIsLoadingLogs(false);
    }

    async ngOnChanges(changes: SimpleChanges) {
        if ((changes.fileId && changes.fileId.previousValue) || (changes.datasetId && changes.datasetId.previousValue)) {
            await this.updateLogFile();
        }
    }

    setIsLoadingLogs(value: boolean) {
        this.isLoadingLogs = value;
    }

    public onToggleShowLogs() {
        this.showLogs = !this.showLogs;
        this.toggleShowLogs.emit(this.showLogs)

    }

    public copy(event: Event, log: FileLog) {
        event.stopPropagation();
        const resultJson = JSON.stringify(log.jsonObject);
        this._clipboard.copy(resultJson);
        log.isCopied = true;
        setTimeout(() => {
            log.isCopied = false
        }, 2000);
    }

    private async updateLogFile(): Promise<void> {
        this.logSteps = [];
        const { isSuccess, result: fileLog } = await this._datasetFileService.getLogs(this.datasetId, this.fileId).toPromise();
        if (isSuccess) {
            this.stepsLog = fileLog?.stepsLog.map((step) => ({ stepName: step.name, jsonObject: step, isCopied: false })) || [];
            this.actionsLog = fileLog.actionsLog.map((action) => ({ stepName: action.name, jsonObject: action, isCopied: false })) || [];
        }
    }

}
