<div class="height-100" id="chart-details">
    <div class="header" *ngIf="visibleDetailsHeader">
        <div class="upper-section">
            <charts-control #CONTROL [state]="chartState" [chartType]="chartType" [metricList]="metricList"
                [chartControlService]="kendoChartControlService"></charts-control>
        </div>
        <div class="right-section">
            <mat-icon [matMenuTriggerFor]="menu" color="accent" color="accent">download</mat-icon>
            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="exportChart('png')"> Save as PNG </button>
                <button mat-menu-item (click)="exportChart('jpeg')"> Save as JPEG</button>
                <button mat-menu-item (click)="exportChart('svg')"> Save as SVG</button>
                <mat-divider></mat-divider>
                <button mat-menu-item (click)="exportChart('pdf')"> Save as PDF</button>
            </mat-menu>
        </div>
    </div>
    <div class="chart-header" *ngIf="chartViewErrorHandler.isError | async">
        <div class="chart-header-left" *ngIf="chartViewErrorHandler.errorMsg | async as msg">
            <div class="error">{{msg}}</div>
        </div>
    </div>
    <div [ngClass]="visibleDetailsHeader ? 'chart' : 'height-100'"
        class="main-wrapper">
        <div class="height-100 main">
            <div class="height-100 card">
                <kendo-chart-item #KENDO_CHART
                    *ngIf="chartState && queryService && serviceUrl && availableColumns && mainViewState.getState()"
                    [chartState]="chartState" [queryService]="queryService" [serviceUrl]="serviceUrl"
                    [availableColumns]="availableColumns" (error)="onChartError($event)" [previewMode]="false"
                    [pageTitle]="mainViewState.getState()?.mainTitle"></kendo-chart-item>
            </div>
        </div>
    </div>

</div>
