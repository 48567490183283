import { NgModule, CUSTOM_ELEMENTS_SCHEMA, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule, FormControl, AbstractControl, ValidationErrors, FormArray, FormGroup } from '@angular/forms';
import { FormlyModule, FormlyFieldConfig, FormlyField, FieldType } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyMatDatepickerModule } from '@ngx-formly/material/datepicker';
import { PanelWrapperComponent } from './dyn-wrapper/panel-wrapper.component';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { DynSelectComponent } from './dyn-select/dyn-select.component';
import { DynChipListComponent } from './dyn-chip-list/dyn-chip-list.component';
import { DragDropDirective } from './dyn-upload/drag-drop.directive';
import { DynUploaderComponent } from './dyn-upload/dyn-upload.component';
import { DynFormsListComponent } from './dyn-forms-list/dyn-forms-list.component';
import { DynRadio } from './dyn-radio/dyn-radio.component';
import { MatStepperModule } from '@angular/material/stepper';
import { DynFormComponent } from './dyn-form/dyn-form.component';
import { MatNativeDateModule } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatChipsModule } from '@angular/material/chips';
import { MatButtonModule } from '@angular/material/button';
import { DynStarRatingComponent } from './dyn-star-rating/dyn-star-rating.component';
import { StarRatingComponent } from './star-rating/star-rating.component';
import { MatDatepickerModule, MatDatepickerToggle } from '@angular/material/datepicker';
import { DatepickerWithoutDaySelection } from './dyn-date/datepicker-withoutday-selection';
import { MatFormFieldModule } from '@angular/material/form-field';
import { RepeatTypeComponent } from './repeat-section/repeat-section.type';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CreditCardNComponent } from './dyn-credit-card/credit-card.component';
import { DynLinkComponent } from './dyn-link/dyn-link.component';
import { DynSignPadComponent } from './dyn-sign-pad/dyn-sign-pad.component';
import { SignaturePadModule } from 'ngx-signaturepad';
import { CreditCardNumberFormatValidatorDirective } from './dyn-credit-card/credit-card-number-validation';
import { CreditCardNumberDirective } from './shared/directives/credit-card-number.directive';
import { GraphQlDynAutoCompleteComponent } from './graphql-auto-complete/graphql-auto-complete.component';
import { DatetimepickerFieldType } from './datetime-picker/datetime-picker';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule, NgxMatNativeDateModule, NgxMatDateAdapter, NGX_MAT_DATE_FORMATS, NgxMatDateFormats } from '@angular-material-components/datetime-picker';
import { CurrencyFieldType } from './currency/currency-input';
import { DynCheckboxListComponent } from './dyn-checkbox-list/dyn-checkbox-list.component';
import { TextInputWithHistory } from './text-input-with-history/text-input-with-history.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TelFieldType } from './dyn-phone/dyn-phone';
import { PhonePipe } from './shared/phone-pipe';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { TabDirective } from './shared/directives/tab-directive';
import { MatListModule } from '@angular/material/list';
import { DynSelectionComponent } from './dyn-selection/dyn-selection.component';
import { FloatNumnberPipe } from './shared/comma-pipe';
import { FloatFieldType } from './dyn-float/float-component';
import { MatCardModule } from '@angular/material/card';
import { FormlyHyperLinkComponent } from './dyn-input/formly-hyper-link.component';
import { ApiAutoCompleteComponent } from './api-auto-complete/api-auto-complete.component';
import { InputMaskFieldComponent } from './masked-input/masked-input.component';
import { NgxMaskModule } from 'ngx-mask';
import { DynButtonComponent } from './dyn-button/dyn-button.component';
import { AutocompleteTypeComponent } from './auto-complete/autocomplete.component';
import { CustomMultiCheckboxComponent } from './custom-multicheckbox/custom-multicheckbox.component';
import { DataChipListComponent } from './data-chip-list/data-chip-list.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTabsModule } from '@angular/material/tabs';
import { DynStepperComponent } from './dyn-stepper/dyn-stepper';
import { CronExpGeneratorComponent } from './cron-exp-generator/cron-exp-generator.component'
import { CronEditorModule } from 'ngx-cron-editor';
import { DynAdvDatepickerComponent } from './dyn-adv-datepicker/dyn-adv-datepicker.component';
import { DynTimePickerComponent } from './dyn-time-picker/dyn-time-picker.component';
import { IgxTimePickerModule, IgxInputGroupModule } from "igniteui-angular";
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { NgxMatMomentModule, NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular-material-components/moment-adapter';
import { ToDatePipePipe } from './to-date-pipe.pipe';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FormlyFieldToggle } from './dyn-toggle/dyn-toggle';
import { CustomLabelComponent } from "./custom-label/custom-label.component";
import { DynamicLinkInputComponent } from './dyn-link-input/dyn-link-input.component';
import { DynTableComponent } from './dyn-table/dyn-table.component';
import { GridModule } from '@progress/kendo-angular-grid';
import { SimpleChipListComponent } from './simple-chip-list/simple-chip-list.component';
import { DynamicNumberInputComponent } from './dyn-number-input/dyn-number-input.component';
import { MatMenuModule } from '@angular/material/menu';
export function creditcardValidator(control: FormControl) {
    return /^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/.test(control.value) ? null : { 'cardNumber': true };
}

export function creditcardValidatorMessage(err, field: FormlyFieldConfig) {
    return `"${field.formControl.value}" is not a valid Credit Card Number`;
}

export function validationMessage(err, field: FormlyFieldConfig) {
    return err;
}

export function matchingValidator(control, field: FormlyFieldConfig) {
    const fieldValue = control._parent.value[control._fields[0].key];
    const matchingWithValue = control._parent.value[field.templateOptions.matchingWith];
    if (!fieldValue || !matchingWithValue) {
        return null;
    }
    if (fieldValue === matchingWithValue) {
        return null;
    }
    return { fieldMatch: { message: 'Not Matching' } };
}
export function fieldComparisonValidator(control, fieldConfig: FormlyFieldConfig) {
    const valueToCompare = fieldConfig.templateOptions.compareWithField;
    const parentForm = fieldConfig.parent.formControl;
    if (valueToCompare && parentForm) {
        const comparedField = parentForm.get(valueToCompare);
        const commparedWithName = fieldConfig?.parent?.fieldGroup?.find(x => x.key == valueToCompare)?.templateOptions?.label;

        if (comparedField && control.value > comparedField.value) {
            return { 'field-comparison': { message: 'Value should be less or equal to  ' + (commparedWithName ?? valueToCompare) } };
        }
    }

    return null;
}
export function minValidationMessage(err, field) {
    return `This value should be more than or equal ${field.templateOptions.min}`;
}

export function maxValidationMessage(err, field) {
    return `This value should be less than or equal ${field.templateOptions.max}`;
}

export function EmailValidator(control: FormControl): ValidationErrors {
    return /^([+\w0-9._-]+@[\w0-9._-]+\.[\w0-9_-]+)$/i.test(control.value) ? null : { 'email': true };
}

export function minLengthValidationMessage(err, field) {
    return `This field should contain ${field.templateOptions.minLength} characters at least`;
}

export function maxLengthValidationMessage(err, field) {
    return `This field should contain ${field.templateOptions.maxLength} characters at most`;
}

export function EmailValidatorMessage(err, field: FormlyFieldConfig) {
    return `"${field.formControl.value}" is not a valid Email Address`;
}

export const MOMENT_DATETIME_WITH_SECONDS_FORMAT = 'MM/DD/yyyy hh:mm:ss A';

// If using Moment
const CUSTOM_MOMENT_FORMATS: NgxMatDateFormats = {
    parse: {
        dateInput: 'l, LTS',
    },
    display: {
        dateInput: MOMENT_DATETIME_WITH_SECONDS_FORMAT,
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@Component({
    selector: 'formly-empty-type',
    template: '',
})
export class EmptyTypeComponent extends FieldType { }

@NgModule({
    declarations: [
        DynFormComponent,
        PanelWrapperComponent,
        DynSelectComponent,
        DynUploaderComponent,
        DynChipListComponent,
        SimpleChipListComponent,
        DragDropDirective,
        DynFormsListComponent,
        DynStarRatingComponent,
        DynCheckboxListComponent,
        CustomMultiCheckboxComponent,
        StarRatingComponent,
        ApiAutoCompleteComponent,
        InputMaskFieldComponent,
        CronExpGeneratorComponent,
        DynRadio,
        DatepickerWithoutDaySelection,
        GraphQlDynAutoCompleteComponent,
        CreditCardNComponent,
        CreditCardNumberFormatValidatorDirective,
        CreditCardNumberDirective,
        RepeatTypeComponent,
        DynLinkComponent,
        DynamicLinkInputComponent,
        DynSignPadComponent,
        EmptyTypeComponent,
        DatetimepickerFieldType,
        CurrencyFieldType,
        TelFieldType,
        PhonePipe,
        TabDirective,
        DynSelectionComponent,
        FloatNumnberPipe,
        FloatFieldType,
        FormlyHyperLinkComponent,
        DynButtonComponent,
        AutocompleteTypeComponent,
        DataChipListComponent,
        DynStepperComponent,
        DynAdvDatepickerComponent,
        DynTimePickerComponent,
        TextInputWithHistory,
        ToDatePipePipe,
        FormlyFieldToggle,
        CustomLabelComponent,
        DynTableComponent,
        DynamicNumberInputComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        CronEditorModule,
        MatListModule,
        MatButtonModule,
        MatNativeDateModule,
        MatAutocompleteModule,
        MatInputModule,
        MatIconModule,
        MatChipsModule,
        MatRadioModule,
        MatSelectModule,
        MatStepperModule,
        MatCheckboxModule,
        MatCardModule,
        FormlyMaterialModule,
        FormlySelectModule,
        NgxMaskModule.forRoot(),
        MatSnackBarModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatFormFieldModule,
        SignaturePadModule,
        MatProgressSpinnerModule,
        NgxMatNativeDateModule,
        MatTooltipModule,
        MatTabsModule,
        CronEditorModule,
        IgxTimePickerModule,
        IgxInputGroupModule,
        NgxMatDatetimePickerModule,
        NgxMatTimepickerModule,
        MatMomentDateModule,
        MatSlideToggleModule,
        FormlyModule.forRoot({
            validators: [
                { name: 'field-comparison', validation: fieldComparisonValidator },
                { name: 'cardNumber', validation: creditcardValidator },
                { name: 'field-matching', validation: matchingValidator },
                { name: 'email', validation: EmailValidator }
            ],
            validationMessages: [
                { name: 'cardNumber', message: creditcardValidatorMessage },
                { name: 'required', message: 'This field is required' },
                { name: 'pattern', message: 'Invalid expression' },
                { name: 'other', message: validationMessage },

                { name: 'min', message: minValidationMessage },
                { name: 'max', message: maxValidationMessage },
                { name: 'email', message: EmailValidatorMessage },
                { name: 'minlength', message: minLengthValidationMessage },
                { name: 'maxlength', message: maxLengthValidationMessage }
            ],
            types: [
                { name: 'toggle', component: FormlyFieldToggle },
                { name: 'dyn-select', component: DynSelectComponent, wrappers: ['form-field'] },
                { name: 'dyn-list', component: DynFormsListComponent, wrappers: ['form-field'] },
                { name: 'dyn-radio', component: DynRadio, wrappers: ['form-field'] },
                { name: 'dyn-chip-list', component: DynChipListComponent, wrappers: ['form-field'] },
                { name: 'simple-chip-list', component: SimpleChipListComponent },
                { name: 'star-rating', component: StarRatingComponent },
                { name: 'dyn-star-rating', component: DynStarRatingComponent },
                { name: 'dyn-uploader', component: DynUploaderComponent },
                { name: 'dyn-date', component: DatepickerWithoutDaySelection },
                { name: 'dyn-time-picker', component: DynTimePickerComponent, wrappers: ['form-field'] },
                { name: 'text-input-with-history', component: TextInputWithHistory, wrappers: ['form-field'] },
                { name: 'graphql-auto-complete', component: GraphQlDynAutoCompleteComponent, wrappers: ['form-field'] },
                { name: 'credit-card-number', component: CreditCardNComponent },
                { name: 'dyn-link', component: DynLinkComponent },
                { name: 'dyn-input-link', component: DynamicLinkInputComponent },
                { name: 'dyn-sign', component: DynSignPadComponent, wrappers: ['form-field'] },
                { name: 'dyn-check-box-list', component: DynCheckboxListComponent, wrappers: ['form-field'] },
                { name: 'custom-multicheckbox', component: CustomMultiCheckboxComponent, wrappers: ['form-field'] },
                { name: 'dyn-empty', component: EmptyTypeComponent },
                { name: 'repeat', component: RepeatTypeComponent },
                { name: 'datetimepicker', component: DatetimepickerFieldType, wrappers: ['form-field'] },
                { name: 'currency', component: CurrencyFieldType, wrappers: ['form-field'] },
                { name: 'tel', component: TelFieldType, wrappers: ['form-field'] },
                { name: 'dyn-selection-list', component: DynSelectionComponent, wrappers: ['form-field'] },
                { name: 'dyn-hyper-link', component: FormlyHyperLinkComponent, wrappers: ['form-field'] },
                { name: 'api-auto-complete', component: ApiAutoCompleteComponent, wrappers: ['form-field'] },
                { name: 'comma-separated', component: FloatFieldType, wrappers: ['form-field'] },
                { name: 'masked-input', component: InputMaskFieldComponent, wrappers: ['form-field'] },
                { name: 'dyn-cron', component: CronExpGeneratorComponent, wrappers: ['form-field'] },
                { name: 'custom-select', component: AutocompleteTypeComponent, wrappers: ['form-field'] },
                { name: 'dyn-button', component: DynButtonComponent },
                { name: 'data-chip-list', component: DataChipListComponent, wrappers: ['form-field'] },
                { name: 'dyn-stepper', component: DynStepperComponent, wrappers: [] },
                { name: 'dyn-adv-datepicker', component: DynAdvDatepickerComponent, wrappers: [] },
                { name: 'dyn-adv-datepicker', component: DynAdvDatepickerComponent, wrappers: [] },
                { name: 'custom-label', component: CustomLabelComponent, wrappers: [] },
                { name: 'dyn-table', component: DynTableComponent, wrappers: [] },
                { name: 'dyn-section', component: PanelWrapperComponent, wrappers: [] },
                { name: 'number', component: DynamicNumberInputComponent },
            ],
            wrappers: [
                { name: 'panel', component: PanelWrapperComponent },
                // { name: 'form-field', component: DefaultWrapperComponent }
            ],
        }),
        FormlyMatDatepickerModule,
        CommonModule,
        PDFExportModule,
        GridModule,
        MatMenuModule
    ],
    exports: [
        DynFormComponent, DynStepperComponent, DynFormsListComponent, DatepickerWithoutDaySelection, MatDatepickerModule,
        MatMomentDateModule,
        SimpleChipListComponent,
        NgxMatMomentModule,
        NgxMatDatetimePickerModule,
    ],
    providers: [
        MatDatepickerModule,
        MatNativeDateModule,
        { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: false } },
        { provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_MOMENT_FORMATS },
        { provide: NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: false } },
    ]
})
export class DynFormsModule { }
