import { IntegrationType } from './enums';
export const integrationTypeName = new Map<IntegrationType, string>([
    [IntegrationType.Webhook, 'Webhook'],
    [IntegrationType.Make, 'Make'],
    [IntegrationType.Zapier, 'Zapier']
])

export const integrationTypeImage = new Map<IntegrationType, string>([
    [IntegrationType.Webhook, 'assets/images/logo/webhook-logo.png'],
    [IntegrationType.Make, 'assets/images/logo/make-logo.png'],
    [IntegrationType.Zapier, 'assets/images/logo/zapier-logo.png']
])

export const fileIconMap = new Map<string, string>([
    ['txt', 'description'],
    ['pdf', 'picture_as_pdf'],
    ['png', 'insert_photo'],
    ['jpg', 'insert_photo'],
    ['jpeg', 'insert_photo'],
    ['docx', 'description'],
    ['doc', 'description'],
    ['xlsx', 'description'],
    ['xls', 'description'],
    ['csv', 'description']
]);
