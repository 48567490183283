import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormsDataServiceInterface, OptionDataServiceInterface } from '@discoverer/dynamic-forms';
import { HttpClient } from '@angular/common/http';
import { IntegrationFormService } from '../../../services/integration-form.service';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HasuraOptionService } from '../../../../../../discoverer/dynamic-forms/option-lists/shared-services/hasura-option-service';
import { IntegrationModel } from '../../../models/integration.model';
import { IntegrationService } from '../../../services/api/integration.service';
import { integrationTypeName } from '../../../models/constants';
import { DynFormComponent } from '@discoverer/dynamic-forms/dyn-forms/dyn-form/dyn-form.component';
import { IntegrationType } from '../../../models/enums';
import { Clipboard } from '@angular/cdk/clipboard';


@Component({
    selector: 'integration-details-dialog',
    templateUrl: './integration-details-dialog.component.html',
    styleUrls: ['./integration-details-dialog.component.scss'],
    providers: [
        { provide: FormsDataServiceInterface, useClass: IntegrationFormService, deps: [HttpClient, IntegrationService] },
        { provide: OptionDataServiceInterface, useClass: HasuraOptionService },
    ]
})

export class IntegrationDetailsDialogComponent implements OnInit {

    @ViewChild('dynForm') dynForm: DynFormComponent;
    public IntegrationType = IntegrationType;
    public isLoading = false;
    public isLoadingToken = false;
    public isEditMode: boolean = false;
    public form = new FormGroup({});
    public isCopied: any = {};
    public model: IntegrationModel = new IntegrationModel();
    constructor(public dialogRef: MatDialogRef<IntegrationDetailsDialogComponent>,
        private snackBar: MatSnackBar,
        private _integrationService: IntegrationService,
        private clipboard: Clipboard,
        @Inject(MAT_DIALOG_DATA) public data: IntegrationModel) {
    }
    ngOnInit() {
        this.loadData();
    }

    public getIntegrationDynFormKey(integrationTypeId: IntegrationType) {
        switch(integrationTypeId){
            case IntegrationType.Webhook:
                return 'new-webhooks'
            case IntegrationType.Make:
                return 'new-make'
            case IntegrationType.Zapier:
                return 'new-zapier'
        }
    }
    public close(value = null) {
        
        this.dialogRef.close(value);
    }

    public save() {
        
        this.isLoading = true;

        if (!this.dynForm.form.valid)
            this.isLoading = false;

        this.dynForm.submit();
    }
    public onSubmit(event) {
        
        try {
            this.isLoading = true;
            this.model.datasetId = this.data.datasetId;

            if (event) {
                
                this.isLoading = false;
                if (event.error) {
                    this.snackBar.open(event.error);
                }
                this.close(event);
            }

        } catch (error) {
            
            this.isLoading = false;
        }
    }

    public getIntegrationName(): string {
        return integrationTypeName.get(this.data.typeId)
    }

    private loadData() {
        try {
            this.isEditMode = !!this.data.id;
            this.model.id = this.data.id;
            this.model.datasetId = this.data.datasetId;
            this.model.typeId = this.data.typeId;
            this.model.configuration = this.data.configuration;

            switch(this.data.typeId){
                case IntegrationType.Webhook:
                    if(!this.model.configuration) {
                        this.model.configuration = {};
                    }
                    if(!this.model.configuration?.['headers']?.length) {
                        this.model.configuration['headers'] = [{"key": "", "value": ""}];
                    }
                    break;
                case IntegrationType.Zapier:
                    this.isLoadingToken = true;
                    this._integrationService.getToken().subscribe(response => {
                        this.isLoadingToken = false;
                        if (!response.isSuccess || response.result === false) {
                            console.error(`get api token for current account failed`);
                            return;
                        }
                        else {
                            this.model.apiToken = response.result;
                        }
                    })
                    break;
                default:
                    throw `integration type "${this.data.typeId}" not implemented`;
            }
        } catch (error) {
            this.isLoading = false;
        }

    }

    copyToClipboard(copyValue: string, copyPropertyName: string, event: Event) {
        this.clipboard.copy(copyValue);
        this.isCopied[copyPropertyName] = true;
        event.stopPropagation();
        setTimeout(() => {
            this.isCopied[copyPropertyName] = false;
        }, 4000);
    }
}
