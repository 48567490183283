import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { AppSettingsService, IDynamicReport } from '.';
import { IDirRequest } from '../classes/request-state.class';
import { RequestCacheService } from './request-cache.service';


@Injectable()
export abstract class RequestPersistService<T> {

    public oRequestLoaded: BehaviorSubject<T>;
    public oLastRequestData: BehaviorSubject<T>;

    protected _sRequestData = new BehaviorSubject<T>(null);
    protected _sRequestLoaded = new BehaviorSubject<T>(null);

    private _loadReqId: string;
    private _saveToReqId: string;
    private _originalHash: number;
    private _currentHash: number;


    constructor(
        protected http: HttpClient,
        protected appSettings: AppSettingsService,
        protected cache: RequestCacheService
    ) {
        this.oRequestLoaded = this._sRequestLoaded;
        this.oLastRequestData = this._sRequestData;
    }

    protected abstract stateLoadCallback(data: T, missing: boolean);

    public async init(requestId: string) {
        await this.loadRequest(requestId);
    }

    public get currentReqId() {
        return this._saveToReqId;
    }

    public get loadReqId() {
        return this._loadReqId;
    }

    public get hasChanges() {
        return this._originalHash !== this._currentHash;
    }

    public async deleteRequest(reqId): Promise<any> {
        const appId = (await this.appSettings.getCurrentApp()).key;
        this.cache.removeItem(reqId);
        return this.http.delete(`/api/es/requests/${appId}/${reqId}`).toPromise();
    }

    public async resetRequest(): Promise<void> {
        await this.loadRequest(this._loadReqId);
    }

    public async createRequest(): Promise<string> {
        this._saveToReqId = await this.createNewRequest();
        return this._saveToReqId;
    }
    public createLocalRequest(): string {
        const guid = uuidv4();
        this.cache.setItem(guid, '{}', true);
        return guid;
    }

    async persistRequest(isUpdate: boolean, cacheOnly = true, newState?: T) {
        console.log('persistCurrentRequest');
        const state = newState ? newState : await this.oLastRequestData.pipe(first()).toPromise();
        await this.updateRequest(isUpdate ? this._loadReqId : this._saveToReqId, state, cacheOnly);
        // /await this.resetRequest();
    }
    async persistTempRequest(isUpdate: boolean, cacheOnly = true, newState?: T) {
        console.log('persistCurrentRequest');
        const state = newState ? newState : await this.oLastRequestData.pipe(first()).toPromise();
        await this.updateRequest(isUpdate ? this._loadReqId : this._saveToReqId, state, cacheOnly, true);
        // /await this.resetRequest();
    }

    public async loadRequest(reqId: string, skipFetch: boolean = false): Promise<void> {
        this._loadReqId = reqId;
        if (this._loadReqId === this._saveToReqId) {
            await this.createRequest();
        }
        let request = null;
        if (!skipFetch) {
            this._sRequestLoaded.next(null);
            request = await this.getRequest(this._loadReqId) || {};
            const objKeys = Object.keys(request);
            const missing = objKeys.length === 0;
            request.loadReqId = this._loadReqId;
            await this.stateLoadCallback(request, missing);
        } else {
            request = await this.oLastRequestData.pipe(first()).toPromise();
        }
        console.log('*** create Another Request');
        await this.createRequest();

        this._sRequestLoaded.next(request);
        //disLogger('Request Loaded', request);

    }


    private getHash(str: string): number {
        let hash = 0;
        if (str.length === 0) {
            return hash;
        }
        for (let i = 0; i < str.length; i++) {
            const char = str.charCodeAt(i);
            // tslint:disable-next-line:no-bitwise
            hash = ((hash << 5) - hash) + char;
            // tslint:disable-next-line:no-bitwise
            hash = hash & hash; // Convert to 32bit integer
        }
        return hash;
    }
    // public async getRequest(reqId: string): Promise<any> {
    //     debugger
    //     if (!this.cache.getItem(reqId)) {
    //         const appId = (await this.appSettings.getCurrentApp()).key;
    //         const item = await this.http.get(`api/es/requests/${appId}/${reqId}`).toPromise();
    //         if (!!item) {
    //             this.cache.setItem(reqId, item);
    //             return this.cache.getItem(reqId);
    //         } else {
    //             let savedItem = await this.createNewRequest();
    //             let z =  await this.http.get(`api/es/requests/${appId}/${savedItem}`).toPromise();
    //             this.cache.setItem(savedItem, z);
    //             return this.cache.getItem(savedItem);
    //         }
    //     }
    // }
    public async getRequest(reqId: string, appkey?: string): Promise<any> {
        if (!this.cache.getItem(reqId)) {
            const appId = appkey ||(await this.appSettings.getCurrentApp()).key;
            this.cache.setItem(reqId, await this.http.get(`api/es/requests/${appId}/${reqId}`).toPromise())
        }
        return this.cache.getItem(reqId);
    }

    public async updateRequest(reqId: string, state: any, cacheOnly = true, isTemp = false): Promise<any> {
        this.cache.setItem(reqId, state, isTemp);
        if (cacheOnly) {
            return Promise.resolve(true);
        } else {
            const appId = (await this.appSettings.getCurrentApp()).key;
            return this.http.put(`/api/es/requests/${appId}/${reqId}`, state).toPromise()
        };
    }

    private async createNewRequest(): Promise<string> {
        const appId = (await this.appSettings.getCurrentApp()).key;
        return (await this.http.post<any>(`/api/es/requests/${appId}`, '').toPromise()).requestId;
    }
    public async createDefaultRequest(appKey: string, defaultReport: IDynamicReport, commonDirectoryId: number): Promise<any> {
        const response = await this.http.post<any>(`/api/es/requests/${appKey}/createDefaultRequest/${commonDirectoryId}`, JSON.parse(JSON.stringify(defaultReport))).toPromise();
        return response.requestId;
    }
    public async getRequests(appKey: string, directories: IDirRequest[]): Promise<any> {
        const directoryIds = directories.map(d => d.id).join(",");
        const response = await this.http.get<any>(`/api/es/requests/${appKey}/getRequests/${directoryIds}`).toPromise();
        return response.requests;
    }

    public async exportRequests(appKey: string, tabId: string): Promise<any> {
        return await this.http.get<any>(`api/es/requests/${appKey}/exportRequests/${tabId}`).toPromise();
    }

    public async ImportRequest(appKey: string, tabId: string): Promise<any> {
        return await this.http.post<any>(`/api/es/requests/${appKey}/importRequest/${tabId}`, {}).toPromise();
    }
    public async globalSearchRequests(appKey: string, term: string, skip: number, size: number): Promise<any[]> {
        return await this.http.get<any>(`api/es/requests/${appKey}/search?term=${term}&skip=${size*skip}&size=${size}`).toPromise();
    }
}


const _requestPersistCache: { [key: string]: any } = {};
