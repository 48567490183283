import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { take } from "rxjs/operators";
import { DatasetService } from "../../../../services/api/dataset.service";
import { DatasetTypes } from "../../../../models/dataset.model";
import { ChildQueue } from "./chooser-queue-children-viewer/chooser-queue-children-viewer.component";



@Component({
    selector: 'app-document-main-content',
    templateUrl: './document-main-content.component.html',
    styleUrls: ['./document-main-content.component.scss']
})
export class DocumentMainContentComponent implements OnInit {

    @Input() result: any;
    @Input() limit: number;
    @Input() fileId: string;
    @Input() datasetId: string;
    @Input() SplitFiles: ChildQueue[] = [];
    @Input() isSaving: boolean;
    @Input() isLoading: boolean;
    @Input() isEditMode: boolean;
    @Input() resultIndex: number;
    @Output() dataFetched = new EventEmitter<any>();
    @Input() tabId: string;
    @Output() checkValidity = new EventEmitter<boolean>();
    public datasetType: string;
    public isWorkspace: boolean;

    constructor(private _datasetService: DatasetService) { }

    public async ngOnInit(): Promise<void> {
        this.datasetType = (await this._datasetService.oDatasetDetails.pipe(take(1)).toPromise()).Type;

        this.isWorkspace = this.datasetType == DatasetTypes.WORKSPACE && this.tabId == 'queue';

        console.log("isWorkspace", this.isWorkspace)
    }

    public onDataFetched(result) {
        this.dataFetched.emit(result);
    }


    oCheckValidity(isValid) {
        this.checkValidity.emit(isValid);
    }

}
