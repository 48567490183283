<div class="flex flex-col gap-2 p-4">
    <mat-divider></mat-divider>
    <div *ngFor="let child of children;">
        <div class="p-5">
            <div *ngIf="child">
                <div class="title">
                    <label>{{ child.OriginalFileName }}.{{ child.FileExtension }}</label>
                    <div class="flex gap-2">
                        <mat-icon matTooltip="Open Records" (click)="navigateToDetails(child.DatasetKey, child.Id, 'records')">open_in_new</mat-icon>
                        <mat-icon matTooltip="Open Queue" (click)="navigateToDetails(child.DatasetKey, child.Id, 'queue')">open_in_new</mat-icon>
                    </div>
                </div>
                <div class="pb-2.5">
                    <label>Status:</label> {{ child.Status }}
                </div>
                <div class="pb-2.5">
                    <label>Dataset:</label> {{ child.DatasetTitle }}
                </div>
                <div class="pb-2.5">
                    <label>Results Count:</label> {{ child.ResultsCount }}
                </div>
            </div>
        </div>
        <mat-divider></mat-divider>
    </div>
</div>
