import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DiscovererQueryService, MultiSelectService } from '@discoverer/core';
import { DatasetFileService } from '../../../services/api/dataset-file.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ERROR_SNACKBAR_OPTIONS, SUCCESS_SNACKBAR_OPTIONS } from '../../home/edit-title-dialog/edit-title-dialog';
import { ConfirmationDialogComponent } from '../../attoms/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DatasetDetailsDto, DatasetsTypes } from '../../../models/dataset.model';
import { DatasetService } from '../../../services/api/dataset.service';
import { take } from 'rxjs/operators';

@Component({
    selector: 'app-multi-select-handler',
    templateUrl: './multi-select-handler.component.html',
    styleUrls: ['./multi-select-handler.component.scss']
})
export class MultiSelectHandlerComponent implements OnInit, OnDestroy {
    enableSelect: boolean = false
    @Input() buissnessKey: string = 'id';
    @Input() datasetId: string;
    @Output() toggleChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() queryService: DiscovererQueryService

    datasetDetails: DatasetDetailsDto;
    datasetTypes = DatasetsTypes;
    constructor(
        public multiSelectService: MultiSelectService,
        private _datasetFileService: DatasetFileService,
        private _snackBar: MatSnackBar,
        private _dialog: MatDialog,
        private _datasetService: DatasetService
    ) { }

    async ngOnInit(): Promise<void> {
        this.datasetDetails = await this._datasetService.oDatasetDetails.pipe(take(1)).toPromise();
    }

    ngOnDestroy(): void {
        this.multiSelectService.markListEmpty();
    }

    async reprceossSelected() {
        const selected = [... new Set(this.multiSelectService.getItemsSelected().map(item => item[this.buissnessKey])
            .reduce((acc, value) => acc.concat(value), []))] as string[];
        if (!selected.length) {
            this._snackBar.open('No files selected', null, ERROR_SNACKBAR_OPTIONS);
            return;
        }

        var confirm = await this._dialog.open(ConfirmationDialogComponent, { data: { message: `Are you sure you want to reprocess (${selected.length}) files?` } })
            .afterClosed().toPromise();
        if (!confirm) return;

        var { isSuccess } = await this._datasetFileService.reprocessMultipleFiles(this.datasetId, selected).toPromise();

        if (isSuccess) {
            this.multiSelectService.markListEmpty();
            this._snackBar.open(`(${selected.length}) sent to reprocess!`, null, SUCCESS_SNACKBAR_OPTIONS);
        } else {
            this._snackBar.open('Error reprocessing files', null, ERROR_SNACKBAR_OPTIONS);
        }
        this.queryService.refresh();
    }

    async deleteSelected() {
        const selected = [... new Set(this.multiSelectService.getItemsSelected().map(item => item[this.buissnessKey])
            .reduce((acc, value) => acc.concat(value), []))] as string[];
        if (!selected.length) {
            this._snackBar.open('No files selected', null, ERROR_SNACKBAR_OPTIONS);
            return;
        }

        var confirm = await this._dialog.open(ConfirmationDialogComponent, {
            data: {
                message: `Are you sure you want to delete (${this.multiSelectService.getItemsSelected()?.length}) files?`,
                note: `P.S:- This will delete all selected files (${selected.length}) and can\'t be undone!!`
            }
        })
            .afterClosed().toPromise();
        if (!confirm) return;

        var { isSuccess } = await this._datasetFileService.deleteSomeByIds(this.datasetId, selected).toPromise();

        if (isSuccess) {
            this.multiSelectService.markListEmpty();
            this._snackBar.open(`(${selected.length}) files were deleted!`, null, SUCCESS_SNACKBAR_OPTIONS);
        } else {
            this._snackBar.open('Error deleting files', null, ERROR_SNACKBAR_OPTIONS);
        }
        this.queryService.refresh();
    }
}
