<div class="wrapper">
    <div class="header">
        <div class="flex">
            <mat-icon class="header-icon">campaign</mat-icon>
            <p class="header-title">{{sidenavTitle}}</p>
        </div>
        <div class="header-actions">
            <button mat-icon-button (click)="sidenavRef.toggle(); handleSidenavClosed()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>

    <div class="main">
        <app-document-result *ngIf="fileId && sidenavRef.opened" [datasetId]="datasetId" [fileId]="fileId"
            [sidenavRef]="sidenavRef"></app-document-result>
        <!-- <div *ngIf="componentToRender === sidenavComponentTemplate.CHANGE_DOCUMENT" class="file-upload-container">
            <custom-file-input [datasetId]="datasetId" [isTest]="true" (backendResult)="handleFileUploaded($event)"></custom-file-input>
        </div> -->
    </div>

    <div class="footer">
        <button mat-stroked-button (click)="sidenavRef.toggle(); handleSidenavClosed()">
            <mat-icon>close</mat-icon>
            Close
        </button>
    </div>
</div>