<div class="main-wrapper" cdkDragBoundary=".main-fields" cdkDrag (cdkDragStarted)="onDragStart()"
    (cdkDragEnded)="onDragEnd()">
    <form class="field-form-wrapper" [formGroup]="formGroup" [ngClass]="{'active mt-2': additionalItem}" #dom
        (mouseover)="enableRowEditing(dom)" (mouseleave)="disableRowEditing(dom)">
        <mat-icon class="drag-handle"
            [ngStyle]="{'visibility': (isEditMode) ? 'visible' : 'hidden'}"
            cdkDragHandle>
            drag_indicator
        </mat-icon>

        <mat-form-field class="field-wrapper type" appearance="outline" [ngClass]="{'focused': isFocused}">
            <mat-select formControlName="dataType" class="select-field"
                (selectionChange)="changeEvent($event.source.ngControl.name)">
                <mat-option *ngFor="let option of FIELD_TYPE_OPTIONS" [value]="option.value"
                    [disabled]="option.value === ''">{{ option.display }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="field-wrapper" appearance="outline" [ngClass]="{'focused': isFocused}">
            <input (focus)="onFocus()" (blur)="onBlur()" [id]="(parentIndex !== null && parentIndex !== undefined) ? 'display-' + parentIndex + '-' + index : 'display-' + index" matInput formControlName="display" class="input-field display" (change)="changeEvent('display')"
                placeholder="Field Name">
        </mat-form-field>

        <mat-form-field class="field-wrapper description" appearance="outline" [ngClass]="{'focused': isFocused}">
            <textarea [id]="(parentIndex !== null && parentIndex !== undefined) ? 'description-' + parentIndex + '-' + index : 'description-' + index" (focus)="onFocus()" (blur)="onBlur()" [ngClass]="{'edit-mode': isEditMode}" [attr.rows]="'2'" matInput formControlName="description" class="input-field"
                (change)="changeEvent('description')" placeholder="Description">
                </textarea>
        </mat-form-field>

        <mat-icon class="delete-icon" *ngIf="!additionalItem" (click)="deleteField(index)">delete_outline</mat-icon>
    </form>
</div>
