<div class="control-bar" layoutDir="column">
    <ng-template #popover>
        <reports [currentAppKey]="currentAppKey" [hidden]="false" (personalDirectoryId)="directoryId = $event"
            (hideReportsEvent)="closePopover()" [reportType]="reportType"(saveReport)="openSaveReportDialog($event)"
            (deleteReport)="deleteReport()" [defaultRequestId]="defaultRequestId" (loading)="processingRequest = $event"
            [loadedRequestDirectory]="reportDir">
        </reports>
    </ng-template>
    <!-- todo should be dynamic when more cores are introduced -->
    <div style="justify-content: center; margin-top: -20px; margin-bottom: -20px" layoutDir="row">
        <button matTooltip="Reset View" class="ml-2 menu-button" mat-icon-button (click)="reset()">
            <mat-icon>refresh </mat-icon>
        </button>

        <button [popoverTrigger]="popover" *ngIf="canSwitchReport" [closeOnClickOutside]="true" matTooltip="Open Report"
            class="menu-button" mat-icon-button>
            <mat-icon>folder_open</mat-icon>
        </button>
        <button matTooltip="Get Link" class="menu-button" mat-icon-button (click)="shareCurrentReport()">
            <mat-icon>link </mat-icon>
        </button>
        <export-report #exportReport *ngIf="true" [currentTab]="currentTab" [queryService]="queryService">
        </export-report>
        <button matTooltip="Export Data" class="menu-button" mat-icon-button (click)="exportReport.exportToExcel()"
            *ngIf="!!exportReport && exportReport.reportType != 'pivot-view'"
            [disabled]="(exportReport.exportSevice.oLoadingStatusResult | async).status === 'Busy'">
            <!-- <mat-spinner *ngIf="loadingState && loadingState.status == 'Busy'" diameter="20"></mat-spinner> -->
            <mat-icon>download</mat-icon>

            <mat-spinner [diameter]="20"
                *ngIf="(exportReport.exportSevice.oLoadingStatusResult | async).status === 'Busy'"></mat-spinner>
            <!--   -->
        </button>
        <button matTooltip="Report Actions" mat-icon-button color="primary" [matMenuTriggerFor]="viewActions"
            (click)="$event.preventDefault()">
            <mat-icon>more_horiz </mat-icon>
        </button>
    </div>
    <div layoutDir="row">
        <div layoutDir="row">
            <div layoutDir="row" class="left-row">
                <div layoutDir="row" style="align-items: center">
                    <div layoutDir="row" class="title" style="align-items: center">
                        <h3 class="main-title">
                            <a class="darker color-warn"> {{ mainTitle }} </a>
                        </h3>
                        <button class="edit-icon menu-button" matTooltip="Edit Report Name" *ngIf="canEditReport"
                            mat-icon-button color="primary" (click)="editReportName()">
                            <mat-icon>edit</mat-icon>
                        </button>
                    </div>

                    <mat-menu #viewActions="matMenu">
                        <button *ngIf="canEditReport" mat-menu-item (click)="openSaveReportDialog(true)">
                            <mat-icon matListIcon color="accent">save </mat-icon> Save
                        </button>
                        <button *ngIf="canAddReport" mat-menu-item (click)="openSaveReportDialog(false)">
                            <mat-icon matListIcon color="accent">save_as </mat-icon> Save As
                        </button>
                        <button *ngIf="canDeleteReport" mat-menu-item (click)="deleteReport()">
                            <mat-icon matListIcon color="accent">delete </mat-icon> Delete
                        </button>
                    </mat-menu>
                </div>
            </div>
        </div>

        <div class="right">
            <ng-container [ngTemplateOutlet]="template"></ng-container>
        </div>
    </div>
</div>
