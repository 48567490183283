import { Clipboard } from '@angular/cdk/clipboard';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { DatasetDetailsDto, DatasetType, DatasetsTypes } from '../../models/dataset.model';
import { DatasetService } from '../../services/api/dataset.service';
import { IToolbarSettings, ToolbarService } from '../../services/toolbar.service';
import { NewDatasetComponent } from './new-dataset/new-dataset.component';
import { MultiFileUploaderComponent } from '../attoms/multi-file-uploader/multi-file-uploader.component';


@Component({
    selector: 'dataset-list',
    templateUrl: './dataset-list.component.html',
    styleUrls: ['./dataset-list.component.scss']
})
export class DatasetListComponent implements OnInit {

    searchText: string = '';
    dataListOriginal: DatasetDetailsDto[] = [];
    datasetList: DatasetDetailsDto[] = [];
    DatasetsTypes = DatasetsTypes;
    constructor(
        public dialog: MatDialog,
        private _datasetService: DatasetService,
        private _toolbarService: ToolbarService,
        private _router: Router,
        private clipboard: Clipboard,
        private _snackbar: MatSnackBar,
    ) {
    }
    public async ngOnInit() {
        await this._toolbarService.setSettings(null, { showBackButton: false, title: 'Datasets' } as IToolbarSettings)
        await this.getDatasets();
    }

    async openCreateDialog(type: DatasetType = DatasetsTypes.DATASET) {
        const refresh = await this.dialog.open(NewDatasetComponent, { width: '560px', data: { type } }).afterClosed().toPromise();
        refresh && await this.getDatasets();
    }
    public async getDatasets() {
        try {
            var response = await this._datasetService.getAll().toPromise();

            if (!response.isSuccess)
                this._showMsg(`Error fetching datasets`);

            else {
                this._datasetService.updateChooserDatasetId(response?.result.find(x => x.Type === DatasetsTypes.PIPELINE_CHOOSER)?.Id);
                this.dataListOriginal = response?.result;
                this.datasetList = this.dataListOriginal.sort((a, b) => -1 * a.Type.localeCompare(b.Type));
            }

        } catch {
            this.dataListOriginal = [];
            this.datasetList = [];
        }
    }
    public navigateToSchema(dataset: DatasetDetailsDto) {
        const { Id, ProcessedDocs } = dataset;
        if (dataset.Type === DatasetsTypes.DATASET)
            this._router.navigate(['datasets', Id, ProcessedDocs > 0 ? 'records' : 'schema']);
        else
            this._router.navigate(['datasets', dataset.Id, 'queue']);

    }
    filterDatasets() {
        this.datasetList = this.dataListOriginal.filter(x => x.Title.toLowerCase().includes(this.searchText.toLowerCase()));
    }

    public closeSearch() {
        this.searchText = '';
        this.filterDatasets();
    }

    copyEmail(dataset: DatasetDetailsDto, event: Event) {
        // Reset isCopied for all datasets
        this.datasetList.forEach(ds => ds.IsCopied = false);

        // Copy the email and set isCopied to true for the current dataset
        this.clipboard.copy(dataset.EmailAlias);
        dataset.IsCopied = true;
        // Stop event propagation
        event.stopPropagation();
        // Reset the copied status after 5 seconds
        setTimeout(() => {
            dataset.IsCopied = false;
        }, 4000);
    }

    private _showMsg(msg: string) {
        this._snackbar.open(msg, 'close', {
            duration: 2000,
            politeness: 'polite'
        })
    }
    uploadDialog(datasetId: string, datasetType: DatasetType) {
        this.dialog.open(MultiFileUploaderComponent, { width: '800px', data: { datasetId, datasetType }, disableClose: false }).afterClosed();
    }
}

export { DatasetType };

