<disco-tab [appId]="'docpipeline'" [tabId]="datasetId" (context)="setContext($event)">
    <ng-template disco-tab-content>

        <div class="flex flex-col">
            <div class="body">
                <app-file-result-editor [data]="newRecord" [datasetId]="datasetId"></app-file-result-editor>
            </div>
            <div class="flex flex-row justify-end mt-4">
                <button class="flex gap-2" mat-flat-button color="primary" (click)="save()" [disabled]="loading">
                    <mat-spinner *ngIf="loading" [diameter]="20"></mat-spinner>
                    Save
                </button>
            </div>
        </div>
    </ng-template>
</disco-tab>
