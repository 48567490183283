import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseController } from '@discoverer/core';
import { createKeyFromName } from '../../../helpers/docpipeline-function';

@Component({
    selector: 'app-schema-fields',
    templateUrl: './schema-fields.component.html',
    styleUrls: ['./schema-fields.component.scss']
})

export class SchemaFieldsComponent extends BaseController {
    @Input() isDataLoaded: boolean;
    @Input() isEditMode: boolean;
    @Input() originalFields: FormArray;
    @Input() objectFormGroup: any;
    @Input() canAddField?: boolean = true;
    @Input() canDelete?: boolean = true;
    @Input() useWhiteBackground?: boolean = false;
    @Input() isNestEdit?: boolean = true;

    @Output() handleChange = new EventEmitter<FormArray>();
    @Output() newFieldAdded = new EventEmitter<boolean>();
    constructor(
        private _fb: FormBuilder) {
        super();
        this.originalFields = this._fb.array([]);
    }

    public handleItemChange(fields: FormArray, index: number, controlName: string, fullPath: string = ''): void {
        let group = fields.at(index) as FormGroup;
        if (index === fields.length - 1 && this.canAddField) {
            group = this._handleLastFieldChange(fields, group, index);
        }
        if (controlName == 'dataType') {
            this._handleDataTypeChange(group, index, controlName, fullPath);
            this.newFieldAdded.emit(true);
        }
        this.handleChange.emit(this.originalFields);
    }

    private _handleLastFieldChange(fields: any, group: FormGroup, index: number): FormGroup {
        const array = this._formArrayToArray(this._fb.array([group]));
        const newGroup = this._createFieldGroup(array[0], false);
        fields.insert(index, newGroup);
        group.reset({ dataType: 'string' });
        this.newFieldAdded.emit(true);
        return newGroup;
    }

    private _handleDataTypeChange(group: FormGroup, index: number, controlName: string, fullPath: string): void {
        const dataType = group.get('dataType').value;
        this._updateSubFields(group, dataType);
        this._updateFieldName(group, dataType);
        this._focusTheField(fullPath || index, controlName);
    }

    private _updateSubFields(group: FormGroup, dataType: string) {
        const subFields = (group.get('fields') || []) as FormArray;
        if (['object_array', 'object'].includes(dataType)) {
            if (subFields.length === 0) {
                group.addControl('fields', this._fb.array([]));
                (group.controls['fields'] as FormArray).push(this._emptyFormGroup());
                this.objectFormGroup = group;
            }
        } else if (subFields?.length) {
            group.removeControl('fields');
            this.objectFormGroup = null;
        }
    }

    private _updateFieldName(group: FormGroup, dataType: string) {
        const originalDataType = group.get('originalDataType')?.value;
        originalDataType !== dataType ?
            group.get('fieldName').setValue(createKeyFromName(group.get('display').value)) :
            group.get('fieldName').setValue(group.get('originalFieldName').value);
    }

    public drop(event: CdkDragDrop<string[]>): void {
        const controls = this.getMainFieldsControls();
        if (event.previousIndex !== controls.length - 1 && event.currentIndex !== controls.length - 1) {
            moveItemInArray(controls, event.previousIndex, event.currentIndex);
        }
        this.handleChange.emit(this.originalFields);
    }

    public getMainFieldsControls(): FormGroup[] {
        return this.originalFields?.controls as FormGroup[];
    }

    public getNestedFieldsControls(field: any) {
        return field.controls.fields.controls;
    }

    public getConnectedLists(): string[] {
        return this.getMainFieldsControls().map((_, i) => `nestedList-${i}`);
    }

    public dropNested(event: CdkDragDrop<string[]>, field: any) {
        const nestedFields = this.getNestedFieldsControls(field);
        if (this._isReorderingWithinSameContainer(event, nestedFields)) {
            moveItemInArray(nestedFields, event.previousIndex, event.currentIndex);
        } else {
            const prevField = this._findPreviousField(event);
            if (prevField) {
                this._transferItemBetweenContainers(prevField, nestedFields, event);
            }
        }
        this.handleChange.emit(this.originalFields);
    }

    private _findPreviousField(event: CdkDragDrop<string[]>): any {
        return this.getMainFieldsControls().find((mainField) => {
            if (this._isObject(mainField.value.dataType)) {
                return this.getNestedFieldsControls(mainField) === event.previousContainer.data;
            }
            return false;
        });
    }

    private _transferItemBetweenContainers(prevField: any, nestedFields: any[], event: CdkDragDrop<string[]>): void {
        const prevNestedFields = this.getNestedFieldsControls(prevField);
        if (prevNestedFields) {
            transferArrayItem(
                prevNestedFields,
                nestedFields,
                event.previousIndex,
                event.currentIndex
            );
        }
    }

    private _isReorderingWithinSameContainer(event: CdkDragDrop<string[]>, nestedFields: any[]): boolean {
        return event.previousContainer === event.container &&
            event.previousIndex !== nestedFields.length - 1 &&
            event.currentIndex !== nestedFields.length - 1;
    }

    public addItem(fields: any, index: number, fullPath: string = '', controlName: string): void {
        if (index === fields.length - 1) {
            this._handleLastFieldAddition(fields, index);
        }
        this._focusTheField((fullPath ? fullPath : index), controlName);
        this.handleChange.emit(this.originalFields);
    }

    private _handleLastFieldAddition(fields: any, index: number): void {
        const group = fields.at(index) as FormGroup;
        const array = this._formArrayToArray(this._fb.array([group]));
        fields.insert(index, this._createFieldGroup(array[0], false));
        group.reset({ dataType: 'string' });
    }

    public deleteField(fields: any, index: number): void {
        const group = fields.at(index) as FormGroup;
        fields.removeAt(index);
        if (this._isObject(group.get('dataType').value)) {
            this.objectFormGroup = null;
        }
        this.handleChange.emit(this.originalFields);
    }

    private _formArrayToArray(group: FormArray): any[] {
        return group.controls.map(control => {
            if (!(control instanceof FormGroup)) {
                throw new Error('Expected FormGroup instance');
            }
            const result = {
                fieldName: control.get('fieldName')?.value || createKeyFromName(control.get('display')?.value),
                display: control.get('display')?.value,
                type: control.get('dataType')?.value,
                description: control.get('description')?.value,
            };
            if (control.get('fields')) {
                result['fields'] = this._formArrayToArray(control.get('fields') as FormArray);
            }
            return result;
        }
        );
    }

    public addFieldAtIndex(fields: any, index: number, fullPath: string = ''): void {
        fields.insert(index, this._createFieldGroup({}, false));
        this._focusTheField(fullPath ? fullPath : index);
        this.handleChange.emit(this.originalFields);
    }

    private _emptyFormGroup(): FormGroup {
        return new FormGroup({
            dataType: new FormControl('string'),
            fieldName: new FormControl(''),
            display: new FormControl(''),
            description: new FormControl(''),
        });
    }

    private _createFieldGroup(fieldData: any, disabled: boolean = true): FormGroup {
        const fieldGroup = this._fb.group({
            fieldName: new FormControl({ value: fieldData?.fieldName || createKeyFromName(fieldData?.display), disabled: disabled }),
            display: new FormControl({ value: fieldData?.display || '', disabled: disabled }, [Validators.required]),
            dataType: new FormControl({ value: fieldData?.type || 'string', disabled: disabled }),
            description: new FormControl({ value: fieldData?.description || '', disabled: disabled }),
            originalDataType: new FormControl({ value: fieldData.type || null, disabled: disabled }),
            originalFieldName: new FormControl({ value: fieldData.fieldName || null, disabled: disabled }),
        });
        if (this._isObject(fieldData.type)) {
            const itemGroups = fieldData.fields ? fieldData.fields.map(value => this._createFieldGroup(value, disabled)) : [];
            fieldGroup.addControl('fields', this._fb.array(itemGroups));
        }
        return fieldGroup;
    }

    private _focusTheField(path: any, controlName: string = ''): void {
        const checkedControlName = controlName === '' ? 'display' : controlName === 'display' ? 'description' : 'display';
        setTimeout(() => {
            const element = document.getElementById(`${checkedControlName}-${path}`);
            if (element) {
                element.focus();
                const parentForm = element.closest('form');
                if (parentForm) {
                    parentForm.classList.add('active');
                }
            }
        }, 0);
    }

    private _isObject(value) {
        return value === 'object_array' || value === 'object';
    }
}
