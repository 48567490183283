<div class="wrapper">
    <div class="vertical-navigation-header">
        <div class="title-wrapper">
            <div class="flex items-center gap-2">
                <div class="back-item" *ngIf="toolbarSettings?.showBackButton" (click)="back()">
                    <mat-icon>arrow_back</mat-icon>
                </div>
                <div class="dataset-title-wrapper items-center flex-1">
                    <span class="app-name">{{ toolbarSettings?.title }}</span>
                    <mat-icon class="edit-icon" *ngIf="toolbarSettings?.showBackButton"
                        (click)="editDataSetTitleDialog()">edit</mat-icon>
                </div>
            </div>
            <div class="reprocess-btn-container" *ngIf="toolbarSettings?.showBackButton">
                <button mat-flat-button color="primary" class="import-doc" (click)="openImportDocument()">
                    Import Documents
                </button>
                <app-reprocess-action [datasetId]="datasetId"></app-reprocess-action>
            </div>
            <span *ngIf="toolbarSettings?.subTitle" class="text-subtitle">
                {{ toolbarSettings?.subTitle }}
                <mat-icon class="copy-icon" *ngIf="!isSubTitleCopied"
                    (click)="copyEmail(toolbarSettings?.subTitle, 'subTitle')" matTooltip="Copy">content_copy</mat-icon>
                <mat-icon class="check-icon" *ngIf="isSubTitleCopied">check</mat-icon>
            </span>
        </div>
    </div>
    <div class="vertical-navigation-content">
        <div *ngFor="let item of sidebarItems">
            <ng-container *ngTemplateOutlet="ai_group; context: {item: item}"></ng-container>
            <div *ngIf="item?.children">
                <ng-container *ngFor="let child of item?.children">
                    <ng-container *ngTemplateOutlet="ai_basic; context: {item: child}"></ng-container>
                </ng-container>
            </div>
        </div>
    </div>

    <div class="vertical-navigation-footer">
        <div class="flex items-center w-full px-2 py-8 border-t">
            <button mat-icon-button [matMenuTriggerFor]="userActions">
                <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
            </button>
            <span class="flex flex-col leading-none">
                <span>{{user?.name}}</span>
                <span class="mt-1.5 text-md font-medium">{{user?.email}}</span>
            </span>
        </div>
        <mat-menu [xPosition]="'before'" class="user-actions" #userActions="matMenu">
            <button mat-menu-item>
                <span class="flex flex-col leading-none">
                    <span>Signed in as</span>
                    <span class="mt-1.5 text-md font-medium">{{user?.email}}</span>
                </span>
            </button>
            <mat-divider class="my-2"></mat-divider>
            <button mat-menu-item [routerLink]="['/user-settings', 'profile']">
                <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
                <span>Profile</span>
            </button>
            <button mat-menu-item [routerLink]="['/user-settings', 'password']">
                <mat-icon [svgIcon]="'heroicons_outline:lock-closed'"></mat-icon>
                <span>Password</span>
            </button>
            <button mat-menu-item [routerLink]="['/user-settings', 'plans']">
                <!-- <mat-icon [svgIcon]="'heroicons_outline:banknotes'"></mat-icon> -->
                <mat-icon>payment</mat-icon>
                <span>Billing and Plans</span>
            </button>
            <!-- <button mat-menu-item>
                <mat-icon [svgIcon]="'heroicons_outline:table-cells'"></mat-icon>
                <mat-icon> table_chart</mat-icon>
                <span>Billing History</span>
            </button> -->
            <button mat-menu-item [routerLink]="['/user-settings', 'usage']">
                <mat-icon [svgIcon]="'heroicons_outline:chart-pie'"></mat-icon>
                <span>Usage</span>
            </button>

            <mat-divider class="my-2"></mat-divider>
            <button mat-menu-item (click)="signOut()">
                <mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
                <span>Sign out</span>
            </button>
        </mat-menu>

    </div>
</div>

<ng-template #ai_basic let-item="item">
    <a *ngIf="item?.route" [routerLink]="[item?.route]" [routerLinkActive]="'active'"
        [routerLinkActiveOptions]="{exact: false}">
        <div class="flex items-center justify-between item-wrapper item-child">
            <div class="flex items-center justify-start ">
                <mat-icon *ngIf="item?.icon" [svgIcon]="item?.icon"></mat-icon>
                <p>{{item?.title}}</p>
            </div>
            <div class="flex gap-1">
                <div *ngIf="item?.leftBadge && !item.isLoading && item.id === 'queue'">
                    <span [ngClass]="{
                        'processing' : item?.leftBadge,
                        'pulse-animation': item?.leftBadge != 0 && item.id === 'queue'
                        }" class="badge default-badge-color">{{item?.leftBadge}}</span>

                </div>
                <span *ngIf="item?.rightBadge && !item.isLoading" matTooltip="Processed Items"
                    class="badge default-badge-color">{{item?.rightBadge}}</span>
                <span *ngIf="item?.isLoading">
                    <mat-spinner diameter="20"></mat-spinner>
                </span>
            </div>
        </div>
    </a>

    <div *ngIf="!item?.route" class="flex items-center justify-between item-wrapper item-child">
        <div class="flex items-center justify-start ">
            <mat-icon *ngIf="item?.icon" [svgIcon]="item?.icon"></mat-icon>
            <p>{{item?.title}}</p>
        </div>
        <span *ngIf="item?.rightBadge" class="badge default-badge-color">{{item?.rightBadge || '0'}}</span>
        <span *ngIf="item?.leftBadge" class="badge default-badge-color">{{item?.leftBadge || '0'}}</span>
    </div>
</ng-template>



<ng-template #ai_group let-item="item">
    <div class="flex items-center justify-between item-wrapper item-group">
        <div class="flex items-center justify-start ">
            <mat-icon *ngIf="item?.icon" [svgIcon]="item?.icon"></mat-icon>
            <p>{{item?.title}}</p>
        </div>
        <span *ngIf="item?.badge" class="badge default-badge-color">{{item?.badge || '0'}}</span>
    </div>
</ng-template>