import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { SchemaService } from '../../services/schema.service';

@Injectable({providedIn: 'root'})
export class FileDetailsHandlerService {

    private _fileResults$ = new ReplaySubject<{ [key: string]: any }[]>(1);
    public oFileResults = this._fileResults$.asObservable();



    constructor(public schemaService: SchemaService) { }

    public setFileResults(results: { [key: string]: any }[]) {
        this._fileResults$.next(results);
    }

    public async getFileResults() {
        return await this.oFileResults.pipe(take(1)).toPromise()
    }


}
