import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DRQuery, IDynamicReport } from '../classes';
import { LoadingState } from '../classes/loading-state';


@Injectable()
export class ExportDataTableService {

    public oLoadingStatusResult: Observable<LoadingState>;

    protected _bsLoadingStatus$: BehaviorSubject<LoadingState>;

    constructor(
        private http: HttpClient) {
        this._bsLoadingStatus$ = new BehaviorSubject<LoadingState>({ status: "Success" });
        this.oLoadingStatusResult = this._bsLoadingStatus$.asObservable();
    }

    public async ExportToExcel(url: string, report: IDynamicReport, query: DRQuery ): Promise<any> {
        this._bsLoadingStatus$.next({status:"Busy"});
        var data = Object.assign(query, { columnSettings: report.tableView.columnSettings });
        return this.http.post(url, data, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }), responseType: 'blob'
        }).pipe(tap({
                    next: (x) => {
                        this._bsLoadingStatus$.next({status:"Success"})
                    },
                    error: (err) => {
                        this._bsLoadingStatus$.next({status:"Failure", errorDescription : err})
                    }
                })).toPromise();
    }

    public async ExportPivotDataToExcel(data: any[], columns: any[]): Promise<any> {
        this._bsLoadingStatus$.next({status:"Busy"});

        return this.http.post(`/api/es/export/ExportPivotDataToExcel`, { data, columns }, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }), responseType: 'blob'
        }).pipe(tap({
                    next: (x) => {
                        this._bsLoadingStatus$.next({status:"Success"})
                    },
                    error: (err) => {
                        this._bsLoadingStatus$.next({status:"Failure", errorDescription : err})
                    }
                })).toPromise();
    }



}
