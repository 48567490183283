import { Component, Input, OnInit } from '@angular/core';
import { DatasetFileService } from '../../../../../services/api/dataset-file.service';
import { FilePathBuilder } from '../../../../../services/api/helpers/file-path-builder';
/**
 * Component to display a files as cards.
 */
@Component({
    selector: 'app-file-card-viewer',
    templateUrl: './file-card-viewer.component.html',
    styleUrls: ['./file-card-viewer.component.scss']
})
export class FileCardViewerComponent implements OnInit {
    @Input() datasetId: string;
    @Input() fileId: string;

    fileNames: string[] = [];
    totalFilesCount: number;
    filesFrom: 'email' | 'zip' = 'email';
    constructor(private _datasetFileService: DatasetFileService) { }

    async ngOnInit(): Promise<void> {
        const { isSuccess, result: { fileNames, total } } = await this._datasetFileService.getEmailAttachments(this.datasetId, this.fileId).toPromise()
        this.fileNames = fileNames;
        this.totalFilesCount = total;
    }

    async downloadAttachment(fileName: string): Promise<void> {
        var response = await this._datasetFileService.downloadFileFromPath(this.datasetId, this.getFilePath(fileName)).toPromise();
        const blob = new Blob([response.body], { type: response.body.type });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.click();
    }

    saveAllAttachments(): void {
        this.fileNames.forEach(async (fileName) => {
            await this.downloadAttachment(fileName);
        });
    }

    private getFilePath(filename: string): string {
        var filePathMapping = {
            'email': FilePathBuilder.buildEmailFilePath,
            'zip': FilePathBuilder.buildZipFilePath
        }
        return filePathMapping[this.filesFrom](this.fileId, filename);
    }
}
