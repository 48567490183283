import { ChangeDetectorRef, Component, OnInit, Optional } from '@angular/core';
import { DatasetActionType, ParsedTabs } from '../../models/enums';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DiscovererQueryService } from '@discoverer/core';
import { ToolbarService } from '../../services/toolbar.service';
import { EmitterService } from '../../services/emitter.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmationDialogComponent } from '../attoms/confirmation-dialog/confirmation-dialog.component';
import { ActionModel } from '../../models/action.model';
import { ActionService } from '../../services/api/action.service';
import { actionTypeImage } from '../../models/constants';
import { ActionDetailsDialogComponent } from './action-details-dialog/action-details-dialog.component';
import { ActionLogsDialogComponent } from './action-logs-dialog/action-logs-dialog.component';
import { ERROR_SNACKBAR_OPTIONS, SUCCESS_SNACKBAR_OPTIONS } from '../home/edit-title-dialog/edit-title-dialog';
import { TaskService } from '../../services/api/task.service';
import { CommonService } from '../../services/common.service';

@Component({
    selector: 'app-action',
    templateUrl: './actions.component.html',
    styleUrls: ['./actions.component.scss']
})

export class ActionsComponent implements OnInit {
    public datasetId: string;
    public actionList: ActionModel[] = [];
    public isLoading = true;
    public deleteIsLoadingId: string = null;

    constructor(
        @Optional() public dialogRef: MatDialogRef<any>,
        protected dialog: MatDialog,
        public queryService: DiscovererQueryService,
        private _actionService: ActionService,
        private _activatedRouter: ActivatedRoute,
        private _toolbarService: ToolbarService,
        private _emitterService: EmitterService,
        private _matSnackBar: MatSnackBar,
        private _cdRef: ChangeDetectorRef,
        private _taskService: TaskService,
        private _commonService: CommonService
    ) { }

    async ngOnInit() {
        this._activatedRouter.params.subscribe(async params => {
            this.datasetId = params['datasetId'];
            this.loadData();
        });
    }

    public create() {
        var data = {
            id: "",
            datasetId: this.datasetId
        };
        this.callDetailsDialog(data);
    }

    public edit(id: string) {
        const selectedAction = this.actionList.find(action => action.id === id);
        if (!selectedAction) {
            this.showSnackBarError(`action of id ${id} not found`);
            return;
        }
        selectedAction.datasetId = this.datasetId;
        console.log('fafa selectedAction', selectedAction);
        this.callDetailsDialog(selectedAction);
    }

    public openLogs(id: string) {
        this.callLogsDialog({ id, datasetId: null });
    }
    public delete(id: string) {
        this.dialog.open(ConfirmationDialogComponent, { data: { message: `Are you sure you want to delete this action ?` } }).afterClosed().subscribe(
            res => {
                if (res) {
                    this.isLoading = true;
                    this.deleteIsLoadingId = id;
                    this._actionService.delete(id, this.datasetId).subscribe(async response => {
                        if (response.isSuccess) {
                            await this.reorderActionListAfterRemove(id);
                            await this.onDeleteSuccess(response.result);
                        }
                        else {
                            this.showSnackBarError(`Not able to delete action with id ${id}`);
                            return;
                        }
                        this.isLoading = false;
                        this.deleteIsLoadingId = id;
                    });
                } else {
                    this.isLoading = false;
                    this.deleteIsLoadingId = null;
                }
            }
        )
    }

    public getActionImage(type: DatasetActionType): string {
        return actionTypeImage.get(type)
    }
    private async onDeleteSuccess(taskId: string) {
        await this._taskService.waitForTask(taskId);
        await this._commonService.updateColumnAndAssets(ParsedTabs.AllRecordTabName, this.datasetId);
        this.showSnackBarSuccess(`Action deleted successfully`);
        this.getAll();
    }

    private async reorderActionListAfterRemove(id: string) {
        const updatedOrder = this.actionList.filter(action => action.id !== id)?.map((action, index) => ({ ...action, order: index + 1 }));
        const mappedActionList = updatedOrder.map(action => {
            return { id: action.id, order: action.order };
        });
        this._actionService.reOrder(this.datasetId, mappedActionList).subscribe(response => {
            if (!response.isSuccess) {
                this.showSnackBarError("Something went wrong during reorder action list !!!");
                return;
            }
        });
    }

    private callDetailsDialog(data: any = null) {
        this.isLoading = false;
        this.dialog.open(ActionDetailsDialogComponent, {
            data: { data: data, actionList: this.actionList },
            maxWidth: '1280px',
            width: '1280px',
            maxHeight: '800px',
            panelClass: 'panel-class-details-dialog',
            disableClose: false
        }).afterClosed().subscribe(res => {
            this.getAll();
        });
    }

    private callLogsDialog(data: any) {
        this.isLoading = false;
        this.dialog.open(ActionLogsDialogComponent, {
            data: data,
            maxWidth: '1280px',
            width: '1280px',
            maxHeight: '800px',
            panelClass: 'panel-class-details-dialog',
            disableClose: false
        });
    }

    private showSnackBarError(message: string) {
        this._matSnackBar.open(message, 'X', ERROR_SNACKBAR_OPTIONS);
    }
    private showSnackBarSuccess(message: string) {
        this._matSnackBar.open(message, 'X', SUCCESS_SNACKBAR_OPTIONS);
    }
    private async loadData() {

        this.queryService.resetFilters()
        this.queryService.refresh()
        this._emitterService.setDatasetId(this.datasetId)

        await this._toolbarService.setSettings(this.datasetId, this._activatedRouter.snapshot.routeConfig.data);

        this.getAll();
    }
    private getAll() {
        if (!this.datasetId)
            return [];

        this._actionService.getAll(this.datasetId).subscribe(response => {
            this.isLoading = false;
            if (!response.isSuccess) {
                this.showSnackBarError("Something went wrong during fetch data !!!");
                return;
            }
            else {
                this.actionList = response.result;
                this._cdRef.detectChanges();
            }
        })
    }
}
