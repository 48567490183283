import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SchemaService } from '@app/ignite/components/reports/services/schema.service';
import { ERROR_SNACKBAR_OPTIONS } from '../../../home/edit-title-dialog/edit-title-dialog';

export interface DocumentDialogFormData {
    title: string;
    description: string;
}

@Component({
    selector: 'app-dataset-document-title',
    templateUrl: './dataset-document-title.dialog.html',
    styleUrls: ['./dataset-document-title.dialog.scss']
})
export class DatasetDocumentTitleDialog implements OnInit {

    constructor(
        private dialogRef: MatDialogRef<DatasetDocumentTitleDialog>,
        private schemaService: SchemaService,
        private _snackbar: MatSnackBar,
        @Inject(MAT_DIALOG_DATA) public data: { isNew: string, formData: DocumentDialogFormData, oldDocKey: string }) { }

    ngOnInit(): void { }
    async save() {
        this.dialogRef.close(this.data.formData);
    }
    close() {
        this.dialogRef.close()
    }
}
