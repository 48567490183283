<div class="wrapper">
    <div class="header">
        <p class="title">Import Documents</p>
        <button mat-icon-button (click)="closeDialog()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <mat-radio-group *ngIf="datasetType === datasetTypes.DATASET" aria-label="Select an option"
        [(ngModel)]="renderdView">
        <mat-radio-button value="upload">Upload File</mat-radio-button>
        <mat-radio-button value="manual">Use Form </mat-radio-button>
    </mat-radio-group>
    <div class="main" *ngIf="renderdView === 'upload'">
        <div class="files-list-wrapper" *ngIf="fileList">
            <div *ngFor="let file of fileList" class="file-list-item">
                <div class="flex-start-center">
                    <mat-icon>{{file.icon}}</mat-icon>
                    <p>{{file.name}}</p>
                </div>
                <div>
                    <div *ngIf="file.uploadPercentage !== -1 && file.uploadPercentage !== 100"
                        class="uploading-container flex-start-center">
                        <p>{{file.uploadPercentage || 0}}%</p>
                        <mat-progress-bar [value]="file.uploadPercentage"></mat-progress-bar>
                    </div>
                    <div *ngIf="file.uploadPercentage === 100" class="flex-start-center">
                        Completed<mat-icon style="color: var(--ai-green-success);">check_circle</mat-icon>
                    </div>
                    <div *ngIf="file.uploadPercentage === -1" class="flex-start-center">
                        Not Uploaded<mat-icon style="color: var(--ai-red-error);">cancel</mat-icon>
                    </div>
                </div>
            </div>
        </div>
        <div class="file-upload-wrapper">
            <custom-file-input [datasetId]="datasetId" [isUploading]="isUploading" [isMultipleUpload]="true"
                [uploadFiles]="false" (fileList)="processUploadedFiles($event)"></custom-file-input>
        </div>
    </div>
    <div class="main" *ngIf="renderdView === 'manual'">
        <app-add-new-record [datasetId]="datasetId"></app-add-new-record>
    </div>
</div>