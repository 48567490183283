import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IColumnSetting } from '@discoverer/core/services/classes/discoverer.class';
import { DiscoTabContext } from '@discoverer/dynamic-reports';
import { DatasetFileService } from '../../../services/api/dataset-file.service';
import { ERROR_SNACKBAR_OPTIONS, SUCCESS_SNACKBAR_OPTIONS } from '../../home/edit-title-dialog/edit-title-dialog';

@Component({
    selector: 'app-add-new-record',
    templateUrl: './add-new-record.component.html',
    styleUrls: ['./add-new-record.component.scss']
})
export class AddNewRecordComponent implements OnInit {
    newRecord = {}
    @Input() datasetId: string;
    tabColumns: IColumnSetting[] = []
    loading = false
    constructor(
        private _datasetFileService: DatasetFileService,
        private _snackBar: MatSnackBar
    ) { }

    ngOnInit(): void {
    }
    private _removeEmptyLines(field: string) {
        const keys = field.split('.');
        for (const key of keys) {
            this.newRecord[key] =
                this.newRecord[key].filter(data => Object.values(data).some(r => !!(r?.toString())));
        }
    }
    setContext($event: DiscoTabContext) {
        this.tabColumns = $event.columnSettings
    }
    async save() {
        this.loading = true
        var arrayCols = this.tabColumns.filter(col => col.dataType === 'object_array')
        arrayCols.forEach(col => this._removeEmptyLines(col.fieldName))
        const {isSuccess, result} = await this._datasetFileService.addNewRecord(this.datasetId, this.newRecord).toPromise()
        if (isSuccess) {
            this._snackBar.open('Record added successfully. Clearing old data', 'close', SUCCESS_SNACKBAR_OPTIONS)
            console.log('Record added successfully')
            this.newRecord = {}
        } else {
            this._snackBar.open(`Error adding record: ${result}`, 'close', ERROR_SNACKBAR_OPTIONS)
        }
        this.loading = false
    }
}
