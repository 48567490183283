import { Clipboard } from '@angular/cdk/clipboard';
import { Location } from "@angular/common";
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TabSettingsService } from "@discoverer/core";
import { combineLatest } from 'rxjs';
import { Field } from '../../helpers/docpipeline-function';
import { DatasetFileService } from '../../services/api/dataset-file.service';
import { EmitterService } from '../../services/emitter.service';
import { DocumentViewDetailsDirective } from "./document-view-details.directive";
import { FileDetailsHandlerService } from './file-details-handler.service';
import { DatasetService } from '../../services/api/dataset.service';
import { take } from 'rxjs/operators';
import { DatasetsTypes } from '../../models/dataset.model';
import { title } from 'process';
const NESTED_TYPES = ['object', 'object_array'];
const getFirstTwoNonObjectKeys = (schema: { [key: string]: Field }) =>{
    if(!schema) return [];
    return Object.keys(schema).filter(key => !NESTED_TYPES.includes(schema[key].type)).slice(0, 2);
}
@Component({
    selector: 'app-document-view-details',
    templateUrl: './document-view-details.component.html',
    styleUrls: ['./document-view-details.component.scss']
})
export class DocumentViewDetailsComponent extends DocumentViewDetailsDirective implements OnInit {
    public tabId: any;
    public columnList: any;
    public source: 'records' | 'queue';
    public fileKey: 'File.file_id' | 'Id';
    limit: number = 20;
    constructor(
        @Inject(MAT_DIALOG_DATA) data,
        _datasetFileService: DatasetFileService,
        clipboard: Clipboard,
        location: Location,
        cdr: ChangeDetectorRef,
        _router: Router,
        _tabSettingService: TabSettingsService,
        _dialog: MatDialog,
        _snackBar: MatSnackBar,
        private _fileDetailsHandlerService: FileDetailsHandlerService,
        public _datasetService: DatasetService,
        _activatedRoute: ActivatedRoute,
        _emitterService: EmitterService
    ) {
        super(
            data,
            _datasetFileService,
            clipboard,
            location,
            cdr,
            _router,
            _tabSettingService,
            _dialog,
            _snackBar,
            _activatedRoute,
            _emitterService
        );
        this.tabId = data.routeSnapshot.data['subDatasetId'] || data.routeSnapshot.data['tabId'];
        this.columnList = data.routeSnapshot.data['columnList'];
        this.source = data.routeSnapshot.data['source'];
        this.fileKey = data.routeSnapshot.data['fileKey'];
    }
    public async ngOnInit() {
        await super.ngOnInit();
        await this._subscribeToResults();
    }

    onScroll(event: any): void {
        const scrollPosition = event.target.scrollTop;
        const scrollHeight = event.target.scrollHeight;
        const clientHeight = event.target.clientHeight;

        // If we're near the bottom, load more items
        if (scrollHeight - (scrollPosition + clientHeight) < 100) {
              this.limit += 10;
        }
    }

    private async _subscribeToResults() {
        this.fieldsLoading = true;
        if ((await this._datasetService.oDatasetDetails.pipe(take(1)).toPromise()).Type === DatasetsTypes.PIPELINE_CHOOSER) {
            this.titles = [];
            this.fieldsLoading = false;
        } else {
            this.subscriptions.push(combineLatest([
                this._fileDetailsHandlerService.oFileResults,
                this._fileDetailsHandlerService.schemaService.oCurrentSchema
            ]).subscribe(
                ([fileResults, currentSchema]) => this._processFields(fileResults, currentSchema),
                () => this.fieldsLoading = false
            ));

        }
    }

    private _processFields(fileResults: { [key: string]: any }[], currentSchema: any): void {
        this.titles = [];
        try {
            const firstTwoFields = getFirstTwoNonObjectKeys(currentSchema);
            this.titles = firstTwoFields.map(fld => fileResults[this.resultIndex >= 0 ? this.resultIndex : 0][fld]);
            this.fieldsLoading = false;
        } catch (error) {
            console.error('Error processing fields:', error);
        }
    }
}
