import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { DatasetService } from '@app/ignite/components/reports/services/api/dataset.service';
import { TaskService } from '@app/ignite/components/reports/services/api/task.service';
import { IgniteTabService } from '@app/ignite/components/reports/services/ignite-tab.service';
import { ERROR_SNACKBAR_OPTIONS, SUCCESS_SNACKBAR_OPTIONS } from '../../../home/edit-title-dialog/edit-title-dialog';

@Component({
    selector: 'field-from-files-dialog',
    templateUrl: './field-from-files.dialog.html',
    styleUrls: ['./field-from-files.dialog.scss']
})
export class FieldFromFilesDialog {
    @Output() generatingFields = new EventEmitter<{ isGenerating: boolean, documentKey?: string }>();
    @Input() datasetId: string;

    isUploading: boolean = false;
    constructor(
        private _datasetService: DatasetService,
        private _taskService: TaskService,
        private _igniteTabService: IgniteTabService,
        private _snackbar: MatSnackBar,
    ) { }

    emitGeneratingFields(value: { isGenerating: boolean, documentKey?: string }) {
        this.generatingFields.emit(value)
    }

    public async handleFileList(fileList: FileList) {
        await this._generateSchema(fileList[0])
    }


    private async _generateSchema(file: File): Promise<string> {
        try {
            this.isUploading = true;
            this.emitGeneratingFields({ isGenerating: true });
            const formData = await this._createFormData(file);
            const response = await this._datasetService.createFieldsByFile(this.datasetId, formData).toPromise();

            if (!response?.isSuccess) {
                this.isUploading = false;
                this._handleError();
                return null;
            }

            const successOpt = await this._executeTask(response?.result?.taskId);

            // const currentDocument = await this._schemaService.getCurrentDocument();

            if (successOpt) {
                await this._handleSuccess(response?.result.fileId);
                return response?.result.fileId;
            } else {
                this._handleError();
                return null;
            }
        } catch {
            this._handleError();
            return null;
        } finally {
            this.emitGeneratingFields({ isGenerating: false });
            this.isUploading = false;
        }
    }

    private _handleError(): void {
        this._showSnackbar(`Error while generating schema`, ERROR_SNACKBAR_OPTIONS);
    }

    private async _handleSuccess(fileId: any): Promise<void> {
        if (fileId) localStorage.setItem(this.datasetId, fileId);
        const files = await this._igniteTabService.initTab('Results', (await this._datasetService.getFields(this.datasetId).toPromise()).result, this.datasetId);
        await this._datasetService.createOrUpdateAssets(this.datasetId, files).toPromise();
        // await this._datasetFileService.reprocessById(this.datasetId, fileId).toPromise();
        this._showSnackbar(`Schema generated successfully`, SUCCESS_SNACKBAR_OPTIONS);
    }

    private async _createFormData(file: File): Promise<FormData> {
        let formData = new FormData();
        // const currentDocument = await this._schemaService.getCurrentDocument();
        formData.append('inputFile', file);
        // formData.append('currentDocument', JSON.stringify(''));
        formData.append('isTest', String(true));
        return formData;
    }

    private async _executeTask(taskId: string): Promise<boolean> {
        return await this._taskService.waitForTask(taskId);
    }

    private _showSnackbar(msg: string, config: MatSnackBarConfig) {
        this._snackbar.open(msg, 'close', config);
    }
}
