<div class="search-header-container header">
    <div class="line1">
        <div *ngIf="queryService" class="filter-groups" layoutDir="row">
            <!--Search input-->
            <div class="groups-wrapper" style="margin-bottom: -5px;" >
                <search-input-widget [animate]="true" class="search-bar color-accent" label="Search" [searchValue]="searchValue"
                    (searchEvent)="searchTextUpdate($event)">
                </search-input-widget>
            </div>
        </div>
    </div>
</div>
