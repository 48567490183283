import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DialogResolverService, DialogRouteConfig } from '@discoverer/core';
import { environment } from '@env/environment';
import { ActionsComponent } from './common/actions/actions.component';
import { QueueActionsComponent } from './common/queue/queue-actions/queue-actions.component';
import { QueueComponent } from './common/queue/queue.component';
import { ResultsActionsComponent } from './common/results/results-actions/results-actions.component';
import { ResultsComponent } from './common/results/results.component';
import { SchemaHandlerComponent } from './common/schema-handler/schema-handler.component';
import { SettingsComponent } from './common/settings/settings.component';
import { DocumentViewDetailsComponent } from './common/shared-pages/document-details/document-view-details.component';
import { ActionDetailsDialogComponent } from './common/actions/action-details-dialog/action-details-dialog.component';
import { ActionLogsDialogComponent } from './common/actions/action-logs-dialog/action-logs-dialog.component';
import { ConnectionsComponent } from './common/connections/connections.component';


const dlgSettings = {
    data: {
        isBack: true
    },
    position: { top: '0', bottom: '0', left: '0', right: '0' },
    minWidth: '100vw',
    panelClass: 'panel-class-details-dialog',
    disableClose: false
} as DialogRouteConfig;

const resultsDlgSettings = {
    data: {
        isBack: true
    },
    position: { top: '0', bottom: '0' },
    maxWidth: '100vw',
    width: '100vw',
    panelClass: 'panel-class-details-dialog',
    disableClose: false
} as DialogRouteConfig;

const redirectRoutes: Routes = [
    {
        path: 'schema',
        component: SchemaHandlerComponent,
        data: {
            showBackButton: true
        }
    },
    {
        path: 'actions',
        component: ActionsComponent,
        data: {
            showBackButton: true
        },
        children: [
            {
                path: ':fileId',
                component: ActionDetailsDialogComponent,
                resolve: { dlgRef: DialogResolverService },
                data: {
                    dlg: resultsDlgSettings,
                    appId: environment.auth.productKey,

                }
            },
            {
                path: ':fileId',
                component: ActionLogsDialogComponent,
                resolve: { dlgRef: DialogResolverService },
                data: {
                    dlg: resultsDlgSettings,
                    appId: environment.auth.productKey,

                }
            },
            {
                path: '',
                outlet: 'actions',
                component: ResultsActionsComponent
            }
        ]
    },
    {
        path: 'settings',
        component: SettingsComponent,
        data: {
            showBackButton: true
        }
    },
    {
        path: 'connections',
        component: ConnectionsComponent,
        data: {
            showBackButton: true
        }
    },
    {
        path: 'records',
        component: ResultsComponent,
        data: {
            appId: environment.auth.productKey,
            tabId: 'records',
            id: 'default',
            showBackButton: true
        },
        children: [
            {
                path: ':fileId',
                component: DocumentViewDetailsComponent,
                resolve: { dlgRef: DialogResolverService },
                data: {
                    appId: environment.auth.productKey,
                    tabId: 'datasetId',
                    columnList: ['File.file_id', 'File.result_index'],
                    source: 'records',
                    fileKey: 'File.file_id',
                    dlg: resultsDlgSettings
                }
            },
            {
                path: '',
                outlet: 'actions',
                component: ResultsActionsComponent
            }
        ]
    },
    {
        path: 'queue',
        component: QueueComponent,
        data: {
            appId: environment.auth.productKey,
            tabId: 'queue',
            id: 'default',
            showBackButton: true
        },
        children: [
            {
                path: ':fileId',
                component: DocumentViewDetailsComponent,
                resolve: { dlgRef: DialogResolverService },
                data: {
                    appId: environment.auth.productKey,
                    tabId: 'queue',
                    columnList: [
                        'Id',
                        'OriginalFileName',
                        'FileExtension',
                        'CreatedAt',
                        'SplitFiles.Status',
                        'SplitFiles.OriginalFileName',
                        'SplitFiles.FileExtension',
                        'SplitFiles.DatasetKey',
                        'SplitFiles.Id',
                        'SplitFiles.DatasetTitle',
                        'SplitFiles.ResultsCount'
                    ],
                    source: 'queue',
                    fileKey: 'Id',
                    dlg: dlgSettings
                }
            },
            {
                path: '',
                component: QueueActionsComponent,
                outlet: 'actions'
            }
        ]
    },
    {
        path: ':subDatasetId/records', component: ResultsComponent,
        data: {
            appId: environment.auth.productKey,
            tabId: 'records',
            id: 'default',
            showBackButton: true
        },
        children: [
            {
                path: ':fileId',
                component: DocumentViewDetailsComponent,
                resolve: { dlgRef: DialogResolverService },
                data: {
                    appId: environment.auth.productKey,
                    tabId: 'datasetId',
                    columnList: ['File.file_id', 'File.result_index'],
                    source: 'records',
                    fileKey: 'File.file_id',
                    dlg: resultsDlgSettings
                }
            },
            {
                path: '',
                outlet: 'actions',
                component: ResultsActionsComponent
            }
        ]
    },
    { path: '**', redirectTo: 'datasets' }
]


const routes: Routes = [...redirectRoutes];

@NgModule({
    declarations: [],
    imports: [RouterModule.forChild(routes)]
})
export class ReportsRoutingModule {
}
