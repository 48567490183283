<div class="main-fields w-full ">

    <div *ngIf="!isDataLoaded">
        <mat-spinner [diameter]="24"></mat-spinner>
    </div>

    <div class="titles flex">
        <div *ngIf="canAddField" class="column-0 drag"></div>
        <div class="column-1 title">Type</div>
        <div class="column-2 title">Field Name</div>
        <div class="column-3 title">Description</div>
    </div>

    <div class="main-schema-form w-full" *ngIf="isDataLoaded" cdkDropList (cdkDropListDropped)="drop($event)"
        [cdkDropListDisabled]="!canAddField">
        <div *ngFor="let field of getMainFieldsControls(); let i = index">

            <div *ngIf="isEditMode && i !== getMainFieldsControls().length - 1" class="add-field-line-wrapper"
                (click)="addFieldAtIndex(originalFields, i)">
                <div class="add-field-line">
                    <hr>
                    <mat-icon>add_circle_outline</mat-icon>
                    <hr>
                </div>
            </div>

            <field-detail *ngIf="field" [formGroup]="field" [index]="i" [isEditMode]="isEditMode"
                [canDelete]="canDelete" [useWhiteBackground]="useWhiteBackground"
                [additionalItem]="isEditMode && i == (originalFields.length - 1)" [canDrag]="canAddField"
                [isEditModeFormGroup]="objectFormGroup" (inputChange)="handleItemChange(originalFields, i, $event)"
                (delete)="deleteField(originalFields, i)">
            </field-detail>

            <div class="nested-table"
                *ngIf="field.controls.fields && !(isEditMode && canAddField && i == (originalFields.length - 1))"
                cdkDropList [id]="'nestedList-' + i" [cdkDropListData]="getNestedFieldsControls(field)"
                [cdkDropListConnectedTo]="getConnectedLists()" (cdkDropListDropped)="dropNested($event,field)">

                <div class="titles-sub flex">
                    <div class="column-0 drag"></div>
                    <div class="column-1 title">Type</div>
                    <div class="column-2 title">Field Name</div>
                    <div class="column-3 title">Description</div>
                </div>

                <div *ngFor="let subField of getNestedFieldsControls(field); let nestedIndex = index">

                    <div *ngIf="isEditMode  && nestedIndex !== getNestedFieldsControls(field).length - 1 "
                        class="add-field-line-wrapper"
                        (click)="addFieldAtIndex(field.controls.fields, nestedIndex, i + '-' + nestedIndex)">
                        <div class="add-field-line">
                            <hr>
                            <mat-icon>add_circle_outline</mat-icon>
                            <hr>
                        </div>
                    </div>
                    <field-detail [formGroup]="subField" [index]="nestedIndex" [parentIndex]="i" [isNested]="true"
                        [isEditMode]="isNestEdit" [useWhiteBackground]="useWhiteBackground"
                        [additionalItem]="isEditMode && nestedIndex == (getNestedFieldsControls(field).length - 1)"
                        [objectFormGroup]="objectFormGroup"
                        (inputChange)="addItem(field.controls.fields, nestedIndex, i + '-' + nestedIndex, $event)"
                        (delete)="deleteField(field.controls.fields, nestedIndex)"></field-detail>
                </div>

            </div>
        </div>
    </div>
</div>
