<div class="wrapper">
    <div class="header flex justify-between">
        <div class="left-wrapper">
            <div class="close-icon">
                <button mat-icon-button (click)="dialogRef.close()">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
            <div class="integration-info">{{(isEditMode ? 'Edit ' : 'Create ') + getIntegrationName()}}</div>
            <div class="action">
                <button mat-flat-button color="basic" (click)="save()">Save</button>
            </div>
        </div>
    </div>
    <div class="main">
        <div class="overlay" *ngIf="isLoading">
            <mat-spinner></mat-spinner>
        </div>

        <div *ngIf="data.typeId == IntegrationType.Zapier">
            <div class="copyable-object-container">
                <label class="copyable-object-label">
                    API Token
                </label>
                <div class="copyable-object">
                    <div class="copyable-object-display">
                        <span>{{ model.apiToken }}</span>
                    </div>
                    <div class="copyable-object-icon-container">
                        <span *ngIf="isLoadingToken">
                            <mat-spinner diameter="25"></mat-spinner>
                        </span>
                        <span *ngIf="!isLoadingToken">
                            <mat-icon class="copy-icon" *ngIf="!isCopied['apiToken']"
                                (click)="copyToClipboard(model.apiToken, 'apiToken', $event)" matTooltip="Copy">
                                content_copy
                            </mat-icon>
                            <mat-icon class="copy-icon" *ngIf="isCopied['apiToken']">
                                check
                            </mat-icon>
                        </span>
                    </div>
                </div>
            </div>
            <hr>

            <div class="zapier-container">
                <h1>Zapier Settings</h1>
                <zapier-workflow [signUpEmail]='"email_of_your_user@example.com"'
                    [signUpFirstName]='"first_name_of_your_user"' [signUpLastName]='"last_name_of_your_user"'
                    [clientId]='"0zvbWWVPzLgGPNOTHP4Vc5cE9bUbgF7tj0XG9Pd0"' [theme]='"light"'
                    [introCopyDisplay]='"hide"' [manageZapsDisplay]='"hide"' [guessZapDisplay]='"hide"'
                    [appSearchBarDisplay]='"show"' [templateCtaDisplay]='"show"'
                    [zapCreateFromScratchDisplay]='"hide"'></zapier-workflow>
            </div>
        </div>

        <app-dyn-form (result)="onSubmit($event)" #dynForm class="form" [key]="getIntegrationDynFormKey(data.typeId)"
            [model]="data" [isEditMode]="true">
        </app-dyn-form>
    </div>
</div>