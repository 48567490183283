export type DatasetType = 'DATASET' | 'PIPELINE_CHOOSER';
export enum DatasetsTypes {
    DATASET = 'DATASET',
    PIPELINE_CHOOSER = 'PIPELINE_CHOOSER'
}
export class BaseDatasetDetailsDto {
    Id: string;
    Title: string;
    DatasetIcon: string;
    EmailAlias: string;
    Type: DatasetType;
    Description: string;
    ProcessedDocs: number;
    ProcessingDocs: number;
    ReceivedDocs: number;
}
export class DatasetDetailsDto extends BaseDatasetDetailsDto {
    IsCopied?: boolean;
}

export class DatasetCountDto {
    datasetId?: string;
    queueLength: number;
    resultLength: number;
    updatedAt?: any;
}