<div class="search-header-container header">
    <div class="line1">
        <div *ngIf="currentQuery" class="filter-groups" layoutDir="row">
            <!--Search input-->
            <div class="groups-wrapper" *ngIf="!hideFilters">
                <search-input-widget class="search-bar color-accent" label="Search" [searchValue]="oSearchValue | async"
                    (searchEvent)="searchTextUpdate($event)">
                </search-input-widget>
            </div>

            <!--Filter icon-->
            <div class="groups-wrapper" *ngIf="!hideFilters">
                <button ml-2 matTooltip="Filter" mat-icon-button color="secondary" [matMenuTriggerFor]="filterList"
                    (click)="$event.preventDefault(); filterInput.focus()">
                    <mat-icon>filter_list
                    </mat-icon>
                </button>
                <mat-menu class="filterList" yPosition="below" #filterList="matMenu">
                    <div (click)="$event.stopPropagation();">
                        <mat-form-field class="filter-input add-filters">
                            <input (keyup)="$event.preventDefault()" #filterInput type="text" placeholder="Filter By"
                                autocomplete="off" matInput [formControl]="filtersListControl">
                        </mat-form-field>
                    </div>
                    <div class="cardFilterList">
                        <div style="max-height: 300px; overflow-y: scroll;">
                            <button (focus)="filterInput.focus()" mat-menu-item
                                *ngFor="let option of filteredcolumns | async"
                                (click)="addEmptyFilter(option.display);">
                                {{option?.display}}
                            </button>
                        </div>
                        <div style="background-color: #f9f9f9;">
                            <button (click)="showAdvancedModal()" class="m-2" mat-raised-button color="primary">
                                Advanced
                            </button>
                            <button (click)="openFiltersClick.emit(true)" class="m-2" mat-raised-button color="primary"
                                style="opacity: 0.9;">
                                Show All
                            </button>
                        </div>
                    </div>
                </mat-menu>
                <!-- <mat-form-field class="filter-input add-filters">


                    <mat-icon matSuffix>filter_list</mat-icon>
                    <mat-autocomplete (optionSelected)="addEmptyFilter($event.option.value);" #auto="matAutocomplete">
                        <mat-option matTooltip="{{option?.display}}" *ngFor="let option of filteredcolumns | async"
                            [value]="option?.display">
                            {{option?.display}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <div style="width: 100%;position: absolute; top: 200px">hello</div> -->
            </div>
            <!--Filter list-->
            <div class="groups-wrapper" style="flex-grow: 1;overflow: auto" *ngIf="!hideFilters && currentQuery">
                <router-outlet name="topfilters"></router-outlet>
                <ng-container *ngFor="let filter of currentQuery?.filters; trackBy: trackByFields">
                    <div class="filter-group" *ngIf="!!filter.facetValues && filter.fields[0] !== 'Advanced'">
                        <div class="top-label" *ngIf="!!columnDictionary ">

                            <div class="filter-title">

                                <ng-template let-facet="facet" let-facetTitle="facetTitle" #FacetTemplate
                                    [ngTemplateOutlet]="FacetTemplate" [ngTemplateOutletContext]="{
                                        facet: columnDictionary[filter.fields[0]],
                                        facetTitle: columnDictionary[filter.fields[0]]?.display + (isFilterExcluded(filter) ? '(Excluded)': '')
                                    }">
                                    <list-form #MENU [title]="facetTitle" [initializeOpened]="filter.openFacet"
                                        (menuOpened)="filter.openFacet = $event; removeEmptyfacetFilters($event)"
                                        class="list">
                                        <div
                                        *ngIf="MENU.open"
                                         class="flex row">
                                            <div class="facet-container">
                                                <div class="facet-item">
                                                    <facet-container [isCollapsible]="false" [facet]="facet"
                                                        [currentTab]="currentTab"
                                                        [filterMode]="filter.filterMode" [queryService]="queryService"
                                                        [isFilterExcluded]="isFilterExcluded(filter)">
                                                    </facet-container>
                                                </div>
                                            </div>
                                        </div>
                                    </list-form>
                                </ng-template>

                            </div>
                            <div class="filter-items">
                                <div class="filter-item justify-content-between align-center"
                                    *ngFor="let facetValue of filter.facetValues; index as i" layoutDir="row"
                                    (click)="uncheck(filter.fields, facetValue);">
                                    {{(facetValue.title) ? facetValue.title?.replace('|', '\t'): '(Blank)'}}
                                    <mat-icon class="close-icon">close</mat-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngFor="let filter of displayedAdvFilters;">
                    <div class="filter-group">
                        <div class="top-label">
                            Advanced Filter
                            <div class="items">
                                <search-header-advanced-filter-container [filter]="filter"
                                    [advFilterService]="advFilterService" [columnDictionary]="columnDictionary">
                                </search-header-advanced-filter-container>
                                <div class="filter-item">
                                    <mat-icon class="close-icon edit-position" (click)="showAdvancedModal(0)">edit
                                    </mat-icon>
                                    <mat-icon class="close-icon" (click)="removeAdvancedFilter(0)">close</mat-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
             <!--Results-->
            <div *ngIf="currentQuery" class="groups-wrapper" style="justify-self: flex-end; align-self: center;">

                <!-- <div *ngIf="!hideFilters">
                    <div *ngIf="!hideFilters" class="button-list">
                        <a (click)="openFiltersClick.emit(true)" class="button" mat-mini-fab color="primary">
                            <mat-icon>filter_list</mat-icon>
                        </a>
                        <div class="hidden-button">
                            <a (click)="showAdvancedModal()" class="button" mat-flat-button color="primary">Advanced
                                Filter</a>
                        </div>

                    </div>
                </div> -->
                <div layout="row">
                    <!-- <span *ngIf="(queryService.oQuery | async) as currentQuery" class="filter-groups">
                        <span class="grouping-warning"
                            *ngIf="currentQuery.groups?.length && tableHeaderConfig?.resultLength > 2000">
                            Only 2000 rows are currently being viewed .
                        </span>
                    </span> -->
                    <div class="line2" *ngIf="selectedView === 'table-view'">
                        <span
                            style="font-size: 13px;font-weight: 400;color: var(--text-color);margin-right: 8px;width: max-content;"
                            *ngIf="tableHeaderConfig?.resultLength > 0 ">1 - {{ fetchedRecordsLength }} of
                            {{tableHeaderConfig?.resultLength | number}}
                            {{tabTitle}}</span>

                        <b style="font-size:14px;width: max-content;" *ngIf="tableHeaderConfig?.resultLength === 0">
                            No Records Found
                        </b>
                        <mat-icon *ngIf="canChangeColumnSettings" class="columns-settings"
                            (click)="openColumnsSettingsDialog(selectedView)" color="accent">settings
                        </mat-icon>

                    </div>
                </div>
                <div class="line2" *ngIf="selectedView === 'kanban-view'">
                    <mat-icon class="columns-settings" (click)="openColumnsSettingsDialog(selectedView)" color="accent">
                        settings
                    </mat-icon>
                </div>
            </div>
        </div>
    </div>
