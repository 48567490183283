<div *ngIf="($workspaceState | async) as state">
    <mat-sidenav-container class="flex-auto h-full v-full">
        <!-- Drawer content -->
        <mat-sidenav-content class="flex flex-col">
            <!-- Main -->
            <div class="main-workspace-content flex flex-row p-1 sm:p-2 ">
                <!-- CONTENT GOES HERE -->
                <div class="main-view-container w-full">
                    <div class="workspace-container">
                        <div class="upper-row">
                            <report-actions *ngIf="!hideActions && !currentTab.hideReportControlFeatures" #ACTIONS
                                [queryService]="state.mainQueryService"
                                [tableHeaderConfig]="tableHeaderService?.tableHeader | async"></report-actions>
                        </div>
                        <div>
                            <timed-refresh [queryService]="state.mainQueryService"></timed-refresh>
                        </div>
                        <div class="second-row">
                            <div class="cont">
                                <div class="relative flex flex-col">
                                    <main-title class="title"></main-title>
                                    <div [@enterAnimation]
                                        style="position: absolute;top: 50px;padding: 2px 8px; border-radius: 8px;width: max-content;"
                                        *ngIf="(_workspaceNotificationService.oNotification | async) as note"
                                        [style.background]="note.backgroundColor" [style.color]="note.color">
                                        {{note?.msg}}
                                    </div>
                                </div>
                                <div class="flex items-center justify-center mt-1 mr-10">
                                    <mat-icon class="small-icon" *ngIf="!currentTab.hideReportControlFeatures"
                                        [popoverTrigger]="popover" [closeOnClickOutside]="true"
                                        matTooltip="Open Report">keyboard_arrow_down</mat-icon>

                                    <button mat-mini-fab class="ml-6" color="primary">
                                        <mat-icon [ngClass]="{'spining': spin}" (click)="refresh()">refresh</mat-icon>
                                    </button>
                                </div>
                                <ng-template #popover>
                                    <reports [currentAppKey]="currentTab.app.key" [hidden]="false"
                                        (personalDirectoryId)="directoryId = $event"
                                        (hideReportsEvent)="closePopover()">
                                    </reports>
                                </ng-template>

                                <!-- <stat-action class="stat"></stat-action> -->
                                <div class="actions gap-2" *ngIf="!hideActions">
                                    <router-outlet name="actions"></router-outlet>
                                    <ng-container [ngTemplateOutlet]="customTemplate"></ng-container>
                                </div>

                                <views-type-control
                                    *ngIf="canChangeReportType && !currentTab.hideReportControlFeatures"></views-type-control>

                                <div *ngIf="hideActions && !reportPersist?.mainViewState?.getState()?.isVerticalFacets"
                                    class="applied-pinned-filters">
                                    <applied-filters-list #FILTERS
                                        [queryService]="state.mainQueryService"></applied-filters-list>
                                </div>
                                <div *ngIf="hideActions" class="actions">
                                    <button mat-button class="filter-button"
                                        (click)="drawerOpened = true; matDrawer.open();">
                                        <mat-icon class="icon">filter_list</mat-icon>
                                        <span class="text">FILTERS</span>
                                    </button>
                                    <column-settings-control class="control"
                                        *ngIf="!currentTab.hideReportControlFeatures"
                                        [queryService]="state.mainQueryService"></column-settings-control>
                                </div>
                            </div>
                        </div>
                        <reset-cache *ngIf="!hideActions"></reset-cache>

                        <div class="third-row" *ngIf="!hideActions">
                            <div class="left">
                                <disco-search [queryService]="state.mainQueryService"></disco-search>
                                <button mat-button class="filter-button"
                                    *ngIf="!reportPersist?.mainViewState?.getState()?.isVerticalFacets"
                                    (click)="drawerOpened = true; matDrawer.open();">
                                    <mat-icon class="icon">filter_list</mat-icon>
                                    <span style="font-size: 0.8rem; margin-left:3px">FILTERS</span>
                                </button>
                                <!-- <filter-list-control *ngIf="canEditFilters"
                                    (openFiltersClick)=""
                                    [queryService]="state.mainQueryService"></filter-list-control> -->
                                <div class="applied-pinned-filters"
                                    *ngIf="!reportPersist?.mainViewState?.getState()?.isVerticalFacets">
                                    <applied-filters-list #FILTERS
                                        [queryService]="state.mainQueryService"></applied-filters-list>
                                </div>
                            </div>
                            <div class="right">
                                <main-view-control [tableHeaderService]="tableHeaderService"
                                    [queryService]="state.mainQueryService"></main-view-control>
                                <column-settings-control
                                    [queryService]="state.mainQueryService"></column-settings-control>
                            </div>
                        </div>

                        <div [ngClass]="hideActions ? 'scrollable-area-action-hidden' : 'scrollable-area'"
                            layoutDir="row">
                            <div class="left-filters"
                                *ngIf="reportPersist?.mainViewState?.getState()?.isVerticalFacets">

                                <vertical-applied-filters
                                    [queryService]="state.mainQueryService"></vertical-applied-filters>
                                <button mat-button class="more-filters-button"
                                    (click)="drawerOpened = true; matDrawer.open();">
                                    <mat-icon class="icon">filter_list</mat-icon>
                                    <span class="text">MORE FILTERS</span>
                                </button>
                            </div>
                            <!-- Buttons for Table | Pivot | Chart | Kanban-->
                            <!-- The  Table | Pivot | Chart | Kanban-->
                            <div class="main-view"
                                [ngClass]="{'small-main-view': reportPersist?.mainViewState?.getState()?.isVerticalFacets}">
                                <one-chart-view [queryService]="state.mainQueryService"
                                    [chartState]="reportPersist.chartState"
                                    *ngIf="state.selectedView === AllViewTypes.Chart && !!reportPersist.chartState">
                                </one-chart-view>

                                <div class="pivot-wrapper" *ngIf="state.selectedView === AllViewTypes.Pivot">
                                    <pivot-chart-wrapper [pivotState]="reportPersist.pivotChartState"
                                        [queryService]="state.mainQueryService" *ngIf="!!reportPersist.pivotChartState">
                                    </pivot-chart-wrapper>
                                </div>

                                <kendo-table-view #Table class="kendo-table-view"
                                    *ngIf="state.selectedView === AllViewTypes.Table"
                                    (cellClickedEvent)="rowClick.emit($event)" [tableHeaderService]="tableHeaderService"
                                    (gridQueryServiceEvent)="state.mainQueryService = $event"
                                    [hideEditColumn]="hideEditColumn" [enableMultiSelect]="enableSelect">

                                </kendo-table-view>

                                <kanban-view [kanbanViewState]="reportPersist.kanbanViewState"
                                    [queryService]="state.mainQueryService"
                                    *ngIf="state.selectedView === AllViewTypes.Kanban && !!reportPersist.kanbanViewState">
                                </kanban-view>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-sidenav-content>

        <!-- Drawer -->
        <mat-sidenav [fixedTopGap]="0" [fixedBottomGap]="0" [fixedInViewport]="fixedInViewport" position="end"
            class="sidenav" class="w-92 dark:bg-gray-900" [autoFocus]="true" [mode]="drawerMode" [opened]="drawerOpened"
            #matDrawer>
            <facet-list-container *ngIf="drawerOpened && !!state.selectedView && !!currentTab"
                [queryService]="state.mainQueryService" [serviceUrl]="currentTab.serviceUrl"
                (close)="drawerOpened = false; matDrawer.close()">
            </facet-list-container>
        </mat-sidenav>
    </mat-sidenav-container>
</div>

<!--
<div #scrollwrapper [ngClass]="{'report-open':!hideReports,'report-view': (selectedView=='report-view') }"
  id="app-root" class="scroll-wrapper" [class.fix-sticky]="false" (window:resize)="adjustWorkspaceWidth()">
  <div style="height: 100%;" *ngIf="!!currentTab && !!mainQueryService">
    <div id="main-wrapper" class="main-view">
      <div class="container-fluid main-view-container">

        <div #facetlistcontainer>
          <facet-list-container [@fadeInOutO]="!!selectedView?'in':'out'" *ngIf="!!selectedView"
            [queryService]="mainQueryService" [serviceUrl]="currentTab.serviceUrl"
            (tilted)="expandRightSide = !expandRightSide; adjustWorkspaceWidth()" (resized)="adjustFacetListWidth()">
          </facet-list-container>
        </div>
        <div [style.max-width.px]="workspaceWidth" class="right-side-area">

        </div>
      </div>
    </div>
  </div> -->
