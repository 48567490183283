import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { DatasetFileService } from '../../services/api/dataset-file.service';
import { DatasetService } from '../../services/api/dataset.service';
import { SchemaService } from '../../services/schema.service';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ERROR_SNACKBAR_OPTIONS, SUCCESS_SNACKBAR_OPTIONS } from '../home/edit-title-dialog/edit-title-dialog';

export enum SchemaDocumentViewTypes {
    pdfView = 'PDF_VIEW',
    uploadView = 'UPLOAD_VIEW',
    resultView = 'RESULT_VIEW'
}

@Component({
    selector: 'app-schema-main',
    templateUrl: './schema-main.component.html',
    styleUrls: ['./schema-main.component.scss']
})
export class SchemaMainComponent implements OnInit {

    viewTypes = SchemaDocumentViewTypes;
    public activeComponent: string = SchemaDocumentViewTypes.pdfView;
    public hideFileViewer = false
    public isLoading: boolean = true;
    public datasetId: string;
    public fileId: string
    public dragging = false;
    public fileName: string;
    public fileExtension: string;

    schema: any
    constructor(
        private _datasetService: DatasetService,
        private _datasetFileService: DatasetFileService,
        private _schemaService: SchemaService,
        private _snackbar: MatSnackBar
    ) { }

    async ngOnInit(): Promise<void> {
        this.datasetId = (await this._datasetService.getCurrentBaseDatasetDetails()).Id;
        await this.setTestFileId();
        this.activeComponent = this.fileId ? this.viewTypes.pdfView : this.viewTypes.uploadView;
        await this._getFields();
        // await this._getDetails();
        this.isLoading = false
    }

    async setTestFileId() {
        const testFile = !!this.datasetId ? (await this._datasetFileService.getDatasetTestFile(this.datasetId).toPromise()).result : null;
        this.fileId = testFile?.Id || null;
        this.fileName = testFile?.OriginalFileName || null;
        this.fileExtension = testFile?.FileExtension || null;
    }

    async handleFileUploaded() {
        await this.setTestFileId();
        // await this._getDetails();
        this.activeComponent = this.viewTypes.pdfView;
    }

    async getTestFileId() {
        await this.setTestFileId();
        return this.fileId;
    }

    async handleSchemaUpdate(isSchemaUpdated: boolean) {
        if (isSchemaUpdated) {
            await this.setTestFileId();
            await this._reprocessTestFiles();
            if (!!this.datasetId && this.fileId) this.activeComponent = this.viewTypes.pdfView
        }
    }

    private async _reprocessTestFiles() {
        if (!!this.datasetId && !!this.fileId) {
            const { isSuccess } = await this._datasetFileService.reprocessById(this.datasetId, this.fileId).toPromise();
            const msg = isSuccess ? 'Test file reprocessed successfully' : 'Error reprocessing Test file';
            const config = isSuccess ? SUCCESS_SNACKBAR_OPTIONS : ERROR_SNACKBAR_OPTIONS;
            this._showMsg(msg, config);
        }
    }

    private _showMsg(msg: string, config: MatSnackBarConfig) {
        this._snackbar.open(msg, 'close', config);

    }

    private async _getFields() {
        const { isSuccess, result } = await this._datasetService.getFields(this.datasetId).toPromise();
        if (isSuccess && !!result) {
            this.schema = result;
            this._schemaService.updateSchema(result);
            // this._schemaService.setFields()
        }
        this.isLoading = false;
    }

    // private async _getDetails() {
    //     try {
    //         if (!!this.datasetId && !!this.fileId) {
    //             const { isSuccess, result: detailsResponse } = await this._datasetFileService.getFileDetails(this.datasetId, this.fileId).toPromise();
    //             if (isSuccess && detailsResponse) {
    //                 this.fileName = detailsResponse.OriginalFileName;
    //                 this.fileExtension = detailsResponse.FileExtension;
    //             }
    //         }
    //     } catch (error) {
    //         console.error('Error fetching file details:', error);
    //     }
    // }
}
