import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { Field } from '../../helpers/docpipeline-function';
import { BackendResponse } from '../../models/common';
import { BaseDatasetDetailsDto, DatasetCountDto, DatasetDetailsDto, DatasetType, DatasetsTypes } from '../../models/dataset.model';
import { FileInfoDto } from '../../models/datasetFile.models';
import { CreateDatasetDto } from '../../models/dtos/create-dataset-dto';
import { BackendRouteEndpoint } from './constants/backend-route-constants';


@Injectable({
    providedIn: 'root'
})
export class DatasetService {

    _datasetDetails$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    oDatasetDetails: Observable<any> = this._datasetDetails$.asObservable();

    _chooserDatasetId$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    oChooserDatasetId: Observable<string> = this._chooserDatasetId$.asObservable();

    constructor(
        private httpClient: HttpClient
    ) { }

    // TODO: Add Types instead of any

    getAll(): Observable<BackendResponse<DatasetDetailsDto[]>> {
        return this.httpClient.get<BackendResponse<DatasetDetailsDto[]>>(`${BackendRouteEndpoint.DATASET_BASE_URL}`)
    }
    async getById(datasetId: string): Promise<BackendResponse<any>> {
        const datasetDetails = await this.oDatasetDetails.pipe(take(1)).toPromise() as BaseDatasetDetailsDto
        if (datasetDetails?.Id  === datasetId) return { isSuccess: true, result: datasetDetails };
        const { isSuccess, result } = await this.httpClient.get<BackendResponse<any>>(`${BackendRouteEndpoint.DATASET_BASE_URL}/${datasetId}`).toPromise();
        if (isSuccess && result != null) {
            this._datasetDetails$.next(result);
        }
        return { isSuccess, result };
    }
    updateDatasetDetails(datasetDetails: BaseDatasetDetailsDto) {
        this._datasetDetails$.next(datasetDetails);
    }
    updateChooserDatasetId(chooserDatasetId: string) {
        this._chooserDatasetId$.next(chooserDatasetId);
    }
    async getCurrentBaseDatasetDetails() {
        return await this.oDatasetDetails.pipe(take(1)).toPromise();
    }
    getCounts(datasetId: string): Observable<BackendResponse<DatasetCountDto[]>> {
        return this.httpClient.get<BackendResponse<DatasetCountDto[]>>(`${BackendRouteEndpoint.DATASET_BASE_URL}/${datasetId}/counts`);
    }
    getFields(datasetId: string): Observable<BackendResponse<{ [key: string]: Field }>> {
        return this.httpClient.get<BackendResponse<{ [key: string]: Field }>>(`${BackendRouteEndpoint.DATASET_BASE_URL}/${datasetId}/fields`)
    }
    getPiplines(datasetId: string): Observable<BackendResponse<{ id: string, description: string }[]>> {
        return this.httpClient.get<BackendResponse<{ id: string, description: string }[]>>(`${BackendRouteEndpoint.DATASET_BASE_URL}/${datasetId}/pipelines`)
    }
    getTemplateFields(templateName: string) {
        return this.httpClient.get<BackendResponse<any>>(`${BackendRouteEndpoint.DATASET_BASE_URL}/fields/by-template-name/${templateName}`);
    }
    getGeneralSettings(datasetId: string) {
        return this.httpClient.get<BackendResponse<any>>(`${BackendRouteEndpoint.DATASET_BASE_URL}/${datasetId}/general-settings`);
    }
    getAllTemplatesFields() {
        return this.httpClient.get<BackendResponse<{ [key: string]: { [key: string]: Field } }>>(`${BackendRouteEndpoint.DATASET_BASE_URL}/templates`);
    }

    create(dataset: CreateDatasetDto, type: DatasetType = DatasetsTypes.DATASET): Observable<BackendResponse<any>> {
        return this.httpClient.post<BackendResponse<any>>(`${BackendRouteEndpoint.DATASET_BASE_URL}?type=${type}`, dataset);
    }
    createFieldsByFile(datasetId: string, obj: FormData): Observable<BackendResponse<FileInfoDto>> {
        return this.httpClient.post<BackendResponse<FileInfoDto>>(`${BackendRouteEndpoint.DATASET_BASE_URL}/${datasetId}/fields`, obj)
    }
    createOrUpdateAssets(datasetId: string, assetsFiles) {
        return this.httpClient.post<BackendResponse<any>>(`${BackendRouteEndpoint.DATASET_BASE_URL}/${datasetId}/assets`, assetsFiles)
    }

    updateFields(datasetId: string, fields, newFieldsAdded: boolean): Observable<BackendResponse<any>> {
        return this.httpClient.put<BackendResponse<any>>(`${BackendRouteEndpoint.DATASET_BASE_URL}/${datasetId}/fields?newFieldsAdded=${newFieldsAdded}`, fields)
    }
    updateFieldsByTemplate(datasetId: string, templateName: string): Observable<BackendResponse<any>> {
        return this.httpClient.put<BackendResponse<any>>(`${BackendRouteEndpoint.DATASET_BASE_URL}/${datasetId}/fields/by-template-name/${templateName}`, null)
    }
    updateTemplateName(datasetId: string, name: string, description: string): Observable<BackendResponse<any>> {
        return this.httpClient.put<BackendResponse<any>>(`${BackendRouteEndpoint.DATASET_BASE_URL}/${datasetId}/info`, { name, description })
    }
    updateDatasetGeneralSettings(datasetId: string, generalSettings: any): Observable<BackendResponse<any>> {
        return this.httpClient.put<BackendResponse<any>>(`${BackendRouteEndpoint.DATASET_BASE_URL}/${datasetId}/general-settings`, generalSettings)
    }

    delete(datasetId: string): Observable<BackendResponse<any>> {
        return this.httpClient.delete<BackendResponse<any>>(`${BackendRouteEndpoint.DATASET_BASE_URL}/${datasetId}`);
    }
}
