import { Clipboard } from '@angular/cdk/clipboard';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, UserData, UserDataService } from '@discoverer/app-core';
import { BaseController, DiscovererQueryService } from '@discoverer/core';
import { environment } from '@env/environment';
import { interval } from 'rxjs';
import { take } from 'rxjs/operators';
import { DatasetCountDto, DatasetTypes } from '../../../models/dataset.model';
import { DatasetService } from '../../../services/api/dataset.service';
import { EmitterService } from '../../../services/emitter.service';
import { GeneralSettingsService } from '../../../services/general-settings.service';
import { SchemaService } from '../../../services/schema.service';
import { IToolbarSettings, ToolbarService } from '../../../services/toolbar.service';
import { MultiFileUploaderComponent } from '../../attoms/multi-file-uploader/multi-file-uploader.component';
import { NewDatasetComponent } from '../../dataset-list/new-dataset/new-dataset.component';
import { EditTitleDialogComponent } from '../../home/edit-title-dialog/edit-title-dialog';
import { ISidebarItem } from './models';
import { DATASET_SIDEBAR_ITEMS, WORKSPACE_SIDEBAR_ITEMS, VerticalSidebarService } from './services/vertical-sidebar.service';



@Component({
    selector: 'app-vertical-sidebar',
    templateUrl: './vertical-sidebar.component.html',
    styleUrls: ['./vertical-sidebar.component.scss']
})
export class VerticalSidebarComponent extends BaseController implements OnInit {

    isUpdating = false;
    queueLength!: number;
    resultsLength!: number;
    productKey = environment.auth.productKey;
    sidebarItems: ISidebarItem[] = [];
    user!: UserData;
    toolbarSettings: IToolbarSettings = { showBackButton: false, title: 'Dataset' };
    isEmailCopied = false;
    isSubTitleCopied = false;
    datasetTypes = DatasetTypes
    constructor(
        public queryService: DiscovererQueryService,
        public emitterService: EmitterService,
        private sidebarService: VerticalSidebarService,
        private toolbarService: ToolbarService,
        private userService: UserDataService,
        private _authService: AuthService,
        public datasetService: DatasetService,
        private router: Router,
        private dialog: MatDialog,
        private clipboard: Clipboard,
        private activatedRoute: ActivatedRoute,
        private schemaService: SchemaService,
        private _generalSettingsService: GeneralSettingsService
    ) {
        super();
    }

    async ngOnInit(): Promise<void> {
        this.initializeSubscriptions();

        this.updateDatasetCounts();
        this.subscriptions.push(interval(4000).subscribe(async () => await this.updateDatasetCounts()));

        this.subscriptions.push(this.emitterService.oDatasetId
            .subscribe(async (value: string) => {
                this._generalSettingsService.setDatasetId(value);
            }));
    }

    private async initializeSubscriptions() {
        await this.datasetService.getAll()
        this.subscriptions.push(
            this.userService.loggedInUser.subscribe(user => this.user = user),
            this.toolbarService.oToolbarSettings.subscribe(settings => this.toolbarSettings = settings),
            this.datasetService.oDatasetDetails.subscribe(async details => {
                if (details?.Type === DatasetTypes.WORKSPACE) {
                    await this.datasetService.getWorkspaceById(details?.Id);
                    this.sidebarItems = WORKSPACE_SIDEBAR_ITEMS;
                    const datasets = (await this.datasetService.oCurrentDatasetList.pipe(take(1)).toPromise()).filter(d => d.Type === DatasetTypes.DATASET);
                    this.sidebarItems[1].children = datasets.map(d => ({
                        id: d.Id,
                        title: d.Title,
                        icon: 'feather:database',
                        route: `${d.Id}/records`,
                        rightBadge: d.ProcessedDocs,
                        leftBadge: d.ProcessingDocs,
                        isLoading: false,
                        showDetails: true
                    })
                    );
                    //this.sidebarItems[2] = { ...this.sidebarItems[2], action: () => this.openCreateDialog(DatasetsTypes.DATASET) };
                } else {
                    this.sidebarItems = DATASET_SIDEBAR_ITEMS;
                    await this.datasetService.updateWorkspaceDetails(details.WorkspaceId);
                }
            })
        );
    }
    navigateToWorkspace(workspace): void {
        this.datasetService.updateDatasetDetails(workspace);
        this.router.navigate([`/datasets/${workspace?.Id}/queue`]);
    }
    async openCreateDialog(createType = null) {
        const type = !createType ? (await this.datasetService.getCurrentBaseDatasetDetails())?.Type : createType;
        const openNewDatasetDialog = await this.dialog.open(NewDatasetComponent, { width: '560px', data: { type } }).afterClosed().toPromise();
        if (openNewDatasetDialog) {
            await this.datasetService.getAll();
        }
    }
    public signOut() {
        const redirectUrl = this.router.url;
        this._authService.goToLogin(redirectUrl);
    }
    public goTo(id: string, path: 'actions' | 'schema' | 'settings' | 'queue' | 'records') {

        this.router.navigate([`datasets/${id}/${path}`]);
    }
    private async updateDatasetCounts(): Promise<void> {
        const { Description: description, Id: datasetId } = await this.datasetService.getCurrentBaseDatasetDetails();
        if (!datasetId) {
            console.warn("Dataset Id is not configured!");
            return;
        }
        const result = await this.sidebarService.getDatasetCounts(datasetId);
        const type = (await this.datasetService.getCurrentBaseDatasetDetails())?.Type;
        if (type === DatasetTypes.DATASET) {
            this.updateBadgeForDataset(result[0]);
        } else {
            await this.updateBadgesForPipeline(result);
        }
    }

    private updateBadgeForDataset(result: DatasetCountDto): void {
        const pipelineItem = this.sidebarItems.find(i => i.id === 'pipeline');
        pipelineItem?.children.forEach(child => {
            if (child.id === 'queue') child.rightBadge = (result.queueLength);
            if (child.id === 'records') child.rightBadge = (result.resultLength);
        });
    }

    private async updateBadgesForPipeline(result: DatasetCountDto[]) {
        const pipelineItem = this.sidebarItems.find(i => i.id === 'pipeline');
        const recordsItem = this.sidebarItems.find(i => i.id === 'datasets');
        const workspaceId = await this.datasetService.getCurrentBaseDatasetDetails();

        pipelineItem?.children.filter(({ id }) => id === 'queue').forEach(child => {
            const res = result.find(r => r.datasetId === workspaceId.Id);
            if (!res) return
            child.leftBadge = (res?.queueLength);
            child.rightBadge = (res?.resultLength);
        });

        recordsItem?.children.forEach(child => {
            const res = result.find(r => r.datasetId === child.id);
            if (!res) return;
            child.leftBadge = (res?.queueLength);
            child.rightBadge = (res.resultLength);
        });
    }

    public async editDataSetTitleDialog(): Promise<void> {
        const { Description: description, Id: datasetId, Type: type } = await this.datasetService.getCurrentBaseDatasetDetails();
        const templateName = this.toolbarSettings?.title;
        const dlg = this.dialog.open(EditTitleDialogComponent, {
            width: '650px', data: { datasetId, templateName, description, type }, autoFocus: true
        });

        dlg.afterClosed().subscribe(async resp => {
            if (resp?.templateName) {
                const baseDatasetDetails = await this.datasetService.getCurrentBaseDatasetDetails();
                this.datasetService.updateDatasetDetails({ ...baseDatasetDetails, Title: resp.templateName, Description: resp.description });
                this.toolbarSettings.title = resp.templateName;
            }
        });
    }

    public async openImportDocument(): Promise<void> {
        const { Type: datasetType, Id: datasetId } = (await this.datasetService.getCurrentBaseDatasetDetails());
        this.dialog.open(MultiFileUploaderComponent, {
            width: '800px',
            data: { datasetId, datasetType },
            disableClose: true
        }).afterClosed().subscribe(() => this.queryService.refresh());
    }

    public async back() {
        const datasetDetails = await this.datasetService.getCurrentBaseDatasetDetails();
        this.router.navigate(['datasets', datasetDetails.WorkspaceId, 'queue']);
    }

    public copyEmail(email: string, type: string): void {
        this.clipboard.copy(email);
        if (type === 'email') {
            this.isEmailCopied = true;
        } else if (type === 'subTitle') {
            this.isSubTitleCopied = true;
        }

        setTimeout(() => {
            if (type === 'email') {
                this.isEmailCopied = false;
            } else if (type === 'subTitle') {
                this.isSubTitleCopied = false;
            }
        }, 4000);
    }
}
