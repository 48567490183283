import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class MimeTypesService {

    constructor() { }

    private mimeTypes: { [key: string]: string } = {
        '.txt': 'text/plain',
        '.text': 'text/plain',
        '.pdf': 'application/pdf',
        '.png': 'image/png',
        '.jpg': 'image/jpeg',
        '.jpeg': 'image/jpeg',
        '.docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        '.doc': 'application/msword',
        '.xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        '.xls': 'application/vnd.ms-excel',
        '.csv': 'text/csv',
        '.zip': 'application/zip',
        '.eml': 'message/rfc822'
    };

    getExtensionFromMimeType(mimeType: string): string | null {
        for (const extension in this.mimeTypes) {
            if (this.mimeTypes[extension] === mimeType) {
                return extension;
            }
        }
        return null;
    }

    getMimeTypeFromExtension(extension: string): string | null {
        return this.mimeTypes[extension] || null;
    }
}
