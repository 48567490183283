<div class="wrapper">
    <!-- Header -->
    <div class="page-header-wrapper flex flex-col gap-2">
        <div class="page-header flex">
            <p>Connections</p>
        </div>
        <div class="page-sub-header flex  text-secondary "> Easily send parsed data in real-time to your
            favorite third-party services or apps. With just a few clicks,
            you can create Google Sheets, notify your team on Slack, store data in Airtable, or set up any other
            custom
            integration.
        </div>
    </div>

    <div class="main-contant flex flex-col gap-5">
        <div class="main flex flex-col gap-2 p-5">
            <div class="flex flex-col text-xl font-medium leading-8">
                <div>
                    Your Connections
                </div>
                <div *ngIf="integrationList?.length == 0" class="text-base">
                    You have no integrations setup, please connect one from below
                </div>
            </div>
            <div *ngIf="integrationList?.length > 0"
                class="flex grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 gap-6 w-full min-w-0">
                <ng-container
                    *ngFor="let integration of integrationList; let i = index; let first = first; let last = last;">
                    <!-- Card -->
                    <mat-card class="flex flex-col flex-auto p-6 bg-card shadow rounded-2xl overflow-overflow">
                        <div class="flex flex-row items-center justify-between">
                            <div class="flex flex-1 flex-row items-center gap-2">
                                <img [src]="getIntegrationImage(integration.typeId)" alt="logo" class="w-15 h-15">
                                <div class="flex flex-col p-2">
                                    <div class="flex text-xl font-bold">{{getIntegrationName(integration.typeId)}}</div>
                                    <div class="flex flex-row gap-4 pt-2">
                                        <div class="flex flex-col">
                                            <div class="flex text-l font-bold uppercase">ID</div>
                                            <p class="flex text-secondary font-medium">{{integration.id}} </p>
                                        </div>
                                        <div class="flex flex-col">
                                            <div class="flex text-l font-bold uppercase">Name</div>
                                            <p class="flex text-secondary font-medium">{{integration.name}} </p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="flex flex-0 flex-row justify-between gap-2">
                                <div class="flex-1 ml-2 -mt-2 -mr-3">
                                    <button mat-icon-button [matMenuTriggerFor]="summaryMenu">
                                        <mat-icon class="icon-size-5"
                                            [svgIcon]="'heroicons_solid:dots-horizontal'"></mat-icon>
                                    </button>
                                    <mat-menu #summaryMenu="matMenu">
                                        <button (click)="openLogs(integration.id)" mat-menu-item><mat-icon class="icon-size-5 mr-3 duplicate-mat-icon"
                                                [svgIcon]="'heroicons_solid:document-text'"></mat-icon>
                                            Logs</button>
                                        <button (click)="editIntegration(integration.id)" mat-menu-item> <mat-icon
                                                class="icon-size-5 mr-3 duplicate-mat-icon"
                                                [svgIcon]="'heroicons_solid:pencil'"></mat-icon>
                                            Edit</button>
                                        <button mat-menu-item (click)="deleteIntegration(integration.id)"> <mat-icon
                                                class="icon-size-5 mr-3 " [svgIcon]="'heroicons_solid:trash'">
                                            </mat-icon>
                                            Delete</button>
                                    </mat-menu>
                                </div>
                                <div class="flex-1">
                                    <button (click)="deleteIntegration(integration.id)"> <mat-icon
                                            class="icon-size-6 text-red-700 " [svgIcon]="'heroicons_solid:trash'">
                                        </mat-icon>
                                    </button>
                                </div>
                                <!-- <div class="flex-1">
                                    <mat-slide-toggle [(ngModel)]="integration.IsActive">
                                        IsActive</mat-slide-toggle>
                                </div> -->
                            </div>

                        </div>
                    </mat-card>
                </ng-container>
            </div>
        </div>
        <div class="main flex flex-col gap-2 p-5 h-full">
            <div class="text-xl font-medium leading-8">All Connections</div>
            <div class="grid grid-cols-3 sm:grid-cols-1 md:grid-cols-3 gap-6 pt-2">
                <ng-container *ngIf="availableIntegrations?.length > 0">
                    <ng-container
                        *ngFor="let integration of availableIntegrations; let i = index; let first = first; let last = last;">
                        <!-- Card -->
                        <mat-card
                            [ngClass]="{'transform-card': !integration.disabled, 'disabled': integration.disabled}"
                            (click)="createIntegration(integration.typeId)"
                            class="flex flex-col flex-auto p-4 rounded-2xl overflow-auto justify-between gap-4">
                            <div class="flex flex-row">
                                <img [src]="getIntegrationImage(integration.typeId)" alt="logo" class="w-15 h-15">
                                <div class="flex-0 text-xl font-medium tracking-tight leading-6 truncate p-2">
                                    {{getIntegrationName(integration.typeId)}}
                                </div>
                            </div>
                            <p class="flex pt-2 font-medium">
                                {{integration.description}} </p>
                            <div class="flex flex-0 ">
                                <button mat-raised-button color="primary" [disabled]="integration.disabled"
                                    class="button-connect">
                                    <mat-icon class="icon-link">link</mat-icon>
                                    Connect
                                </button>
                            </div>
                        </mat-card>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </div>
</div>