import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { BaseController } from '@discoverer/core';
import { FormsDataServiceInterface, OptionDataServiceInterface } from '@discoverer/dynamic-forms';
import { DynFormComponent } from '@discoverer/dynamic-forms/dyn-forms/dyn-form/dyn-form.component';
import { debounceTime, take } from 'rxjs/operators';
import { HasuraOptionService } from '../../../../../../discoverer/dynamic-forms/option-lists/shared-services/hasura-option-service';
import { ActionModel, IActionToolModel, IActionToolConfigurationModel } from '../../../models/action.model';
import { Field } from '../../../helpers/docpipeline-function';
import { actionTypeImage, actionTypeName } from '../../../models/constants';
import { DatasetActionType } from '../../../models/enums';
import { ActionFormService } from '../../../services/action-form.service';
import { ActionService } from '../../../services/api/action.service';
import { DatasetService } from '../../../services/api/dataset.service';
import { IgniteTabService } from '../../../services/ignite-tab.service';
import { SchemaService } from '../../../services/schema.service';
import { ERROR_SNACKBAR_OPTIONS } from '../../home/edit-title-dialog/edit-title-dialog';
import { TestActionDialogComponent, TestActionModel } from '../test-action-dialog/test-action-dialog.component';
import { TOOLS, ToolSelectionDialogComponent } from '../tool-selection-dialog/tool-selection-dialog.component';
import { ConnectionService } from '../../connections/connection.service';
import { TestActionHandlerService } from './test-action-handler.service';
import { FieldDataType } from '@discoverer/enums';
import { DataTypeIcons } from '@discoverer/constants';
import { DatasetFileService } from '../../../services/api/dataset-file.service';
import { SubmitDynFormBloc } from '@discoverer/dynamic-forms/dyn-forms/dyn-form/dyn-form.blocs';

@Component({
    selector: 'action-details-dialog',
    templateUrl: './action-details-dialog.component.html',
    styleUrls: ['./action-details-dialog.component.scss'],
    providers: [
        SubmitDynFormBloc,
        { provide: FormsDataServiceInterface, useClass: ActionFormService, deps: [HttpClient, ActionService] },
        { provide: OptionDataServiceInterface, useClass: HasuraOptionService }
    ]
})

export class ActionDetailsDialogComponent extends BaseController implements OnInit, OnDestroy {
    @ViewChild('dynForm') dynForm: DynFormComponent;
    @ViewChild('stepper') stepper: MatStepper;
    public promptMenuItems = [];
    public DatasetActionType = DatasetActionType;
    public isLoading = false;
    public isEditMode: boolean = false;
    public actionNameTitle: string = '';
    public testActionHandlerService: TestActionHandlerService = new TestActionHandlerService();
    public formData: ActionModel = {} as ActionModel;
    public promptValue: string = '';
    public actionList: ActionModel[] = [];
    public dialogData: ActionModel;
    public isDataLoaded = false;
    public isFormDataLoaded = false;
    public originalFields: FormArray;
    public tools = TOOLS;
    public FIELD_TYPE_OPTIONS =
        [
            { value: '', display: 'Type' },
            { value: FieldDataType.STRING, display: 'Text', icon: DataTypeIcons.get(FieldDataType.STRING) },
            { value: FieldDataType.NUMBER, display: 'Number', icon: DataTypeIcons.get(FieldDataType.NUMBER) },
            { value: FieldDataType.DATE, display: 'Date', icon: DataTypeIcons.get(FieldDataType.DATE) },
            { value: FieldDataType.CURRENCY, display: 'Currency', icon: DataTypeIcons.get(FieldDataType.CURRENCY) },
            { value: FieldDataType.LINK_WEBSITE, display: 'URL', icon: DataTypeIcons.get(FieldDataType.LINK_WEBSITE) },
            { value: FieldDataType.EMAIL, display: 'Email', icon: DataTypeIcons.get(FieldDataType.EMAIL) },
            { value: FieldDataType.STRING_ARRAY, display: 'List', icon: DataTypeIcons.get(FieldDataType.STRING_ARRAY) },
            { value: FieldDataType.OBJECT_ARRAY, display: 'Table', icon: DataTypeIcons.get(FieldDataType.OBJECT_ARRAY) },
            { value: FieldDataType.OBJECT, display: 'Section', icon: DataTypeIcons.get(FieldDataType.OBJECT) }

        ];
    private _objectFormGroup: FormGroup = null;



    get objectFormGroup(): FormGroup {
        return this._objectFormGroup;
    }
    set objectFormGroup(formGroup: FormGroup) {
        this._objectFormGroup = formGroup;
        this._cdRef.detectChanges();
    }

    public availableActions = [
        {
            type: DatasetActionType.Webhook,
            description: 'Add a webhook to send the parsed data and events to your server or a 3rd-party platform and create any custom action.',
            imageUrl: 'assets/images/logo/webhook-logo.png',
            disabled: false
        },
        {
            type: DatasetActionType.AiGenerate,
            description: 'Add a Field to generate dynamic content from extracted data using custom prompts and include the output alongside the extracted results.',
            imageUrl: 'assets/images/logo/magic.png',
            disabled: false
        },
        {
            type: DatasetActionType.AiAgent,
            description: 'Use AI agents to execute tasks like integrations, web scraping, or booking, and stores the results in a designated field.',
            imageUrl: 'assets/images/logo/ai-action.png',
            disabled: false
        },

    ];

    public allConnections: any = []

    constructor(
        public dialogRef: MatDialogRef<ActionDetailsDialogComponent>,
        private connectionService: ConnectionService,
        private _fb: FormBuilder,
        private _snackBar: MatSnackBar,
        private _actionService: ActionService,
        private _igniteTabService: IgniteTabService,
        private _schemaService: SchemaService,
        private _datasetService: DatasetService,
        private _cdRef: ChangeDetectorRef,
        private _submitDynForm: SubmitDynFormBloc,
        @Inject(MAT_DIALOG_DATA) public data: { data: ActionModel, actionList: ActionModel[] },
        protected dialog: MatDialog,
        public _datasetFileService: DatasetFileService,
    ) {
        super();
        this.originalFields = this._fb.array([]);
        this.dialogData = this.data.data;
        this.actionList = this.data.actionList;
        this.actionNameTitle = !!this.dialogData?.id ? this.dialogData.name : null;
        this._actionService.updateActionNameTitle(this.actionNameTitle);
        this._pushFieldsStoreAs()
        this._actionService.updateStorAs(this.dialogData.storeAs);
    }

    getMainFieldsControls(): FormGroup[] {
        return this.originalFields.controls as FormGroup[];
    }

    public ngOnDestroy(): void {
        this.formData = {} as ActionModel;
        this._actionService.updateActionNameTitle('');
        this._actionService.updateStorAs(null);
        this.originalFields = null;
    }
    async ngOnInit() {
        await this._init(this.dialogData.type);
    }


    public editorChange(event: { html: string, text: string }) {
        this.formData.configuration.prompt = event.text;
    }

    // public dropdownSelectionChange(field, event) {
    //     this.formData[field] = event.value;
    // }

    public dropdownSelectionChange(field, event) {
        this.formData[field] = event.value;
    }

    public async runTest(): Promise<void> {
        const testFile = await this._datasetFileService.getDatasetTestFile(this.formData.datasetId).toPromise();
        if (testFile.isSuccess && !testFile?.result) {
            this._snackBar.open('Please upload a test file first', 'X', ERROR_SNACKBAR_OPTIONS);
            return;
        }
        var data: TestActionModel = {
            datasetId: this.formData.datasetId,
            configuration: this.formData.configuration,
            testingFileId: testFile?.result?.Id,
            type: this.formData.type
        }
        this.dialog.open(TestActionDialogComponent, { data, width: '50%', height: '500px' });
    }

    public onActionNameTitleChange(newName: string) {
        this.actionNameTitle = newName;
        this._actionService.updateActionNameTitle(newName);
    }

    public getActionDynFormKey(actionType: DatasetActionType): string {
        switch (actionType) {
            case DatasetActionType.Webhook:
                return 'new-webhooks'
            case DatasetActionType.AiActionField:
                return 'new-ai-action'
            case DatasetActionType.AiGenerate:
                return 'new-ai-generate'
            case DatasetActionType.AiAgent:
                return 'ai-driven-task'
        }
    }

    public getActionImage(type: DatasetActionType): string {
        return actionTypeImage.get(type)
    }

    public close(value = null) {
        this.dialogRef.close(value);
    }

    public async save() {
        this.isLoading = true;
        switch (this.dialogData.type) {
            case DatasetActionType.Webhook:
                if (!this.dynForm.form.valid) {
                    this.isLoading = false;
                    return;
                }
                this.dynForm.submit();
                break;
            case DatasetActionType.AiGenerate:
                await this._saveAiGenerate();
                break;
            case DatasetActionType.AiAgent:
                await this._saveAiAgent()
                break;
            default:
                throw `action type "${this.formData.type}" not implemented`;
        }
    }
    private async _saveAiAgent() {

        const title = this._actionService.getActionNameTitle();
        this.formData.name = title;
        if (this.formData.configuration.prompt === '' || this.formData.storeAs.display === '') {
            this._snackBar.open('Please fill all the fields', 'X', ERROR_SNACKBAR_OPTIONS);
            this.isLoading = false;
            return;
        }
        if (!!this.formData.id) {
            await this._actionService.update(this.formData).toPromise().then(result => this.onSubmit(result));
        } else {
            await this._actionService.create(this.formData).toPromise().then(result => this.onSubmit(result));
        }
    }
    private async _saveAiGenerate() {
        const actionNameTitle = this._actionService.getActionNameTitle();
        this.formData.name = actionNameTitle;
        if (this.formData.configuration.prompt === '' || this.formData.storeAs.display === '') {
            this._snackBar.open('Please fill all the fields', 'X', ERROR_SNACKBAR_OPTIONS);
            this.isLoading = false;
            return;
        }
        let { isSuccess, result } = await ((!!this.formData.id)
            ? this._actionService.update(this.formData)
            : this._actionService.create(this.formData)).toPromise()
        if (isSuccess) {
            this.isLoading = false;
            this.onSubmit(result);
        } else {
            this.isLoading = false;
            console.error('Error during saving:', result);
            this._snackBar.open('An error occurred while saving. Please try again. ', 'X', ERROR_SNACKBAR_OPTIONS);
        }
    }
    public isFormDataNotValid(): boolean {
        switch (this.dialogData.type) {
            case DatasetActionType.Webhook:
                return !this.dynForm?.form?.valid || this._isDisplayMissing();
            case DatasetActionType.AiGenerate:
                return !this.formData.configuration?.prompt || this._isDisplayMissing();
            case DatasetActionType.AiAgent:
                return !this.formData.configuration?.prompt || !this.formData.configuration?.tools?.length || this._isDisplayMissing();
            default:
                return true;
        }
    }

    public _isDisplayMissing(): boolean {
        return !Object.values(this.formData?.storeAs || {}).some(
            (item: any) => item?.display
        )
    }

    public async onSubmit(event): Promise<void> {
        try {
            this.isLoading = true;
            await this._submitDynForm.submit(this.formData);
            await this._schemaService.updateDatasetSchema(this.formData.datasetId);
            const fields = await this._schemaService.oCurrentSchema.pipe(take(1)).toPromise()
            const files = await this._igniteTabService.initTab('All Records', fields, this.formData.datasetId);
            await this._datasetService.createOrUpdateAssets(this.formData.datasetId, files).toPromise();
            this.formData.datasetId = this.formData.datasetId;
            if (event) {
                if (event.error) {
                    this._snackBar.open(event.error, 'X', ERROR_SNACKBAR_OPTIONS);
                }
                this.close(event);
            }
        } catch (error) {
            console.error('Error during submission:', error);
            this._snackBar.open('An error occurred while submitting. Please try again.', 'X', ERROR_SNACKBAR_OPTIONS);
        } finally {
            this.isLoading = false;
        }
    }

    public getActionName(type: DatasetActionType): string {
        return actionTypeName.get(type)
    }

    public getToolIcon(tool: IActionToolModel): string {
        return this.tools.find(t => t.key === tool.key)?.icon;
    }

    public async selectAction(type: DatasetActionType) {
        await this._init(type);
        setTimeout(() => {
            this.stepper.next();
        }, 500);
    }

    public changeConnection(tool, event) {
        if (!tool.configuration) {
            tool.configuration = {} as IActionToolConfigurationModel;
        }
        tool.configuration.connection = event.value;
    }

    public getConnectionsOf(app_name) {
        return this.allConnections.filter(connection => connection.app_name === app_name)
    }

    public getConnectionById(id) {
        return this.allConnections.find(connection => connection.id == id)
    }

    public removeTool(tool: any): void {
        const index = this.formData?.configuration?.tools?.indexOf(tool);
        if (index > -1) {
            this.formData?.configuration?.tools?.splice(index, 1); // Remove the tool from the array
        }
    }

    public openToolSelection(tool: IActionToolModel = null, toolIndex: number = null): void {
        const dialogRef = this.dialog.open(ToolSelectionDialogComponent, {
            width: '50vw',
            data: { connections: this.allConnections, selectedTool: tool, toolIndex: toolIndex }
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (!result) return;
            if (!result.tool?.configuration) {
                result.tool.configuration = {} as IActionToolConfigurationModel;
            }
            if (Number.isInteger(result.toolIndex)) {
                //edit
                if (this.formData.configuration.tools[result.toolIndex]) {
                    this.formData.configuration.tools[result.toolIndex].configuration.connection = result.option.id.toString();
                }
            } else {
                //add
                result.tool.configuration.connection = result.option.id.toString();
                this.formData?.configuration?.tools?.push(JSON.parse(JSON.stringify(result.tool)));
            }
        });
    }

    public handleItemChange(originalFields: FormArray) {
        const transformedValue = this._transformFields(originalFields.at(0)?.value);
        this.formData.storeAs = transformedValue;
        this._actionService.updateStorAs(transformedValue);
        this.originalFields = originalFields;
    }

    private objectToArray(obj: { [key: string]: Field; } = {}): any[] {
        if (!obj || typeof obj !== 'object')
            return [];

        return Object.entries(obj).map(([key, value]) => ({
            ...value,
            key: key,
            fieldName: value.fieldName ?? key,
            fields: value.fields ? this.objectToArray(value.fields) : undefined,
        }));
    }

    private flattenData(data: any[], parentFieldName: string = '', parentDisplay: string = ''): any[] {
        const result: any[] = [];
        const document = {
            "type": "string",
            "display": "Entire Document",
            "promptDisplay": "Entire Document",
            "menuDisplay": "Entire Document",
            "description": "The document object",
            "field_category": "default",
            "key": "decument",
            "fieldName": "decument",
            "tag": "{{entire_decument}}",
            "icon": 'description'
        };
        result.push(document);
        const processFields = (fields: any[], parentFieldName: string, parentDisplay: string, nested: string = 'parent') => {
            fields.forEach((field) => {
                const tag = parentFieldName ? `{{${parentFieldName}.${field.fieldName}}}` : `{{${field.fieldName}}}`;
                const promptDisplay = parentDisplay ? `${parentDisplay} - ${field.display}` : field.display;
                const menuDisplay = field.display;
                const icon = this.FIELD_TYPE_OPTIONS.find(opt => opt.value === field.type)?.icon;
                if (field.type === 'object_array' || field.type === 'object') {
                    result.push({ ...field, tag: tag, promptDisplay: promptDisplay, icon: icon, menuDisplay: menuDisplay, nested: nested });
                    processFields(field.fields, field.fieldName, field.display, 'child');
                } else {
                    result.push({ ...field, tag: tag, promptDisplay: promptDisplay, icon: icon, menuDisplay: menuDisplay, nested: nested });
                }
            });
        };

        processFields(data, parentFieldName, parentDisplay);
        return result;
    }

    private async _init(type: DatasetActionType) {
        this.dialogData.type = type;
        switch (type) {
            case DatasetActionType.Webhook:
                if (this.dialogData.id) {
                    this._loadWebHookData();
                } else {
                    this._setWebHookDefaultValues();
                }
                break;
            case DatasetActionType.AiGenerate:
                this._loadPromptFieldsMenu();
                if (this.dialogData.id) {
                    this._loadAiGenerateData();
                } else {
                    this._setAiGenerateDefaultValues();
                }
                break;
            case DatasetActionType.AiAgent:
                this._loadPromptFieldsMenu();
                this.allConnections = await this.connectionService.getConnections();
                this.subscriptions.push(
                    this.connectionService.connectionsList.subscribe(connections => {
                        this.allConnections = connections as any;
                    })
                );
                if (this.dialogData.id) {
                    this._loadAiAgentData();
                } else {
                    this._setAiAgentDefaultValues();
                }
                break;
        }
        this.isFormDataLoaded = true;
    }


    private _loadPromptFieldsMenu() {
        this.subscriptions.push(this._schemaService.oCurrentSchema.pipe(debounceTime(100)).subscribe(fields => {
            const fieldsObjectToArray = this.objectToArray(fields);
            const fieldsArray = this.flattenData(fieldsObjectToArray);
            if (this.dialogData.order) {
                // Get fields from prev actions
                const currentFieldOrder = this.dialogData.order;
                const previousFieldsIds = this.actionList.filter(action => action.order < currentFieldOrder).map(action => action.id);
                this.promptMenuItems = fieldsArray.filter(field => !field.action_id || previousFieldsIds.includes(field.action_id));
            } else {
                // Get all fields
                this.promptMenuItems = fieldsArray;
            }
        }));
    }

    private _loadAiGenerateData() {
        this.isEditMode = !!this.dialogData.id;
        this.formData.apiToken = this.dialogData.apiToken;
        this.formData.configuration = this.dialogData.configuration;
        this.formData.name = this.dialogData.name;
        this.formData.datasetId = this.dialogData.datasetId;
        this.formData.id = this.dialogData.id;
        this.formData.type = this.dialogData.type;
        this.formData.storeAs = this.dialogData.storeAs;
        this.formData.imageUrl = this.dialogData.imageUrl;
        this.formData.isActive = this.dialogData.isActive;
        this.formData.order = this.dialogData.order;
        this.formData.configuration.prompt = this.dialogData.configuration.prompt;
        this.promptValue = this.dialogData.configuration.prompt;
        this.formData.alwaysRun = this.dialogData.alwaysRun;
    }

    private _loadWebHookData(): void {
        this.isEditMode = !!this.dialogData.id;
        this.formData.id = this.dialogData.id;
        this.formData.datasetId = this.dialogData.datasetId;
        this.formData.type = this.dialogData.type;
        this.formData.configuration = this.dialogData.configuration;
        if (!this.formData.configuration.headers.length) {
            this.formData.configuration.headers = [{ key: "", value: "" }];
        }
        this.formData.name = this.dialogData.name;
        this.formData.storeAs = this.dialogData.storeAs;
        this.formData.order = this.dialogData.order;
        this.formData.alwaysRun = this.dialogData.alwaysRun;
    }

    private _setWebHookDefaultValues() {
        this.formData.configuration = {
            headers: [{ key: "", value: "" }]
        };
        this.formData.datasetId = this.dialogData.datasetId;
        this.formData.type = this.dialogData.type;
        this.formData.order = this.actionList.length + 1;
    }

    private _loadAiAgentData() {
        this.isEditMode = !!this.dialogData.id;
        this.formData.apiToken = this.dialogData.apiToken;
        this.formData.configuration = this.dialogData.configuration;
        this.formData.name = this.dialogData.name;
        this.formData.datasetId = this.dialogData.datasetId;
        this.formData.id = this.dialogData.id;
        this.formData.type = this.dialogData.type;
        this.formData.storeAs = this.dialogData.storeAs;
        this.formData.imageUrl = this.dialogData.imageUrl;
        this.formData.isActive = this.dialogData.isActive;
        this.formData.order = this.dialogData.order;
        this.formData.configuration.prompt = this.dialogData.configuration.prompt;
        this.promptValue = this.dialogData.configuration.prompt;
    }

    private _setAiGenerateDefaultValues() {
        this.formData.storeAs = { display: '' };
        this.formData.configuration = { prompt: '' };
        this.formData.datasetId = this.dialogData.datasetId;
        this.formData.type = this.dialogData.type;
        this.formData.order = this.actionList.length + 1;
    }

    private _setAiAgentDefaultValues() {
        this.formData.storeAs = { display: '' };
        this.formData.configuration = { prompt: '', tools: [] };
        this.formData.datasetId = this.dialogData.datasetId;
        this.formData.type = this.dialogData.type;
        this.formData.order = this.actionList.length + 1;
    }


    private _transformFields(firstValue: any) {
        const { dataType, ...rest } = firstValue;
        firstValue = {
            ...rest, key: rest?.fieldName || null, ...(dataType !== undefined ? { type: dataType } : {}),
        };
        return (['object', 'object_array'].includes(firstValue.type)) ? this._transformNesteadFields(firstValue) : { [firstValue?.fieldName || firstValue?.display]: firstValue };
    }

    private _transformNesteadFields(firstValue) {
        const transformedFields = {};
        firstValue.fields.forEach(field => {
            if (field && field.fieldName) {
                transformedFields[field?.fieldName || field?.display] = {
                    display: field.display || '',
                    type: field.type || field.dataType || 'string',
                    description: field.description || '',
                    key: field.fieldName || null,
                };
            }
        });
        return {
            [firstValue?.fieldName || firstValue?.display]: {
                ...firstValue,
                fields: transformedFields,
            }
        }
    }

    private _pushFieldsStoreAs() {
        if (this.dialogData?.storeAs && typeof this.dialogData.storeAs === 'object') {
            Object.entries(this.dialogData.storeAs).forEach(([key, value]: [string, any]) => {
                const formGroup = new FormGroup({
                    display: new FormControl(value?.display || ''),
                    fieldName: new FormControl(key || '', [Validators.required]),
                    dataType: new FormControl(value?.type || 'string'),
                    description: new FormControl(value?.description || ''),
                });
                if (['object', 'object_array'].includes(value.type)) {
                    const fieldsFormArray = new FormArray([]);
                    Object.entries(value.fields).forEach(([fieldKey, fieldValue]: [string, any]) => {
                        const fieldGroup = new FormGroup({
                            fieldName: new FormControl(fieldKey || ''),
                            display: new FormControl(fieldValue?.display || ''),
                            dataType: new FormControl(fieldValue?.type || 'string'),
                            description: new FormControl(fieldValue?.description || ''),
                        });
                        fieldsFormArray.push(fieldGroup);
                    });
                    fieldsFormArray.push(this._emptyFormGroup());
                    formGroup.addControl('fields', fieldsFormArray);
                }
                (this.originalFields as FormArray).push(formGroup);
            });
        } else {
            (this.originalFields as FormArray).push(this._emptyFormGroup());
        }
        this.isDataLoaded = true;
    }

    private _emptyFormGroup(): FormGroup {
        return new FormGroup({
            dataType: new FormControl('string'),
            fieldName: new FormControl(''),
            display: new FormControl(''),
            description: new FormControl(''),
        });
    }


}
