import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { DatasetDetailsDto, DatasetTypes } from '@app/ignite/components/reports/models/dataset.model';
import { DatasetService } from '@app/ignite/components/reports/services/api/dataset.service';
import { SchemaService } from '@app/ignite/components/reports/services/schema.service';
import { ERROR_SNACKBAR_OPTIONS, WARN_SNACKBAR_OPTIONS } from '../../../home/edit-title-dialog/edit-title-dialog';

@Component({
    selector: 'app-copy-from-dataset',
    templateUrl: './copy-from-dataset.component.html',
    styleUrls: ['./copy-from-dataset.component.scss']
})
export class CopyFromDatasetComponent implements OnInit {

    public searchText: string = '';
    public datasets: { workspaceTitle: string, datasets: DatasetDetailsDto[] }[] = [];
    @Input() datasetId: string;
    @Output() close: EventEmitter<boolean> = new EventEmitter();
    public loadingDatasetId: string = '';
    constructor(
        private _datasetService: DatasetService,
        private _schemaService: SchemaService,
        private _snackBar: MatSnackBar
    ) { }

    async ngOnInit() {
        const { isSuccess, result: allDatasets } = (await this._datasetService.getAll());

        if (isSuccess) {
            this.datasets =[]
            allDatasets?.filter(dataset => dataset.Type === DatasetTypes.WORKSPACE).forEach(dataset =>
                this.datasets.push( {
                    workspaceTitle: dataset.Title,
                    datasets: allDatasets.filter(d => d.WorkspaceId === dataset.Id)
                }));
                console.log('*********************************************************************', this.datasets)
        }
    }

    public async updateDocumentFields(dataset: DatasetDetailsDto) {
        if (this.loadingDatasetId !== '') {
            // this._showMsg(`Dataset (${this.datasets.find(dataset => dataset.Id === this.loadingDatasetId)?.Title}) Fields Loading`, ERROR_SNACKBAR_OPTIONS)
            return
        };
        this.loadingDatasetId = dataset.Id;
        const fields = (await this._datasetService.getFields(dataset.Id).toPromise())?.result;
        if (Object.keys(fields || {})?.length > 0) {
            await this._schemaService.setCurrentDocumentFields(fields);
            this.close.emit(true);
            this._showMsg(`Please save fields before making further actions`, WARN_SNACKBAR_OPTIONS)
        } else {
            this._showMsg(`Dataset (${dataset.Title}) fields are not valid`, ERROR_SNACKBAR_OPTIONS)
        }
        this.loadingDatasetId = '';
    }

    private _showMsg(msg: string, config: MatSnackBarConfig) {
        this._snackBar.open(msg, 'Close', config)
    }
}
