import { Component, OnInit, Input, OnDestroy, ChangeDetectorRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { ChangeDetectionStrategy } from '@angular/core';
import { debounceTime, take } from 'rxjs/operators';
import { GetDynFormBloc, SubmitDynFormBloc } from './dyn-form.blocs';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-dyn-form',
  templateUrl: './dyn-form.component.html',
  styleUrls: ['./dyn-form.component.scss'],
  providers: [
    GetDynFormBloc,
    SubmitDynFormBloc
  ],
  changeDetection: ChangeDetectionStrategy.Default
})
export class DynFormComponent implements OnInit, OnDestroy {
  @ViewChild('pdf') pdf;

  @Output()
  isSubmitted = new EventEmitter<boolean>();

  @Output()
  valueChanged = new EventEmitter<boolean>();

  @Output()
  valid = new EventEmitter<boolean>();

  @Output()
  rendered = new EventEmitter<boolean>();

  @Output()
  result = new EventEmitter<any>();

  @Input()
  set key(value: string) {
    this.getDynForm.setKey(value);
  }

  @Input()
  public autoSave: boolean;

  @Input()
  public message: string;

  @Input()
  title: string;

  @Input()
  horizontalMode: boolean;


  private _options: FormlyFormOptions = { formState: { model: {} } };

  @Input() public set options(value: FormlyFormOptions) {
    this._options = value;
  }
  public get options() {
    return this._options;
  }

  @Input() public set model(value) {
    this._model = value;
    this._originalData = JSON.parse(JSON.stringify(value));

    if (!!this._options.formState) {
      this._options.formState.model = this._model;
    }
    this.form.markAsPristine();
  }
  public get model() {
    return this._model;
  }

  @Input() showTitle = true;
  @Input() updatedOnKey: string;

  form = new FormGroup({});
  fields: FormlyFieldConfig[] = [];
  isBusy: boolean;
  isValid;
  isTouched;
  oldStatus = 'INVALID';
  formResult;
  public printMode = false;
  private _originalData;

  private _model;

  @Input() set isEditMode(value: boolean) {
    this._editMode = value;
    if (this.formResult) {
      this.fields = this.formResult.configuration.fields;
      this.model.isEditMode = this.isEditMode;
      if (!!this._options.formState) {
        this._options.formState.model = this._model;
      }
      this.setFieldsMode(this.formResult.configuration.fields, !this.isEditMode);
      this._cdRef.markForCheck();
    }
  }
  get isEditMode(): boolean {
    return this._editMode;
  }

  private _editMode = false;
  @Input()
  hasCustomSubmission: boolean = false;
  @Output()
  triggerCustomSubmission: EventEmitter<boolean> = new EventEmitter(false);
  constructor(
    private _cdRef: ChangeDetectorRef,
    private matSnackBar: MatSnackBar,
    public getDynForm: GetDynFormBloc,
    public submitDynForm: SubmitDynFormBloc
  ) {
    this.getDynForm.result.subscribe(res => {
      this.formResult = res.output;
      this.fields = this.formResult.configuration.fields;
      this.rendered.emit(true);
      this.setFieldsMode(this.formResult.configuration.fields, !this.isEditMode);
      _cdRef.markForCheck();
    });
  }

  ngOnInit() {
    this.submitDynForm.result.subscribe(result => {
      if (result.output.result) {
        this.result.emit(result.output.result);
        this.matSnackBar.open(this.message ? this.message : 'Saved Successfully.', '', {
          duration: 3000,
          panelClass: ['success-snackbar']
        });
      }
      if (result.output.error) {
        this.result.emit(false);
        this.matSnackBar.open(result.output.error.Message || result.output.error, '', {
          duration: 5000,
          panelClass: ['error-snackbar']
        });
      }
      this.isBusy = result.output.isBusy;
      if (!this._cdRef['destroyed']) {
        this._cdRef.markForCheck();
      }
    });
    this.subscribeToStatusChange();
    this.subscribeToValueChange();
  }

  public cancel() {
    this.model = this._originalData;
  }

  public async submit() {
    if (this.hasCustomSubmission === false) {
      await this.submitForm();
    } else {
      this.triggerCustomSubmission.emit(true);
    }
  }

  public async submitForm() {
    if (this.form.valid && !this.isBusy) {
      if (this.updatedOnKey) {
        this.model[this.updatedOnKey] = new Date();
      }
      const result = await this.submitDynForm.submit(this.model);
      this.isSubmitted.emit(true);
    } else if (!this.form.valid) {
      const invalidFields = this.findInvalidControlsRecursive(this.form).map(x => this.getField(x, this.fields).templateOptions.label).join(', ');
      this.matSnackBar.open(`Field(s) ${invalidFields} are not valid`, 'X', {
        duration: 30000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
        panelClass: ['custom-snackbar'],
      });
      this._cdRef.markForCheck();
    }
  }

  public print() {
    this.printMode = true;
    this._cdRef.markForCheck();
    this.pdf.saveAs(this.title);
    this.printMode = false;
    this._cdRef.markForCheck();
  }
  private subscribeToValueChange() {
    this.form.valueChanges.subscribe((value: any) => {
      if (this.form.dirty) {
        this.valueChanged.emit(value);
        if (this.autoSave) {
          this.submit();
        }
      }
    });
  }

  private subscribeToStatusChange() {
    this.form.statusChanges.pipe(
      debounceTime(100)
    ).subscribe(status => {
      this.isValid = false;
      this.isValid = this.form.status === 'VALID';
      this.valid.emit(this.isValid);
      if (!this._cdRef['destroyed']) {
        this._cdRef.markForCheck();
      }
      if (this.oldStatus !== 'DISABLED' && status !== 'DISABLED' && this.oldStatus !== status) {
        this.form.markAsDirty();
        this.form.markAllAsTouched();
        this.oldStatus = status;
      }
      this.isTouched = this.form.dirty;
    });
  }

  private setFieldsMode(fields: FormlyFieldConfig[], isDisabled: boolean) {
    fields.forEach((field) => {
      field = this.setFieldMode(field, isDisabled);
      if (field.fieldArray) {
        this.setFieldMode(field.fieldArray, isDisabled);
      }
    });
  }

  public findInvalidControlsRecursive(formToInvestigate: FormGroup | FormArray): string[] {
    const invalidControls: string[] = [];
    const recursiveFunc = (form: FormGroup | FormArray) => {
      Object.keys(form.controls).forEach(field => {
        const control = form.get(field);
        if (control.invalid && !(control instanceof FormArray) && !(control instanceof FormGroup)) {
          invalidControls.push(field);
          control.markAsDirty();
          control.markAsTouched();
        }
        if (control instanceof FormGroup || control instanceof FormArray) {
          recursiveFunc(control);
        }
      });
    }
    recursiveFunc(formToInvestigate);
    return invalidControls;
  }

  private setFieldMode(
    field: FormlyFieldConfig,
    isDisabled: boolean
  ): FormlyFieldConfig {
    let to = field?.templateOptions || {};
    to.disabled = to.readOnly || isDisabled;
    if (field.fieldGroup) {
      this.setFieldsMode(field.fieldGroup, isDisabled);
    }
    return field;
  }

  private getField(key: string, fields: FormlyFieldConfig[]): FormlyFieldConfig {
    for (let i = 0, len = fields.length; i < len; i++) {
      const f = fields[i];
      if (f.key === key) {
        return f;
      }
      if ((f.fieldGroup || f.fieldArray)) {
        const cf = this.getField(key, f.fieldGroup);
        if (cf) {
          return cf;
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.getDynForm.dispose();
    this.submitDynForm.dispose();
    this._cdRef.detach();
  }
}

