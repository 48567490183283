import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ActivatedRoute, UrlTree } from '@angular/router';
import { DatasetService } from '../../../services/api/dataset.service';
import { IgniteTabService } from '../../../services/ignite-tab.service';
import { ERROR_SNACKBAR_OPTIONS, SUCCESS_SNACKBAR_OPTIONS } from '../../home/edit-title-dialog/edit-title-dialog';

const TEMPLATE_CARDS = [
  { label: 'Purchase Order', value: 'purchase_order' },
  { label: 'HR Contract', value: 'hr_contract' },
  { label: 'MSA Contract', value: 'msa_contract' },
  { label: 'Receipt', value: 'receipt' },
  { label: 'Bill of Lading', value: 'bill_of_lading' },
  { label: 'Invoice', value: 'invoice' },
]
@Component({
  selector: 'app-schema',
  templateUrl: './schema.component.html',
  styleUrls: ['./schema.component.scss']
})
export class SchemaComponent implements OnInit {
  @Input() datasetId: string;
  @Input() generateSchema: boolean

  @Output() schemaUpdated: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() fileUploaded: EventEmitter<boolean> = new EventEmitter<boolean>();

  isLoading: boolean = false
  currentUrl: UrlTree
  templateCards = TEMPLATE_CARDS;
  searchText: string = '';
  isUploading: boolean = false;

  constructor(
    private _snackbar: MatSnackBar,
    private _datasetService: DatasetService,
    private _activated: ActivatedRoute,
    private _igniteTabService: IgniteTabService,
  ) { }

  async ngOnInit(): Promise<void> {
    this.datasetId = this._activated.snapshot.params['datasetId'];
  }

  async updateSchemaToTemplate(templateName: string) {
    this.isLoading = true;
    const fields = await this._datasetService.getTemplateFields(templateName).toPromise();
    const files = await this._igniteTabService.initTab("Records", fields?.result, this.datasetId);
    await this._datasetService.createOrUpdateAssets(this.datasetId, files).toPromise()
    var response = await this._datasetService.updateFieldsByTemplate(this.datasetId, templateName).toPromise();
    if (response.isSuccess) {
      this.schemaUpdated.emit(response?.result);
      this._showSnackbar('schema updated successfully', SUCCESS_SNACKBAR_OPTIONS)
    } else {
      this._showSnackbar("Error updating schema. Try again", ERROR_SNACKBAR_OPTIONS)
    }
    this.isLoading = false;
  }

  private _showSnackbar(msg: string, config: MatSnackBarConfig) {
    this._snackbar.open(msg, 'close', config);
  }
}
