import { DOCUMENT } from "@angular/common";

export enum FieldDataType {
    STRING = 'string',
    NUMBER = 'number',
    DATE = 'date',
    CURRENCY = 'currency',
    LINK_WEBSITE = 'link_website',
    EMAIL = 'email',
    PHONE = 'phone',
    BOOLEAN = 'boolean',
    IMG = 'img',
    HTML = 'html',
    STRING_ARRAY = 'string_array',
    DOUBLE = 'double',
    PERCENT = 'percent',
    TIMESTAMP = 'timestamp',
    TIMESTAMPTZ = 'timestamptz',
    INT = 'int',
    LINK = 'link',
    URL = 'url',
    OBJECT_ARRAY = 'object_array',
    OBJECT = 'object',
    DOCUMENT = 'document'
}

