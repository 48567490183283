import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BackendResponse } from '../../models/common';
import { Observable, BehaviorSubject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BackendRouteEndpoint } from './constants/backend-route-constants';
import { ActionModel } from '../../models/action.model';
import { RunSequentialActionsDto } from '../../models/dtos/run-sequential-action-dto';


@Injectable({
    providedIn: 'root'
})
export class ActionService {
  // TODO: Add Types instead of any

    private actionNameTitleSubject = new BehaviorSubject<string>('');
    public actionNameTitle$ = this.actionNameTitleSubject.asObservable();

    private storeAs = new BehaviorSubject<any>('');
    public storeAs$ = this.storeAs.asObservable();

    constructor(private _httpClient: HttpClient, private _matSnackBar: MatSnackBar) { }

    public updateActionNameTitle(name: string) {
        this.actionNameTitleSubject.next(name);
    }

    public getActionNameTitle(): string {
        return this.actionNameTitleSubject.getValue();
    }

    public updateStorAs(data: any) {
        this.storeAs.next(data);
    }

    public getStorAs(): any {
        return this.storeAs.getValue();
    }

    public create(data: ActionModel): Observable<BackendResponse<any>> {

        var uri = `${BackendRouteEndpoint.DATASET_Action_BASE_URL(data.datasetId)}`;

        return this._httpClient.post<BackendResponse<any>>(uri, data);
    }

    public update(data: ActionModel): Observable<BackendResponse<any>> {

        var uri = `${BackendRouteEndpoint.DATASET_Action_BASE_URL(data.datasetId)}/${data.id}`;


        return this._httpClient.put<BackendResponse<any>>(uri, data);
    }

    public reOrder(datasetId: string, data: {id: string, order: number}[]): Observable<BackendResponse<any>> {
        var uri = `${BackendRouteEndpoint.DATASET_Action_BASE_URL(datasetId)}/re-order`;
        return this._httpClient.put<BackendResponse<any>>(uri, data);
    }

    public getAll(datasetId: string): Observable<BackendResponse<any>> {

        var uri = `${BackendRouteEndpoint.DATASET_Action_BASE_URL(datasetId)}`;

        return this._httpClient.get<BackendResponse<any>>(uri);
    }
    public get(id: string, datasetId: string): Observable<BackendResponse<any>> {

        var uri = `${BackendRouteEndpoint.DATASET_Action_BASE_URL(datasetId)}/${id}`;

        return this._httpClient.get<BackendResponse<any>>(uri);
    }

    public delete(id: string, datasetId: string): Observable<BackendResponse<any>> {

        var uri = `${BackendRouteEndpoint.DATASET_Action_BASE_URL(datasetId)}/${id}`;

        return this._httpClient.delete<BackendResponse<string>>(uri);
    }

    public getToken(): Observable<BackendResponse<any>> {

        var uri = `${BackendRouteEndpoint.ACCOUNT_TOKEN_BASE_URL}`;

        return this._httpClient.get<BackendResponse<any>>(uri);
    }
    runTest(datasetId: string, body: any) {
        return this._httpClient.post<BackendResponse<any>>(`${BackendRouteEndpoint.DATASET_Action_BASE_URL(datasetId)}/test`, body);
    }

    public runSequential(datasetId: string, actionId: string, input: RunSequentialActionsDto): Observable<BackendResponse<any>> {
        const uri = `${BackendRouteEndpoint.DATASET_Action_BASE_URL(datasetId)}/${actionId}/run-sequential`;
        return this._httpClient.post<BackendResponse<any>>(uri, input);
    }
}
