<div *ngIf="(multiSelectService.oAllItems |async)?.length as selectedCount" class="flex items-center ml-10 ">
    <span class="text-sm text-gray-500">
        {{selectedCount}} SELECTED
    </span>
    <button mat-icon-button [mat-menu-trigger-for]="selected_actions">
        <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #selected_actions="matMenu">
        <ng-container *ngIf="(_datasetService.oDatasetDetails| async) as datasetDetails">
            <button *ngIf="(datasetDetails?.Type === datasetTypes.DATASET) || (datasetDetails?.Id !== datasetId)"
                mat-menu-item (click)="reprocessSelectedItems()">
                <mat-icon>refresh</mat-icon> Reprocess Selected
            </button>
        </ng-container>
        <button mat-menu-item (click)="deleteSelectedItems()">
            <mat-icon>delete</mat-icon> Delete Selected
        </button>
        <button mat-menu-item [disabled]="actionsList.length == 0"
            [matMenuTriggerFor]="actions"><mat-icon>restart_alt</mat-icon>Rerun Action</button>
    </mat-menu>

    <mat-menu #actions="matMenu">
        <button mat-menu-item *ngFor="let action of actionsList" (click)="rerunActionForSelectedItems(action)">
            {{action.name}}
        </button>
    </mat-menu>
</div>
