<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
  <!-- Tree Node for file items -->
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>

    <button mat-icon-button [disabled]="true"></button>
    <div class="img-with-node" [ngClass]="{'downloadable': node.type === NodeType.file}" (click)="node.type === NodeType.file && downloadFile(node)" 
    [matTooltip]="node.type === NodeType.file ? 'Download' : null">
      <img class="w-7 h-7" [src]="'assets/images/extension-icons/' + getIcon(node)+'.svg'" />
      <span class="node-name">{{ node.name }}</span>
    </div>
  </mat-tree-node>

  <!-- Tree Node for expandable folders -->
  <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
    <div class="img-with-node">
      <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.name">
        <mat-icon class="expand-icon mat-icon-rtl-mirror">
          {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
        </mat-icon>
      </button>
    </div>
    <mat-icon class="node-icon folder-icon">{{ getIcon(node) }}</mat-icon>
    <span class="node-name downloadable" (click)="downloadFile(node)" matTooltip="Download">
      {{ node.name }}
    </span>
  </mat-tree-node>



</mat-tree>

<!-- <mat-icon class="node-icon"
      [ngClass]="{'folder-icon': node.type === NodeType.folder, 'file-icon': node.type === NodeType.file}">
      {{ getIcon(node) }}
    </mat-icon> -->