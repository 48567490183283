import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NewConnectionDialogComponent } from './new-connection-dialog/new-connection-dialog.component';
import { ConnectionService } from './connection.service';
import { Connection } from './connection.interface';
import { UserData, UserDataService } from '@discoverer/app-core';
import { BaseController } from '@discoverer/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ERROR_SNACKBAR_OPTIONS, SUCCESS_SNACKBAR_OPTIONS } from '../home/edit-title-dialog/edit-title-dialog';
import { ConfirmationDialogComponent } from '../attoms/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-connections',
  templateUrl: './connections.component.html',
  styleUrls: ['./connections.component.scss']
})
export class ConnectionsComponent extends BaseController implements OnInit {
  connections: Connection[] = [];
  displayedColumns: string[] = ['connectionName', 'application', 'created', 'actions'];
  user: UserData;

  constructor(
    private connectionService: ConnectionService,
    private _userService: UserDataService,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar,
  ) {
    super();
  }

  ngOnInit(): void {
    this.subscriptions.push(this._userService.loggedInUser.subscribe((user: UserData) => this.user = user));
    this.loadData();
  }

  async loadData() {
    this.connections = await this.connectionService.getConnections(this.user.token);
  }

  openNewConnectionDialog(): void {
    const dialogRef = this.dialog.open(NewConnectionDialogComponent, { data: { user: this.user }, width: '550px', panelClass: 'new-connection-dialog' });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadData();
      }
    });
  }

  async testConnection(connection: Connection) {
    this.connectionService.checkConnectionStatus(connection.app_name, connection.connection_name, this.user.token).subscribe({
      next: (data) => {
        if (data.status === 'authenticated') {
          this._showMsg(`Valid connection for ${connection.display_name}`, SUCCESS_SNACKBAR_OPTIONS);
        } else {
          this._showMsg(`Invalid connection for ${connection.display_name}`, ERROR_SNACKBAR_OPTIONS);
        }
      },
      error: () => {
        this._showMsg(`Test failed for ${connection.display_name}`, ERROR_SNACKBAR_OPTIONS);
      }
    });
  }

  async deleteConnection(connectionName: string): Promise<void> {
    const deleteDialogRes = await this.dialog.open(ConfirmationDialogComponent, { width: '420px', data: { message: `Are you sure you want to delete the connection ${connectionName}?` } }).afterClosed().toPromise();
    if (deleteDialogRes) {
      this.connectionService.deleteConnection(connectionName, this.user.token).subscribe({
        next: () => {
          this._showMsg(`Connection deleted successfully`, SUCCESS_SNACKBAR_OPTIONS);
          this.loadData(); // Refresh the list
        },
        error: (error) => {
          console.error('Error deleting connection:', error);
          this._showMsg(`Error deleting connection`, ERROR_SNACKBAR_OPTIONS);
        }
      });
    }
  }

  editConnection(connection: Connection) {
    connection.isEditing = true;
    connection.newConnectionName = connection.connection_name;
  }

  saveConnection(connection: Connection) {
    this.connectionService.updateConnectionName(connection.id, connection.newConnectionName, this.user.token).subscribe({
      next: () => {
        connection.connection_name = connection.newConnectionName;
        connection.isEditing = false;
        this._showMsg(`Connection name updated successfully`, SUCCESS_SNACKBAR_OPTIONS);
      },
      error: () => {
        this._showMsg(`Failed to update connection name`, ERROR_SNACKBAR_OPTIONS);
      }
    });
  }

  cancelEdit(connection: Connection) {
    connection.isEditing = false;
  }

  private _showMsg(msg: string, config: MatSnackBarConfig) {
    this._snackBar.open(msg, undefined, config);
  }
}
