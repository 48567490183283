<div class="flex gap-2">

    <mat-slide-toggle [(ngModel)]="enableSelect"
        (change)="multiSelectService.markListEmpty(); toggleChanged.emit($event.checked)">
        <span *ngIf="enableSelect else disabled">({{(multiSelectService.oAllItems |
            async)?.length || 0}}) Selected</span>
        <ng-template #disabled>Multi Select</ng-template>
    </mat-slide-toggle>

    <button mat-icon-button [disabled]="(multiSelectService.oAllItems |async)?.length ===0"
        [mat-menu-trigger-for]="selected_otions">
        <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #selected_otions="matMenu">
        <button *ngIf="datasetDetails && datasetDetails.Type === datasetTypes.DATASET" mat-menu-item (click)="reprceossSelected()">
            <mat-icon>refresh</mat-icon> Reprocess Selected
        </button>
        <button mat-menu-item (click)="deleteSelected()">
            <mat-icon>delete</mat-icon> Delete Selected
        </button>
    </mat-menu>
</div>
