<div id="dashboard">
  <mat-accordion [togglePosition]="'before'" class="example-headers-align" multi *ngIf="reports">
    <div class="row reports-container">
      <div *ngFor="let report of reports; let i = index" class="item item-6">
        <disco-tab [appId]="appId" [tabId]="tabId">
          <ng-template let-tabContext>
            <disco-report *ngIf="tabContext" [queryService]="queryService" [requestId]="report.id">
              <ng-template let-reportContext>
                <mat-expansion-panel (expandedChange)="report.expand = $event" [expanded]="report.expand">
                  <mat-expansion-panel-header >
                    <mat-panel-title>
                      <div class="report-header">
                        <span class="report-name"
                          *ngIf="reportContext">{{reportContext.currentReport.mainView.mainTitle}} {{ (reportContext.currentReport.mainView.type === 'table-view' && report.total) ? '(' + report.total +')' : ''}}</span>&nbsp;
                      </div>
                    </mat-panel-title>
                    <mat-panel-description (click)="$event.stopPropagation();">
                      <div class="report-header">
                        <span (click)="navigateToReport(tabContext.currentTab.key, report.id)"
                          class="material-icons icon">launch</span>
                        <div [ngSwitch]="reportContext?.currentReport?.mainView?.type">
                          <span *ngSwitchCase="'pivot-view'" (click)="REPORT.export()" class="material-icons icon">download</span>
                          <span *ngSwitchCase="'chart-view'" [matMenuTriggerFor]="menu" class="material-icons icon">download</span>
                          <span *ngSwitchCase="'table-view'" (click)="REPORT.export()" class="material-icons icon">download</span>
                          <mat-menu #menu="matMenu">
                              <button mat-menu-item (click)="REPORT.export('png')"> Save as PNG </button>
                              <button mat-menu-item (click)="REPORT.export('jpeg')"> Save as JPEG</button>
                              <button mat-menu-item (click)="REPORT.export('svg')"> Save as SVG</button>
                              <mat-divider></mat-divider>
                              <button mat-menu-item (click)="REPORT.export('pdf')"> Save as PDF</button>
                          </mat-menu>
                        </div>
                      </div>
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <mat-divider></mat-divider>
                  <reports-view #REPORT [chartMaxLabels]="10" (numberOfRecordsEvent)="report.total = $event" [visibleDetailsHeader]="false" >
                  </reports-view>
                </mat-expansion-panel>
              </ng-template>
            </disco-report>
          </ng-template>
        </disco-tab>
      </div>
    </div>

  </mat-accordion>
</div>
