<div class="schema-form-wrapper h-full" cdkDropListGroup>

    <div class="flex w-full h-full items-start gap-2">
        <!-- Main content -->
        <div [ngClass]="hideFileViewer ? 'w-full' : 'w-7/12'">
            <div class="page-header-wrapper">
                <div class="page-header">
                    <ng-container>
                        <div class="flex gap-1 cursor-pointer items-center">
                            <b class="whitespace-nowrap">Fields</b>

                        </div>
                        <div class="pl-10 space-x-4 page-header-actions">
                            <div class="left-btn-actions">
                                <!-- Edit Button -->
                                <button mat-flat-button color="primary" *ngIf="!this.isEditMode && !this.isLoading"
                                    (click)="toggleEdit()">
                                    <mat-icon class="material-icons-outlined">edit</mat-icon>
                                    Edit
                                </button>
                                <!-- Save and Cancel Buttons -->
                                <ng-container *ngIf="(this.isEditMode || this.isLoading)">
                                    <button mat-flat-button color="primary" (click)="save()">
                                        <mat-icon *ngIf="!isLoading" class="material-icons-outlined">save</mat-icon>
                                        <mat-spinner *ngIf="isLoading" [diameter]="24"></mat-spinner>
                                        Save
                                    </button>
                                    <button mat-stroked-button class="basic-button" color="basic" (click)="cancel()"
                                        [disabled]="isLoading">
                                        <mat-icon class="material-icons-outlined close-but">close</mat-icon>
                                        Cancel
                                    </button>
                                </ng-container>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>

            <div class="main-fields">

                <app-schema-fields class="w-full" [isDataLoaded]="isDataLoaded" [isEditMode]="isEditMode"
                    [isNestEdit]="isEditMode" [originalFields]="originalFields" [objectFormGroup]="objectFormGroup" (newFieldAdded)="setFieldsUpdated($event)">
                </app-schema-fields>

                <div class="px-4 w-full">
                    <button class="auto-import" mat-button color="Primary" (click)="openAutoGeneratedFieldsDialog()">
                        <mat-icon fontSet="material-icons-round">auto_awesome</mat-icon>Auto Import Fields
                    </button>
                </div>
            </div>

        </div>
        <div [ngClass]="(hideFileViewer) ? '' : 'w-5/12'">
            <div class="flex flex-col w-full h-full">
                <ng-content select="[extraActions]"></ng-content>
                <ng-content select="[fileActions]"></ng-content>
            </div>
        </div>
    </div>
</div>
