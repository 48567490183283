import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BackendResponse } from '../../../models/common';
import { BackendRouteEndpoint } from '../../../services/api/constants/backend-route-constants';

@Injectable()
export class StripeService {

  constructor(
    private _httpClient: HttpClient
  ) { }

  getPortalSession() {
    return this._httpClient.get<BackendResponse<string>>(`${BackendRouteEndpoint.STRIPE_BASE_URL}/portal-session`);
  }

  verifySubscription() {
    return this._httpClient.get<BackendResponse<boolean>>(`${BackendRouteEndpoint.STRIPE_BASE_URL}/verify-subscription`);
  }
}
