import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
export class ChildQueue {
    Id: string;
    OriginalFileName: string;
    EmailSender: string;
    ParsedDate: Date;
    Status: string;
    DatasetKey: string;
    FileExtension: string;
    UpdatedAt: Date;
    CreatedBy: string;
    UpdatedBy: Date;
    EmailUniqueId: string;
    FileTaskId: string;
    IsTest: boolean;
    NeedsReprocessing: string;
    ResultsCount: number;
    ParentFileId: string;
    DatasetTitle: string;
}
@Component({
    selector: 'app-chooser-queue-children-viewer',
    templateUrl: './chooser-queue-children-viewer.component.html',
    styleUrls: ['./chooser-queue-children-viewer.component.scss']
})
export class ChooserQueueChildrenViewerComponent implements OnInit {
    @Input() children: ChildQueue[] = [];
    constructor(private _router: Router) { }

    ngOnInit(): void {
    }
    async navigateToDetails(childDatasetKey: string, childId: string, page: 'queue' | 'records') {
        // await this._persistsService.createState();
        const url = this._router.serializeUrl(this._router.createUrlTree(['datasets', childDatasetKey, page, childId]));
        window.open(url + `?currentReqId=default&start=0&fileId=${childId}`, '_blank');
    }
}
