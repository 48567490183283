import { Component, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IColumnSetting } from '@discoverer/core/services/classes/discoverer.class';
import { DiscoTabContext } from '@discoverer/dynamic-reports';
import { DatasetFileService } from '../../../services/api/dataset-file.service';
import { ERROR_SNACKBAR_OPTIONS, SUCCESS_SNACKBAR_OPTIONS } from '../../home/edit-title-dialog/edit-title-dialog';

@Component({
    selector: 'app-add-new-record',
    templateUrl: './add-new-record.component.html',
    styleUrls: ['./add-new-record.component.scss']
})
export class AddNewRecordComponent {
    @Input() datasetId: string;

    public newRecord = {}
    public tabColumns: IColumnSetting[] = []
    public loading = false;
    public valid = false;
    public showFileResultEditor = true;
    public showSkeleton = false;
    public skeletonTheme = { height: '30px', opacity: '0.8', 'margin-bottom': '24px' }

    constructor(
        private _datasetFileService: DatasetFileService,
        private _snackBar: MatSnackBar
    ) { }

    private _removeEmptyLines(field: string) {
        const keys = field.split('.');
        for (const key of keys) {
            this.newRecord[key] =
                this.newRecord[key]?.filter(data => Object.values(data).some(r => !!(r?.toString())));
        }
    }

    public setContext($event: DiscoTabContext): void {
        this.tabColumns = $event.columnSettings
    }

    public checkValid($event) {
        this.valid = $event;
    }

    public async save(): Promise<void> {
        this.loading = true
        try {
            for (const col of this.tabColumns.filter(col => col.dataType === 'object_array')) { this._removeEmptyLines(col.fieldName) }
            const { isSuccess, result } = await this._datasetFileService.addNewRecord(this.datasetId, this.newRecord).toPromise()
            isSuccess ? this._onSuccess() : this._onError(result)
        } catch (error) {
            this._onError(error)
        } finally {
            this.loading = false
        }
    }

    private _onSuccess(): void {
        this._snackBar.open('Record added successfully.', 'close', SUCCESS_SNACKBAR_OPTIONS);
        console.log('Record added successfully');
        this.newRecord = {};
        this.showFileResultEditor = false;
        this.showSkeleton = true;
        setTimeout(() => {
            this.showSkeleton = false;
            this.showFileResultEditor = true;
        }, 1000);
    }

    private _onError(result: any): void {
        this._snackBar.open(`Error adding record: ${result}`, 'close', ERROR_SNACKBAR_OPTIONS);
        console.error('Error adding record:', result);
    }

}
