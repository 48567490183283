<div class="wrapper">
    <!-- Header -->
    <div class="page-header-wrapper flex flex-col gap-2 ">
        <div class="page-header flex gap-4 items-center">
            <p>Actions</p>
            <button (click)="create()" mat-mini-fab color="primary" matTooltip="Create New Action" >
                <mat-icon>add</mat-icon>
            </button>
        </div>
        <div class="page-sub-header flex  text-secondary "> Easily send parsed data in real-time to your
            favorite third-party services or apps. With just a few clicks,
            you can create Google Sheets, notify your team on Slack, store data in Airtable, or set up any other
            custom
            action.
        </div>
    </div>

    <div class="main-contant flex flex-col gap-5">
        <div class="main">
            <div class="flex flex-col gap-2 p-5">
                <div class="flex justify-between align-start">
                    <div class="flex flex-col text-xl font-medium leading-8">
                        <div>
                            Your Actions
                        </div>
                        <div *ngIf="actionList?.length == 0" class="text-base">
                            You have no actions setup.
                        </div>
                    </div>
                </div>
                <div *ngIf="actionList?.length > 0"
                    class="actions-list flex grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 gap-6 w-full min-w-0">
                    <ng-container *ngFor="let action of actionList; let i = index; let first = first; let last = last;">
                        <!-- Card -->
                        <mat-card
                            class="flex flex-col flex-auto p-6 bg-card shadow rounded-2xl overflow-overflow cursor-default">
                            <div class="flex flex-row items-center justify-between">
                                <div class="flex flex-1 flex-row items-center gap-2">
                                    <img [src]="getActionImage(action.type)" alt="logo" class="w-10 h-10">
                                    <div class="flex flex-col p-2">
                                        <div class="flex text-xl font-bold items-center">{{action.name}} <span
                                                *ngIf="action?.storeAs?.display as storeAsDisplay"
                                                class="flex items-center"><mat-icon
                                                    class="mx-2">arrow_right_alt</mat-icon> {{storeAsDisplay}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex flex-0 flex-row justify-between gap-2">
                                    <div class="flex-1 ml-2 -mt-2 -mr-3">
                                        <button mat-icon-button [matMenuTriggerFor]="summaryMenu"
                                            *ngIf="deleteIsLoadingId !== action.id else LOADING_DELETE">
                                            <mat-icon class="icon-size-5"
                                                [svgIcon]="'heroicons_solid:dots-horizontal'"></mat-icon>
                                        </button>
                                        <ng-template #LOADING_DELETE>
                                            <mat-spinner diameter="20"></mat-spinner>
                                        </ng-template>
                                        <mat-menu #summaryMenu="matMenu">
                                            <button (click)="openLogs(action.id)" mat-menu-item><mat-icon
                                                    class="icon-size-5 mr-3 duplicate-mat-icon"
                                                    [svgIcon]="'heroicons_solid:document-text'"></mat-icon>
                                                Logs</button>
                                            <button (click)="edit(action.id)" mat-menu-item> <mat-icon
                                                    class="icon-size-5 mr-3 duplicate-mat-icon"
                                                    [svgIcon]="'heroicons_solid:pencil'"></mat-icon>
                                                Edit</button>
                                            <button mat-menu-item (click)="delete(action.id)"> <mat-icon
                                                    class="icon-size-5 mr-3 " [svgIcon]="'heroicons_solid:trash'">
                                                </mat-icon>
                                                Delete</button>
                                        </mat-menu>
                                    </div>
                                </div>

                            </div>
                        </mat-card>
                    </ng-container>
                </div>
            </div>

        </div>
    </div>
</div>
