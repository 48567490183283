<mat-form-field class="search-bar">
    <input #searchField [autoFieldFocus]="searchField" [(ngModel)]="searchText" matInput type="text"
        placeholder="Search for template" />
</mat-form-field>

<p class="not-found-hint" *ngIf="!datasets.length">No Workspaces found</p>
<div class="flex flex-col">
    <ng-container *ngFor="let workspace of datasets">
        <p class="workspace-name">{{ workspace.workspaceTitle }}</p>
        <div class="templates-container">
            <ng-container *ngFor="let dataset of workspace.datasets | filter: searchText: 'Title'">
                <div class="template-card flex gap-2" (click)="updateDocumentFields(dataset)">
                    <mat-spinner diameter="20" *ngIf="loadingDatasetId === dataset.Id"></mat-spinner>
                    <p class="template-name">{{ dataset.Title }}</p>
                </div>
                <p class="not-found-hint" *ngIf="!(workspace.datasets | filter: searchText: 'Title').length">No
                    Workspaces found</p>
            </ng-container>
        </div>

    </ng-container>
</div>
