import { Component, OnInit, Inject } from '@angular/core';
import { DiscovererQueryService, buildFacetFilter, DRFilter, FacetValue, DRFacetOption } from '@discoverer/core';
import { environment } from '@env/environment';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'integration-logs-dialog',
    templateUrl: 'integration-logs-dialog.component.html',
    styleUrls: ['./integration-logs-dialog.component.scss', '../../../../../../../../src/styles/ai-shared-styles.scss']
})

export class IntegrationLogsDialogComponent implements OnInit {
    appId: string = environment.auth.productKey;
    filters = {}
    constructor(
        public dialogRef: MatDialogRef<IntegrationLogsDialogComponent>,
        public queryService: DiscovererQueryService,
        @Inject(MAT_DIALOG_DATA) public data: { datasetId: string, id: string }
    ) { }

    async ngOnInit(): Promise<void> {
        this.filters['IntegrationId'] = buildFacetFilter('IntegrationId', [this.data.id]);
        this.queryService.refresh();
    }
}
