import { environment } from '@env/environment';
import { ColumnSetting } from '../models/column-settings'
import { standardCols } from '../services/ignite-tab.service';
import { GeneralDataType, IColumnSetting, SpecificDataType } from '@discoverer/core';
import { FieldCategoryEnum } from '../models/enums';

export type FieldType = 'object' | 'object_array' | 'string' | 'number' | 'boolean' | 'date' | 'document';

export type Section = { title: string, type: 'form' | 'table', nested: string, rows: string[][] };
export type Field = { display: string, type: FieldType, field_category: FieldCategoryEnum, fields: { [key: string]: Field }, description?: string, fieldName: string, action_id: string };



export function mapColumns(fields: { [key: string]: Field }, includeStandardCols: boolean, parentKey: string = null, parentDisplay: string = null): ColumnSetting[] {
    let cols: ColumnSetting[] = [];
    const keys = Object.keys(fields)
    keys.forEach((key, i) => {
        const col = fields[key] as Field
        const currentKey = parentKey ? `${parentKey}.${key}` : key;
        const currentDisplay = parentDisplay ? `${parentDisplay} - ${col.display}` : col.display;
        //const currentDisplay = col.display;
        if (col.type !== 'document') {
            const hideField = col.type?.includes('object') ? true : false;
            cols.push(new ColumnSetting(
                currentKey,
                convertTypeToSpecificDataType(col.type),
                currentDisplay,
                translateType(col.type),
                parentKey || "",
                hideField,
                i
            ));
        }
        if (!!col.fields) {
            cols.push(...mapColumns(col.fields, false, currentKey, currentDisplay));
        }
    });

    if (includeStandardCols) {
        cols.push(...standardCols);
    }
    return cols;
}

function convertTypeToSpecificDataType(type: string): SpecificDataType {
    switch (type) {
        case 'email' :
            return 'string' as SpecificDataType;
        default:
            return type as SpecificDataType;
    }
}

function translateType(dataType: string): GeneralDataType {
    const translateDataType = {
        string: 'text',
        number: 'numeric',
        date: 'date',
        object_array: 'object_array',
        object: 'object'
    };
    return translateDataType[dataType];
}
export function mapConnectionSettings(key: string, businessKey: string) {
    return {
        index: `data_${key}`,
        table: "",
        businessKey,
        originalTable: "",
        schema: "",
        incrementalUpdate: {
            isIncremental: false,
            updatedOnColumnName: null,
            lastSuccessfulDateRun: null
        }
    }
}
export function mapTabConfig(key: string, name: string, defaultRequestId, nestedPathsKeys) {
    return {
        type: null,
        claim: null,
        icon: null,
        name,
        pageSize: 40,
        serviceUrl: `api/parsed-search/SearchResults/${environment.auth.productKey}/${key}`,
        link: null,
        key,
        isReady: null,
        defaultRequestId,
        defaultFacets: [],
        defaultSorts: [],
        order: null,
        occurrence: null,
        hideReportControlFeatures: true,
        nestedPathsKeys: nestedPathsKeys,
        // refreshTimeout: 5
    }
}
export function toBase64String(obj: any): string {
    return btoa(JSON.stringify(obj))
}

export function isObject(data: any): boolean {
    return !!(typeof data === 'object' && data !== null)
}

export function createKeyFromName(name: string): string {
    if (!name) return name;
    const randomSuffix = Array.from({ length: 3 }, () =>
        Math.random().toString(36).charAt(2).toUpperCase()
    ).join('');
    return `${name?.replace(/ /g, '').toLowerCase()}_${randomSuffix}`;
}
