import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { Field } from '../../helpers/docpipeline-function';
import { BackendResponse } from '../../models/common';
import { DatasetCountDto, DatasetDetailsDto, DatasetTypes } from '../../models/dataset.model';
import { FileInfoDto } from '../../models/datasetFile.models';
import { CreateDatasetDto } from '../../models/dtos/create-dataset-dto';
import { BackendRouteEndpoint } from './constants/backend-route-constants';

@Injectable({
    providedIn: 'root'
})
export class DatasetService {

    private _datasetDetails$: BehaviorSubject<DatasetDetailsDto> = new BehaviorSubject<DatasetDetailsDto>(null);
    oDatasetDetails: Observable<DatasetDetailsDto> = this._datasetDetails$.asObservable();

    private _currentWorkspaceDetails$: BehaviorSubject<DatasetDetailsDto> = new BehaviorSubject<DatasetDetailsDto>(null);
    oCurrentWorkspaceDetails: Observable<DatasetDetailsDto> = this._currentWorkspaceDetails$.asObservable();

    private _currentDatasetList$: BehaviorSubject<DatasetDetailsDto[]> = new BehaviorSubject<DatasetDetailsDto[]>([]);
    oCurrentDatasetList: Observable<DatasetDetailsDto[]> = this._currentDatasetList$.asObservable();

    private _workspaceDatasetList$: BehaviorSubject<DatasetDetailsDto[]> = new BehaviorSubject<DatasetDetailsDto[]>([]);
    oWorkspaceDatasetList: Observable<DatasetDetailsDto[]> = this._workspaceDatasetList$.asObservable();
    constructor(
        private httpClient: HttpClient
    ) { }

    async getAll(): Promise<BackendResponse<DatasetDetailsDto[]>> {
        const { isSuccess, result } = await this.httpClient.get<BackendResponse<DatasetDetailsDto[]>>(`${BackendRouteEndpoint.DATASET_BASE_URL}`).toPromise();
        if (isSuccess && result != null){
            const workspaceDatasets = result.filter(d => d.Type === DatasetTypes.WORKSPACE);
            this._workspaceDatasetList$.next(workspaceDatasets);
            if (!this._datasetDetails$.getValue()) {
                this.updateDatasetDetails(workspaceDatasets[0]);
            }
        }
        return { isSuccess, result };
    }
    async getById(datasetId: string): Promise<BackendResponse<any>> {
        const datasetDetails = await this.getCurrentBaseDatasetDetails();
        if (datasetDetails?.Id === datasetId) return { isSuccess: true, result: datasetDetails };
        const { isSuccess, result } = await this.httpClient.get<BackendResponse<any>>(`${BackendRouteEndpoint.DATASET_BASE_URL}/${datasetId}`).toPromise();
        if (isSuccess && result != null) {
            this._datasetDetails$.next(result);
        }
        return { isSuccess, result };
    }
    async getWorkspaceById(datasetId: string): Promise<BackendResponse<any>> {
        const { isSuccess, result } = await this.httpClient.get<BackendResponse<any>>(`${BackendRouteEndpoint.DATASET_BASE_URL}/workspace/${datasetId}`).toPromise();
        if (isSuccess && result != null) {
            this._currentDatasetList$.next(result);
        }
        return { isSuccess, result };
    }
    updateDatasetDetails(datasetDetails: DatasetDetailsDto) {
        this._datasetDetails$.next(datasetDetails);
    }

    async getCurrentBaseDatasetDetails() {
        return this.oDatasetDetails.pipe(take(1)).toPromise();
    }
    getCounts(datasetId: string): Observable<BackendResponse<DatasetCountDto[]>> {
        return this.httpClient.get<BackendResponse<DatasetCountDto[]>>(`${BackendRouteEndpoint.DATASET_BASE_URL}/${datasetId}/counts`);
    }
    getFields(datasetId: string): Observable<BackendResponse<{ [key: string]: Field }>> {
        return this.httpClient.get<BackendResponse<{ [key: string]: Field }>>(`${BackendRouteEndpoint.DATASET_BASE_URL}/${datasetId}/fields`)
    }
    getTemplateFields(templateName: string) {
        return this.httpClient.get<BackendResponse<any>>(`${BackendRouteEndpoint.DATASET_BASE_URL}/fields/by-template-name/${templateName}`);
    }
    getGeneralSettings(datasetId: string) {
        return this.httpClient.get<BackendResponse<any>>(`${BackendRouteEndpoint.DATASET_BASE_URL}/${datasetId}/general-settings`);
    }
    getAllTemplatesFields() {
        return this.httpClient.get<BackendResponse<{ [key: string]: { [key: string]: Field } }>>(`${BackendRouteEndpoint.DATASET_BASE_URL}/templates`);
    }

    create(dataset: CreateDatasetDto, type: DatasetTypes = DatasetTypes.DATASET): Observable<BackendResponse<any>> {
        return this.httpClient.post<BackendResponse<any>>(`${BackendRouteEndpoint.DATASET_BASE_URL}?type=${type}`, dataset);
    }
    createFieldsByFile(datasetId: string, obj: FormData): Observable<BackendResponse<FileInfoDto>> {
        return this.httpClient.post<BackendResponse<FileInfoDto>>(`${BackendRouteEndpoint.DATASET_BASE_URL}/${datasetId}/fields`, obj)
    }
    createOrUpdateAssets(datasetId: string, assetsFiles) {
        return this.httpClient.post<BackendResponse<any>>(`${BackendRouteEndpoint.DATASET_BASE_URL}/${datasetId}/assets`, assetsFiles)
    }

    updateFields(datasetId: string, fields, newFieldsAdded: boolean): Observable<BackendResponse<any>> {
        return this.httpClient.put<BackendResponse<any>>(`${BackendRouteEndpoint.DATASET_BASE_URL}/${datasetId}/fields?newFieldsAdded=${newFieldsAdded}`, fields)
    }
    updateFieldsByTemplate(datasetId: string, templateName: string): Observable<BackendResponse<any>> {
        return this.httpClient.put<BackendResponse<any>>(`${BackendRouteEndpoint.DATASET_BASE_URL}/${datasetId}/fields/by-template-name/${templateName}`, null)
    }
    updateTemplateName(datasetId: string, name: string, description: string): Observable<BackendResponse<any>> {
        return this.httpClient.put<BackendResponse<any>>(`${BackendRouteEndpoint.DATASET_BASE_URL}/${datasetId}/info`, { name, description })
    }
    updateDatasetGeneralSettings(datasetId: string, generalSettings: any): Observable<BackendResponse<any>> {
        return this.httpClient.put<BackendResponse<any>>(`${BackendRouteEndpoint.DATASET_BASE_URL}/${datasetId}/general-settings`, generalSettings)
    }
    updateDatasetAssetsAndIndex(datasetId: string, assetsFiles) {
        return this.httpClient.put<BackendResponse<boolean>>(`${BackendRouteEndpoint.DATASET_BASE_URL}/${datasetId}/assets-and-index`, assetsFiles)
    }

    delete(datasetId: string): Observable<BackendResponse<any>> {
        return this.httpClient.delete<BackendResponse<any>>(`${BackendRouteEndpoint.DATASET_BASE_URL}/${datasetId}`);
    }
    async updateWorkspaceDetails(workspaceId: string) {
        const worspaceList = await this.oWorkspaceDatasetList.pipe(take(1)).toPromise()
        const workspace = worspaceList.find(w => w.Id === workspaceId);
        this._currentWorkspaceDetails$.next(workspace);
    }
}
