import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BaseController, DiscovererQueryService, DRQuery, ExportDataTableService } from '@discoverer/core/services';
import { IDynamicReport, ITab2, ReportPersistService } from '@discoverer/core/services/dynamic-reports-services';
import { ExportDialogComponent } from '@discoverer/dynamic-reports/dialogs/export-dialog/export-dialog.component';
import { filter, first } from 'rxjs/operators';

export const MAX_EXCEL_SIZE = 10000;

@Component({
    selector: 'export-report',
    styleUrls: ['./export.component.scss'],
    templateUrl: './export.component.html'
})
export class ExportReportComponent extends BaseController implements OnInit {
    @Input() public queryService: DiscovererQueryService;
    @Input() public set currentTab(value: ITab2) {
        if (!!value) {
            this.tabName = value.name;
            this.tabKey = value.key;
            this.currentAppKey = value.app.key;
            this.searchUrl = value.serviceUrl;
        }
        this._currentTab = value;
    }
    @Input() public set resultSize(value) {
        this._resultSize = value;
    }
    public reportType = '';
    public viewType = '';
    private _currentTab: ITab2;
    private _resultSize;
    public currentAppKey: string;
    public tabName: string;
    public tabKey: string;
    public searchUrl: string;
    private currentQuery: DRQuery;

    constructor(
        public reportPersist: ReportPersistService,
        public exportSevice: ExportDataTableService,
        public dialog: MatDialog
    ) {
        super();
    }

    async ngOnInit() {
        this.subscriptions.push(
            this.reportPersist.oLastRequestData.pipe(filter(request => !!request)).subscribe(requestLoaded => {
                this.reportLoaded(requestLoaded);
            })
        );
        this.subscriptions.push(this.queryService.oQuery.subscribe((data) => {
            this.currentQuery = data;
        }));
    }

    private reportLoaded(requestLoaded: IDynamicReport) {
        this.viewType = requestLoaded.mainView.type;
        this.reportType = requestLoaded.mainView.type;
    }

    public async exportToExcel(resultLength?: number): Promise<void> {
        this.currentQuery.size = resultLength;
        let response;
        const state = await (this.reportPersist.oLastRequestData.pipe(first()).toPromise());
        this.currentQuery.start = this.currentQuery.start < 0 ? 0 : this.currentQuery.start;
        this.currentQuery.fields = (await this.reportPersist.getFieldNamesFromConfig(true)).fields;
        this.currentQuery.dataFlattenBy = state.tableView.dataFlattenBy;
        const outputType = this.currentQuery.size <= MAX_EXCEL_SIZE ? 'excel' : 'csv';
        this.exportSevice.ExportToExcel(`${this.searchUrl}?outputType=${outputType}`, state, this.currentQuery).then(x => {
            response = x;
            const blob = new Blob([response], { type: outputType === 'excel' ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' : 'text/csv;charset=utf-8;' });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = this.getReportTitle();
            anchor.click();
        }).catch(err => {
            console.error('Error exporting to Excel:', err);
        });
    }
    public async openExportDialog(excelNumber: number, csvNumber: number) {
        const dialogRef = this.dialog.open(ExportDialogComponent, {
            data: { excelNumber, csvNumber },
            width: '550px',
            panelClass: 'white-class-details-dialog',
            autoFocus: false
        });
        return dialogRef.afterClosed().toPromise();

    }
    private getReportTitle(): string {
        const date = new Date().toLocaleDateString().replace('/', '_').replace('/', '_');
        const time = `${new Date().getHours()}_${new Date().getMinutes()}`;
        const reportTitle = `${this.tabName} Report - ${date} ${time}`;
        return reportTitle;
    }
}
