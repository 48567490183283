<div *ngIf="result && !isLoading && isDataExists">
    <div *ngFor="let res of result?.parser_0; let i = index">
        <!-- <h1 class="text-2xl font-bold ml-3"> File Results Number ({{i+1}}):</h1> -->

        <app-file-result-displayer [datasetId]="datasetId" [fileId]="fileId" [resultIndex]="i"
            (results)="isDataExists = !!$event"></app-file-result-displayer>
        <mat-divider *ngIf="result?.parser_0?.length - 1 !== i" class="divider" ></mat-divider>
    </div>
</div>

<div *ngIf="!isDataExists && !isLoading" class="no-data-label">
    <p>No Data</p>
</div>

<div *ngIf="isLoading" class="spinner">
    <mat-spinner diameter="100"></mat-spinner>
</div>
