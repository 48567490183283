<div class="content">
    <div class="info-actions">
        <div class="record-info">
            <ngx-skeleton-loader *ngIf="isLoading" count="3" animation="progress-dark" [theme]="skeletonTheme">
            </ngx-skeleton-loader>

            <ng-container *ngIf="!isLoading">

                <ng-container *ngIf="titles?.length else viewWithoutFieldsTemplate;">
                    <p class="first-line">{{titles[0] || "-"}}</p>
                    <p class="second-line">{{titles[1] || "-"}}</p>
                    <p class="third-line">{{fileName}}.{{fileExtension}} ({{dateReceived | date :'medium'}})</p>
                </ng-container>

                <ng-template #viewWithoutFieldsTemplate>
                    <p class="file-name">{{fileName}}.{{fileExtension}}</p>
                    <p class="date-received">Received {{dateReceived | date : 'medium'}}</p>
                </ng-template>
            </ng-container>
        </div>
    </div>

</div>