import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { BaseController } from '@discoverer/core';
import { debounceTime } from 'rxjs/operators';
import { DatasetFileService } from '../../services/api/dataset-file.service';
import { EmitterService } from '../../services/emitter.service';
import { ERROR_SNACKBAR_OPTIONS, SUCCESS_SNACKBAR_OPTIONS } from '../home/edit-title-dialog/edit-title-dialog';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../attoms/confirmation-dialog/confirmation-dialog.component';
import { DatasetService } from '../../services/api/dataset.service';
import { DatasetTypes } from '../../models/dataset.model';

@Component({
    selector: 'app-reprocess-action',
    templateUrl: './reprocess-action.component.html',
    styleUrls: ['./reprocess-action.component.scss'],
})
export class ReprocessActionComponent extends BaseController implements OnInit {
    @Input() datasetId: string;
    datasetTypes = DatasetTypes;
    constructor(
        private _datasetFileService: DatasetFileService,
        public emitterService: EmitterService,
        private _router: Router,
        private _snackbar: MatSnackBar,
        private _dialog: MatDialog,
        public datasetService: DatasetService
    ) {
        super();
    }

    async ngOnInit(): Promise<void> {
        this.subscriptions.push(this.emitterService.oDatasetId
            .pipe(debounceTime(1))
            .subscribe(async (value) => {
                this.datasetId = value
                await this.setTotalNeedsReprocessingFilesCount();
            }));
    }

    async setTotalNeedsReprocessingFilesCount() {
        await this.emitterService.getTotalNeedsReprocessingFilesCount()
    }

    async reprocessAllFiles() {
        // const reprocessingCount = await this.emitterService.oReprocessingCount.toPromise();
        const value = await this._dialog.open(ConfirmationDialogComponent, { data: { message: `Are you sure you want to reprocess all files?` } }).afterClosed().toPromise()
        if (!value) return;
        this._datasetFileService
            .reprocessAllFiles(this.datasetId, true)
            .toPromise().catch((err) => {
                this._showMsg("Error reprocessing dataset files", ERROR_SNACKBAR_OPTIONS);
            });
        this.emitterService.startReprocessing();
        this._showMsg(`Dataset files started reprocessing`, SUCCESS_SNACKBAR_OPTIONS);
        this._router.navigate(['datasets', this.datasetId, 'queue',]);
    }

    private _showMsg(msg: string, config: MatSnackBarConfig): void {
        this._snackbar.open(msg, 'close', config);
    }
}
