<div class="home-wrapper">
    <div *ngIf="!toolbarSettings?.showBackButton" class="header">
        <mat-toolbar color="primary">
            <div class="left-item">
                <div class="logo"></div>
                <div class="title-wrapper">
                    <div class="flex gap-2 items-center dataset-title-wrapper">
                        <span class="app-name">{{ toolbarSettings?.title }}</span>
                    </div>

                </div>
                <button mat-flat-button color="primary" *ngIf="datasetService.oChooserDatasetId | async as id" (click)="uploadDialog(id)">
                    <mat-icon class="text-lg">upload</mat-icon> Upload
                </button>
            </div>

            <span class="example-spacer"></span>
            <div class="right-item">
                <!-- Button -->
                <button color="basic" mat-fab class="user-info" [matMenuTriggerFor]="userActions">
                    <mat-icon>more_vert</mat-icon>
                </button>

                <mat-menu [xPosition]="'before'" class="user-actions" #userActions="matMenu">
                    <button mat-menu-item>
                        <span class="flex flex-col leading-none">
                            <span>Signed in as</span>
                            <span class="mt-1.5 text-md font-medium">{{user?.email}}</span>
                        </span>
                    </button>
                    <mat-divider class="my-2"></mat-divider>
                    <button mat-menu-item [routerLink]="['/user-settings', 'profile']">
                        <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
                        <span>Profile</span>
                    </button>
                    <button mat-menu-item [routerLink]="['/user-settings', 'password']">
                        <mat-icon [svgIcon]="'heroicons_outline:lock-closed'"></mat-icon>
                        <span>Password</span>
                    </button>
                    <button mat-menu-item [routerLink]="['/user-settings', 'plans']">
                        <!-- <mat-icon [svgIcon]="'heroicons_outline:banknotes'"></mat-icon> -->
                        <mat-icon>payment</mat-icon>
                        <span>Billing and Plans</span>
                    </button>
                    <!-- <button mat-menu-item>
                        <mat-icon [svgIcon]="'heroicons_outline:table-cells'"></mat-icon>
                        <mat-icon> table_chart</mat-icon>
                        <span>Billing History</span>
                    </button> -->
                    <button mat-menu-item [routerLink]="['/user-settings', 'usage']">
                        <mat-icon [svgIcon]="'heroicons_outline:chart-pie'"></mat-icon>
                        <span>Usage</span>
                    </button>

                    <mat-divider class="my-2"></mat-divider>
                    <button mat-menu-item (click)="signOut()">
                        <mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
                        <span>Sign out</span>
                    </button>
                </mat-menu>

            </div>
        </mat-toolbar>
    </div>

    <div class="main h-full">
        <router-outlet></router-outlet>
    </div>
</div>
