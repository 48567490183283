<div id="visibilityContainer" class="list-button">
    <div class="menu-button" (menuOpened)="onMenuOpened()" (menuClosed)="onMenuClosed()" matTooltip="{{subTitle}}"
        matTooltipClass="tooltip-bg" [matMenuTriggerFor]="Menu" #MenuTrigger="matMenuTrigger">
        <div class="menu-button-content row" *ngIf="!!subTitle else NoSubtitle">
            <div class="title">
                {{title}}
            </div>
            <div class="subtitle">
                <div class="subtitle-content"> {{subTitle}}</div>
            </div>
        </div>
        <ng-template #NoSubtitle>
            <div class="menu-button-content column">
                <div class="single-title">
                    {{title}} <ng-container *ngIf="(info?.length > 0)">({{info?.length}})</ng-container>
                </div>
                <!-- <ng-container *ngIf="!!info">
                    <div *ngIf="(info.length > 0) else NoValue " matTooltip="{{info?.join(', ')}}"
                        class="info-container">
                        <span *ngFor="let inf of info" class="info">{{inf}}</span>
                    </div>
                    <ng-template #NoValue>
                        <span class="empty-info">Show All</span>
                    </ng-template>
                </ng-container> -->
            </div>
        </ng-template>
        <mat-icon class="icon" style="margin-top: -2px; font-size: 12px;">{{MenuTrigger.menuOpen ? 'filter_list' : 'filter_list'}}
        </mat-icon>
    </div>
    <mat-menu #Menu="matMenu" (closed)="cancel.emit(false)">
        <div class="menu-body" (click)="stopPropagation($event)">
            <ng-content></ng-content>
            <div *ngIf="showFooter" class="footer">
                <ng-content select="[extraControl]"></ng-content>
                &nbsp;
                <button class="done-button" mat-flat-button
                    (click)="MenuTrigger.closeMenu(); done.emit(true)">Done</button>
            </div>
        </div>
    </mat-menu>
</div>
