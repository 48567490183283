<div class="wrapper">
    <div class="flex gap-4" (click)="log()">
        <button (click)="saveReport()" [disabled]="(authService.gatewayIdentityUserObservable | async)?.userId != (reportPersist.oLastRequestData | async)?.mainView?.userId">
            <mat-icon>save</mat-icon>
        </button>
        <div class="export-btn-container">
            <button [matMenuTriggerFor]="exportMenuJson"
                *ngIf="!isLoading && tableHeaderConfig?.resultLength <= MAX_EXCEL_SIZE" class="import-doc">
                <mat-icon>save_alt</mat-icon>
                <mat-menu #exportMenuJson="matMenu" class="custom-menu">
                    <button mat-menu-item (click)="exportToJson()">
                        <mat-icon class="small-icon">description</mat-icon>
                        Export to Json
                    </button>
                    <button mat-menu-item (click)="exportTableData(MAX_EXCEL_SIZE)">
                        <mat-icon class="small-icon">insert_drive_file</mat-icon>
                        Export to Excel
                    </button>
                </mat-menu>
            </button>

            <button *ngIf="!isLoading && tableHeaderConfig?.resultLength > MAX_EXCEL_SIZE" mat-flat-button
                [matMenuTriggerFor]="exportMenu" color="primary" class="import-doc">
                <mat-icon>save_alt</mat-icon>
                <mat-spinner diameter="30" *ngIf="isLoading"></mat-spinner>
                <mat-menu #exportMenu="matMenu" class="custom-menu">
                    <button mat-menu-item (click)="exportToJson()">
                        <mat-icon class="small-icon">description</mat-icon>
                        Export to Json
                    </button>
                    <button (click)="exportTableData(MAX_EXCEL_SIZE)"
                        matTooltip="The maximum number of records in Excel is {{MAX_EXCEL_SIZE}}." mat-menu-item>
                        <mat-icon class="small-icon">insert_drive_file</mat-icon>
                        Export to Excel
                    </button>
                    <button mat-menu-item (click)="exportTableData(tableHeaderConfig?.resultLength)">
                        <mat-icon class="small-icon">insert_drive_file</mat-icon>
                        Export to CSV
                    </button>
                </mat-menu>
            </button>

            <export-report #exportReport *ngIf="true" [currentTab]="currentTab" [queryService]="queryService">
            </export-report>


        </div>

    </div>
    <div class="flatten-by-container">
        <mat-label>Show Per</mat-label>
        <mat-form-field *ngIf="columnsToFlattenData.length > 0 ">
            <mat-select #select placeholder="select field" [(ngModel)]="dataFlattenBy" (valueChange)="setFlattenDataBy($event)">
                <!-- <mat-option value="" (click)="setFlattenDataBy('')">Document</mat-option> -->
                <mat-option *ngFor="let col of columnsToFlattenData" [value]="col" matTooltip="{{flattenByDic[col]}}">
                    {{flattenByDic[col]}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-icon *ngIf="dataFlattenBy" class="dataFlattenBy-clear-button" (click)="setFlattenDataBy('')">clear</mat-icon>
    </div>
</div>
