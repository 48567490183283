<mat-form-field class="search-bar">
    <input #searchField [autoFieldFocus]="searchField" [(ngModel)]="searchText" matInput type="text"
        placeholder="Search for template" />
</mat-form-field>

<div class="templates-container">
    <p class="not-found-hint" *ngIf="!datasets.length">No templates found</p>
    <ng-container *ngFor="let dataset of datasets | filter: searchText: 'Title'">
        <div class="template-card flex gap-2" (click)="updateDocumentFields(dataset)">
            <mat-spinner diameter="20" *ngIf="loadingDatasetId === dataset.Id"></mat-spinner>
            <p class="template-name">{{ dataset.Title }}</p>
        </div>
    </ng-container>
</div>
