<div class="create-dataset-wrapper">
    <div class="dialog-header">
        <h1>New {{type === DatasetsTypes.WORKSPACE ? 'Workspace': 'Table'}} </h1>
    </div>

    <div class="main">
        <div class="name-container">
            <mat-form-field appearance="fill">
                <mat-label>Name</mat-label>
                <input matInput placeholder="Name" [formControl]="datasetName" (blur)="updateDescription()"/>
                <mat-error *ngIf="datasetName.invalid">Name is required</mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="main">
        <div class="name-container">
            <mat-form-field appearance="fill">
                <mat-label>Description</mat-label>
                <textarea  matInput placeholder="Description" [formControl]="datasetDescription"></textarea>
                <mat-error *ngIf="datasetDescription.invalid">Description is required</mat-error>
            </mat-form-field>
        </div>
    </div>

    <div class="fotter">
        <button mat-stroked-button (click)="closeDialog()">Cancel</button>
        <button class="create-button" color="primary" mat-stroked-button [disabled]="isCreating"
            (click)="createDataset()">
            <mat-spinner *ngIf="isCreating" diameter="20"></mat-spinner>
            <span>Create</span>
        </button>
    </div>
</div>
