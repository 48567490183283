import { Injectable } from '@angular/core';
import { DatasetService } from '@app/ignite/components/reports/services/api/dataset.service';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, take } from 'rxjs/operators';
import { ISidebarItem } from '../models';

@Injectable({ providedIn: 'root', })
export class VerticalSidebarService {

    private _lastRefreshAt$ = new BehaviorSubject<string>(new Date().toISOString());
    oLastRefreshAt = this._lastRefreshAt$.asObservable();

    public _sQueueLengthChange = new BehaviorSubject<number>(null);
    public oQueueLengthChange = this._sQueueLengthChange.asObservable();
    public _sResultsLengthChange = new BehaviorSubject<number>(null);
    public oResultsLengthChange = this._sResultsLengthChange.asObservable();

    constructor(
        private _datasetService: DatasetService) {
    }

    setLastRefreshAt(value: string) {
        this._lastRefreshAt$.next(value);
    }

    setQueueLength(value: number) {
        this._sQueueLengthChange.next(value);
    }

    setProcessedLength(value: number) {
        this._sResultsLengthChange.next(value);
    }

    async getDatasetCounts(datasetId: string) {
       const response = await this._datasetService.getCounts(datasetId).toPromise();
        if (response.isSuccess) {
            const lastRefreshAt = await this.oLastRefreshAt.pipe(take(1)).toPromise();
            const updatedEntries = response?.result.find(item => new Date(item.updatedAt).toISOString() > lastRefreshAt);
            if (updatedEntries) {
                this._sQueueLengthChange.next(updatedEntries.queueLength);
                this._sResultsLengthChange.next(updatedEntries.resultLength);
            }
            return response?.result;
        } else {
            return [{ datasetId, queueLength: 0, resultLength: 0 }];
       }
    }

    getSidebarItemById(sidebarItemId: string) {
        var item;
        DATASET_SIDEBAR_ITEMS.forEach(item => {
            if (!item?.children && (item.id === sidebarItemId)) item = item;
            if (item?.children) item.children.forEach(child => { if (child.id == sidebarItemId) item = child });
        });
        return item;
    }
}

export const DATASET_SIDEBAR_ITEMS: ISidebarItem[] = [
    {
        id: 'configuration', title: '', icon: null, children: [
            {
                id: 'schema', title: 'Fields',
                icon: 'heroicons_outline:view-columns',
                route: 'schema',
                showDetails: false

            },
            {
                id: 'actions', title: 'Actions',
                icon: 'schema',
                route: 'actions',
                showDetails: false
            },
            { id: 'settings', title: 'Settings', icon: 'settings', route: 'settings', showDetails: false }

        ]
    }, {
        id: 'pipeline', title: 'Tables', icon: null, children: [
            { id: 'queue', title: 'Inbox', icon: 'heroicons_outline:inbox-arrow-down', route: 'queue', rightBadge: 0, isLoading: false, showDetails: false },
            { id: 'records', title: 'Table', icon: 'feather:database', route: 'records', rightBadge: 0, isLoading: false, showDetails: false },
        ]
    }]

export const WORKSPACE_SIDEBAR_ITEMS: ISidebarItem[] = [
    {
        id: 'pipeline', title: '', icon: null, children: [
            { id: 'queue', title: 'Inbox', icon: 'heroicons_outline:inbox-arrow-down', route: 'queue', rightBadge: 0, isLoading: false, showDetails: false },
            { id: 'settings', title: 'Settings', icon: 'settings', route: 'settings', showDetails: false }
        ]

    },
    {
        id: 'datasets', title: 'Tables', icon: null, children: []
    }
]
