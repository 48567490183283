import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, Input, SimpleChanges, OnInit, Output, EventEmitter } from '@angular/core';
import { FileFlatNode, FileNode } from '../../../../../models/common';
import { NodeType } from '../../../../../models/enums';

@Component({
    selector: 'file-tree-viewer',
    templateUrl: './file-tree-viewer.component.html',
    styleUrls: ['./file-tree-viewer.component.scss']
})
export class FileTreeViewerComponent implements OnInit {

    @Input() fileNodes: FileNode[] = [];
    @Output() fileDownload = new EventEmitter<FileNode>();
    public NodeType = NodeType;
    private _transformer = (node: FileNode, level: number): FileFlatNode => {
        return {
            id: node.id,
            expandable: !!node.children && node.children.length > 0,
            name: node.name,
            level: level,
            type: node.type,
            path: node.path,
            extension: node.extension,
            url: node.url
        };
    };
    public fileIconMapextension = new Map<string, string>([
        ['txt', 'txt'],
        ['text', 'txt'],
        ['pdf', 'pdf'],
        ['png', 'image'],
        ['jpg', 'image'],
        ['jpeg', 'image'],
        ['docx', 'doc'],
        ['doc', 'doc'],
        ['xlsx', 'xls'],
        ['xls', 'xls'],
        ['csv', 'csv']
    ]);

    treeControl = new FlatTreeControl<FileFlatNode>(
        node => node.level, node => node.expandable);

    treeFlattener = new MatTreeFlattener(
        this._transformer, node => node.level, node => node.expandable, node => node.children);

    dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    constructor() { }

    ngOnInit() {
        this.dataSource.data = this.fileNodes;
        this.expandFoldersByDefault(this.fileNodes);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['fileNodes']) {
            this.dataSource.data = this.fileNodes;
            this.expandFoldersByDefault(this.fileNodes);
        }
    }

    hasChild = (_: number, node: FileFlatNode) => node.expandable;

    getIcon(node: FileFlatNode): string {
        if (node.type === NodeType.folder) {
            return NodeType.folder;
        }

        const extension = node.extension?.toLowerCase();
        return this.fileIconMapextension.get(extension) || extension;
    }


    downloadFile(fileNode: FileNode): void {
        this.fileDownload.emit(fileNode);
    }

    private expandFoldersByDefault(nodes: FileNode[]) {
        nodes.forEach(node => {
            if (node.type === NodeType.folder) {
                const flatNode = this.treeControl.dataNodes.find(n => n.name === node.name);
                if (flatNode) {
                    this.treeControl.expand(flatNode);
                }
            }
            if (node.children) {
                this.expandFoldersByDefault(node.children); // Recursively expand child folders
            }
        });
    }
}
