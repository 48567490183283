import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { takeWhile } from 'rxjs/operators';
import { DatasetService } from '../../services/api/dataset.service';
import { EmitterService } from '../../services/emitter.service';
import { GeneralSettings, GeneralSettingsService } from '../../services/general-settings.service';
import { ToolbarService } from '../../services/toolbar.service';
import { ConfirmationDialogComponent } from '../attoms/confirmation-dialog/confirmation-dialog.component';
import { ERROR_SNACKBAR_OPTIONS, SUCCESS_SNACKBAR_OPTIONS } from '../home/edit-title-dialog/edit-title-dialog';

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit, OnDestroy {

    isDeleteLoading: boolean;
    isGeneralSettingsLoading: boolean;
    datasetId: string;
    generalSettingsForm: FormGroup;
    generalSettingsData: GeneralSettings;
    loading: boolean;
    _alive: boolean = true;
    constructor(
        private _activated: ActivatedRoute,
        private _datasetService: DatasetService,
        private _snackbar: MatSnackBar,
        private _router: Router,
        private _dialog: MatDialog,
        private _formBuilder: FormBuilder,
        private _emitterService: EmitterService,
        private _toolbarService: ToolbarService,
        private _generalSettingsService: GeneralSettingsService
    ) {

    }
    ngOnDestroy(): void {
        this._alive = false;
    }

    async ngOnInit() {
        this.loading = true;
        this.datasetId = this._activated.snapshot.params['datasetId'];
        this._emitterService.setDatasetId(this.datasetId);
        await this._toolbarService.setSettings(this.datasetId, this._activated.snapshot.routeConfig.data);
        this._generalSettingsService.oGeneralSettings.pipe(takeWhile(() => this._alive)).subscribe((data: GeneralSettings) => {
            if (!!data) {
                this.generalSettingsData = data;
                this._loadGeneralSettingForm(data);
                this.loading = false;
            } else {
                this._prepareGeneralSettingForm();
                this.loading = false;
            }
        });
        if (!this.generalSettingsData) {
            this._prepareGeneralSettingForm();
            this.loading = false;
        }
    }

    async openConfirmDialog() {
        var res = await this._dialog.open(ConfirmationDialogComponent, { width: '420px', data: { message: 'Are you sure you want to delete this Dataset ?' } }).afterClosed().toPromise()
        if (res) await this.deleteDataset();
    }

    async deleteDataset() {
        this.isDeleteLoading = true;
        var deleteRes = await this._datasetService.delete(this.datasetId).toPromise();
        if (!deleteRes?.isSuccess) {
            this._showMsg("Error Deleting Dataset", ERROR_SNACKBAR_OPTIONS);
            this.isDeleteLoading = false;
        }
        else this._showMsg(`Dataset Deleted Successfully`, SUCCESS_SNACKBAR_OPTIONS);
        this.isDeleteLoading = false;
        this._router.navigate(['home']);
    }

    async onGeneralSettingsSubmit() {
        if (this.generalSettingsForm.valid) {
            this.isGeneralSettingsLoading = true;
            var response = await this._datasetService.updateDatasetGeneralSettings(this.datasetId, this.generalSettingsForm.value).toPromise();
            if (!response?.isSuccess) {
                this._showMsg(`Error Deleteing Dataset`, ERROR_SNACKBAR_OPTIONS);
                this.isGeneralSettingsLoading = false;
            } else {
                this._showMsg(`General Settings Updated Successfully`, SUCCESS_SNACKBAR_OPTIONS);
                this._generalSettingsService.refreshGeneralSettings(this.datasetId);
                this.isGeneralSettingsLoading = false;
            }

        }
    }

    private _showMsg(msg: string, config: MatSnackBarConfig) {
        this._snackbar.open(msg, 'close', config);
    }

    private _prepareGeneralSettingForm() {
        this.generalSettingsForm = this._formBuilder.group({
            extract_signatures: [false],   //
            advanced_forms: [false],     // Set default value to false
            advanced_tables: [false],      // Set default value to false
            spreadsheet_mappers: [false]        //
        });
    }

    private _loadGeneralSettingForm(settings: GeneralSettings) {
        this.generalSettingsForm = this._formBuilder.group({
            extract_signatures: [settings.extract_signatures || false],
            advanced_forms: [settings.advanced_forms || false],
            advanced_tables: [settings.advanced_tables || false],
            spreadsheet_mappers: [settings.spreadsheet_mappers || false]
        });
    }
}
