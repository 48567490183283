import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Input, OnInit } from '@angular/core';
import { convertToString } from "@app/ignite/components/reports/helpers/util";
import { ColumnSetting } from '@app/ignite/components/reports/models/column-settings';
import { IColumnSetting } from '@discoverer/core';

export interface ISchema {
    type: string
    description: string
    display: string
    fields?: ISchema
}

@Component({
    selector: 'app-table-object',
    templateUrl: './table-object.component.html',
    styleUrls: ['./table-object.component.scss']
})
export class TableObjectComponent implements OnInit {

    @Input() data: { key: string, value: any, display: string, schema: any }
    @Input() columnSettings: ColumnSetting[]

    isCsvCopied = false;
    displayedColumns: { display: string, fieldName: string }[] = [];

    constructor(
        private clipboard: Clipboard
    ) { }

    ngOnInit() {
        this.displayedColumns = this.getFilteredColumns();
        if (!this.displayedColumns.length) {
            this.displayedColumns = this.data.schema?.map(schem => ({
                display: schem.display,
                fieldName: schem.key
            })) || [];
        }
    }

    private getFilteredColumns(): Array<{ display: string, fieldName: string }> {
        return this.columnSettings
            ?.filter(column => this.isValidColumn(column))
            ?.map(column => this.mapColumn(column));
    }

    private isValidColumn(column: IColumnSetting): boolean {
        return !!column.nestedPath && column.fieldName.includes(`${this.data.key}`);
    }

    private mapColumn(column: IColumnSetting): { display: string, fieldName: string } {
        return {
            display: column.display.split(' - ')[column.display.split(' - ').length - 1],
            fieldName: column.fieldName.split('.')[column.fieldName.split('.').length - 1]
        };
    }

    getDisplay(key: string): string {
        return this.data.schema[key]?.display || key;
    }

    copyAsCsv() {
        const keys = Object.keys(this.data.schema)
        const headers = keys.map(header => convertToString(this.getDisplay(header)))
        const csvContent = [
            headers,
            ...this.data.value.map(row => keys.map(column => convertToString(row[column])))
        ].map(e => e.join(",")).join("\n");
        this.clipboard.copy(csvContent);
        this.isCsvCopied = true;

        setTimeout(() => this.isCsvCopied = false, 4000);
    }
}
