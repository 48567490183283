import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { StatusTypes, TempFile } from '../../models/common';
import { DatasetFileService } from '../../services/api/dataset-file.service';
import { ERROR_SNACKBAR_OPTIONS, LONG_DURATION_ERROR_SNACKBAR_OPTIONS, SUCCESS_SNACKBAR_OPTIONS } from '../home/edit-title-dialog/edit-title-dialog';
import { DatasetService } from '../../services/api/dataset.service';
import { take } from 'rxjs/operators';

@Component({
    selector: 'custom-file-input',
    templateUrl: './custom-file-input.component.html',
    styleUrls: ['./custom-file-input.component.scss']
})
export class CustomFileInputComponent implements OnInit {
    @Input() isTest: boolean = false;
    @Input() datasetId: string;
    @Input() uploadFiles: boolean = true;
    @Input() isMultipleUpload: boolean = false;
    @Input() isUploading: boolean;
    @Input() inputMsg: string
    @Output() backendResult: EventEmitter<string> = new EventEmitter<string>();
    @Output() fileList: EventEmitter<FileList> = new EventEmitter<FileList>();

    tempFiles: TempFile[] = [];
    isDragOver = false;
    selectedFiles: FileList;
    allowedDatasetExtensions: string[] = ['.txt', '.text', '.pdf', '.png', '.jpg', '.jpeg', '.docx', '.doc', '.xlsx', '.xls', '.csv', '.zip', '.eml'];
    allowedChooserExtensions: string[] = ['.txt', '.text', '.pdf', '.png', '.jpg', '.jpeg', '.docx', '.doc'];
    allowedExtensions= []
    constructor(
        private _datasetFileService: DatasetFileService,
        private _datasetService: DatasetService,
        private _snackBar: MatSnackBar,
    ) { }
    async ngOnInit() {
        const chooserDatasetId = await this._datasetService.oChooserDatasetId.pipe(take(1)).toPromise()
        this.allowedExtensions = (this.datasetId === chooserDatasetId)? this.allowedChooserExtensions : this.allowedDatasetExtensions;
    }
    onDragOver(event: DragEvent): void {
        if (this.isUploading) return;
        event.preventDefault();
        this.isDragOver = true;
    }

    onDragLeave(): void {
        if (this.isUploading) return;
        this.isDragOver = false;
    }

    onDrop(event: DragEvent): void {
        if (this.isUploading) return;
        event.preventDefault();
        this.isDragOver = false;
        this.selectedFiles = (event.dataTransfer?.files || []) as FileList;
        this._handleFileUpload();
    }

    onFileSelected(event: Event): void {
        const inputElement = event.target as HTMLInputElement;
        this.selectedFiles = (inputElement.files || []) as FileList;
        this._handleFileUpload();
    }

    private _handleFileUpload() {
        const { invalidFiles, validFiles } = this._validateFileExtensions()
        if (invalidFiles.length) {
            this._showSnackbar(`${invalidFiles.length} file/s were ignored.`, LONG_DURATION_ERROR_SNACKBAR_OPTIONS)
        }
        this.fileList.emit(validFiles);
        if (this.uploadFiles) this.onFileChange(this.selectedFiles);
    }

    private _convertFilesToFileList(files: File[]): FileList {
        const dataTransfer = new DataTransfer(); // Create a new DataTransfer object

        files.forEach(file => {
            dataTransfer.items.add(file); // Add each file
        });

        return dataTransfer.files; // Return the FileList
    }
    private _validateFileExtensions() {
        const validFiles: File[] = [];
        const invalidFiles: File[] = [];

        // Loop through each file in the FileList
        for (let i = 0; i < this.selectedFiles.length; i++) {
            const file = this.selectedFiles[i];
            const fileName = file.name;
            const fileSize = file.size;

            // Check for empty files
            if (fileSize === 0) {
                invalidFiles.push(file);
                console.warn(`File "${fileName}" is empty and will be ignored.`);
                continue;
            }

            // Get the last file extension directly
            const lastDotIndex = fileName.lastIndexOf('.');
            const fileExtension = lastDotIndex > -1 ? fileName.substring(lastDotIndex + 1).toLowerCase() : '';

            // Validate the extension
            if (fileExtension && this.allowedExtensions.includes(`.${fileExtension}`)) {
                validFiles.push(file);
            } else {
                invalidFiles.push(file);
                console.warn(`File "${fileName}" has an invalid extension and will be ignored.`);
            }
        }

        return {
            validFiles: this._convertFilesToFileList(validFiles),
            invalidFiles: this._convertFilesToFileList(invalidFiles)
        };
    }

    public async onFileChange(files: FileList) {
        this.isUploading = true
        for (let i = 0; i < files.length; i++) {
            let file = files[i];
            this.tempFiles.push({
                id: null,
                name: file.name,
                fileId: null,
                status: StatusTypes.PROCESSING,
                url: ''
            });
            let formData = new FormData();
            formData.append(`inputFile`, file);
            formData.append('isTest', String(this.isTest));
            var response = await this._datasetFileService.upload(this.datasetId, formData).toPromise();
            if (response.isSuccess) {
                this.backendResult.emit(response.result);
                this._showSnackbar("File uploaded successfully", SUCCESS_SNACKBAR_OPTIONS);
            } else {
                this._showSnackbar(`Error uploading file ${this.tempFiles[i].name}. Try again`, ERROR_SNACKBAR_OPTIONS);
            }
            this.isUploading = false;
        }
    }

    private _showSnackbar(msg: string, config: MatSnackBarConfig) {
        this._snackBar.open(msg, 'close', config);
    }
}
