<div class="queue-wrapper">
    <div class="main">
        <div class="main-content">
            <disco-tab [appId]="appId" [tabId]="tabId">
                <ng-template let-tabContext>
                    <!-- <textarea rows="20" cols="60">{{tabContext?.currentTab | json}}</textarea> -->
                    <disco-report [queryService]="queryService" *ngIf="tabContext"
                        (context)="setContext($event)" [requestId]="tabContext.currentTab.defaultRequestId">
                        <ng-template let-reportContext>
                            <!-- <textarea rows="20" cols="60">{{reportContext?.currentReport | json}}</textarea>  -->
                            <div class="absolute inset-0 flex flex-col min-w-0 overflow-hidden noselect">
                                <workspace [hideEditColumn]="false" #WORKSPACE
                                    (rowClick)="displayDetailsDialog($event, tabContext.currentTab?.businessKey, WORKSPACE.reportPersist)"
                                    [enableSelect]="enableSelect" [customTemplate]="customTemplate">

                                    <ng-template #customTemplate>
                                        <app-multi-select-handler [queryService]="queryService"  [buissnessKey]="tabContext.currentTab?.businessKey"
                                            [datasetId]="datasetId"
                                            (toggleChanged)="enableSelect = $event"></app-multi-select-handler>
                                    </ng-template>
                                </workspace>
                            </div>
                        </ng-template>
                    </disco-report>
                </ng-template>
            </disco-tab>
        </div>
    </div>
</div>
