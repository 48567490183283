<div class="w-full flex min-h-8 flex-shrink-0 items-center gap-2 border-b-2 py-4 pl-6 pr-4 pl-6">
  <h2 class="flex items-center justify-between text-balance text-lg font-semibold text-nightshade-950">
    Create new connection
  </h2>
  <button class="ml-auto" mat-icon-button (click)="closeDialog()">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="min-h-64 w-full overflow-auto pb-4">
  <div *ngFor="let app of apps"
    class="group flex h-12 w-full cursor-pointer flex-row items-center justify-between gap-2 px-4 py-2" (click)="createConnection(app.app_name)">
    <div class="flex flex-row items-center space-x-3">
      <img class="min-w-8 max-w-8" [src]="app.icon">
      <p class="text-base font-normal" data-slot="text">{{ app.display_name }}</p>
    </div>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentcolor" viewBox="0 0 256 256"
      class="text-nightshade-400 size-4 min-w-4 max-w-4" data-slot="icon">
      <path
        d="M180.24,132.24l-80,80a6,6,0,0,1-8.48-8.48L167.51,128,91.76,52.24a6,6,0,0,1,8.48-8.48l80,80A6,6,0,0,1,180.24,132.24Z">
      </path>
    </svg>
  </div>
</div>