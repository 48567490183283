import { SafeResourceUrl } from "@angular/platform-browser"
import { NodeType } from "./enums"

export enum StatusTypes {
    NEW = 'new',
    PROCESSING = 'processing',
    PROCESSED = 'processed',
    FAILED = 'failed'
}

export interface TempFile {
    id: string
    name: string
    fileId: string,
    status: StatusTypes
    url: string
}


export interface BackendResponse<T> {
    isSuccess: boolean
    result: T
}

export interface FileNode {
    id: string;
    name: string;
    path?: string;
    type: NodeType;
    extension?: string;
    url?: string;
    children?: FileNode[];
}

export interface FileFlatNode {
    id: string;
    expandable: boolean;
    name: string;
    path?: string;
    level: number;
    type: NodeType;
    extension?: string;
    url?: string;
}