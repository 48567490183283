export enum DatasetActionType {
    Webhook = "webhook",
    AiActionField = "aiActionField",
    AiGenerate = "aiGenerate",
    AiAgent = "aiAgent",
}

export enum FileViewerType {
    Standard = 1,
    DownloadedOnly = 2,
    Nested = 3,
    EmailMessage = 4,
    PreviewAndDownload = 5
}

export enum NodeType {
    file = 'file',
    folder = 'folder'

}

export enum FieldCategoryEnum {
    Default = 'default',
    Action = 'action'
}

export enum ParsedTabs {
    AllRecordTabName = 'All Records',
    QueueTabName = 'Queue',
    
}

