<div class="wrapper">
    <div class="header flex justify-between">
        <div class="left-wrapper ">
            <div class="action-info">
                <input [(ngModel)]="actionNameTitle" (ngModelChange)="onActionNameTitleChange($event)"
                    class="editable-title" placeholder="Enter Action Name" />
            </div>
            <div class="action gap-4">
                <button mat-flat-button color="basic" (click)="save()" [disabled]="isFormDataNotValid()">Save</button>
                <button *ngIf="formData.type && formData.type != DatasetActionType.AiAgent" mat-stroked-button
                    color="basic" [disabled]="isFormDataNotValid()" (click)="runTest()">Test Function</button>
            </div>
        </div>
        <div class="close-icon">
            <button mat-icon-button (click)="dialogRef.close()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
    <div class="main">
        <div class="overlay" *ngIf="isLoading">
            <mat-spinner></mat-spinner>
        </div>

        <mat-stepper *ngIf="isFormDataLoaded" #stepper>
            <mat-step label="Step 1" *ngIf="!isEditMode">
                <div class="main flex flex-col gap-4 p-0 h-full">
                    <div class="text-2xl font-medium leading-8">Select Action</div>
                    <div class="flex flex-nowrap overflow-x-auto gap-4 pb-4 hide-scrollbar">
                        <ng-container *ngIf="availableActions?.length > 0">
                            <ng-container *ngFor="let action of availableActions">
                                <mat-card [ngClass]="{'transform-card': !action.disabled, 'disabled': action.disabled}"
                                    class="flex-none relative rounded-2xl shadow-md hover:shadow-lg transition-shadow duration-200"
                                    (click)="selectAction(action.type)">
                                    <div class="flex items-center pt-4 pr-4" [matTooltip]="action.description">
                                        <img [src]="getActionImage(action.type)" alt="Action icon"
                                            class="w-16 object-contain">
                                        <h3 class="ml-2 text-2xl font-medium line-clamp-2">
                                            {{getActionName(action.type)}}
                                        </h3>
                                    </div>
                                    <div class="absolute bottom-4 mt-4 right-4">
                                        <button mat-raised-button color="primary" [disabled]="action.disabled">
                                            Choose
                                        </button>
                                    </div>
                                </mat-card>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
            </mat-step>
            <mat-step label="Step 2" *ngIf="isEditMode || !!formData?.type">
                <div *ngIf="!isEditMode" class="flex gap-4 items-center">
                    <button mat-icon-button matStepperPrevious>
                        <mat-icon>arrow_back</mat-icon>
                    </button>
                    <div class="flex items-center">
                        <img [src]="getActionImage(formData.type)" alt="Action icon" class="w-12 object-contain">
                        <h3 class="ml-2 text-2xl font-medium line-clamp-2">
                            {{getActionName(formData.type)}}
                        </h3>
                    </div>
                </div>
                <div class="flex w-full mt-2">

                    <ng-container [ngSwitch]="formData.type">
                        <ng-container *ngSwitchCase="DatasetActionType.Webhook"
                            [ngTemplateOutlet]="webhookTemplate"></ng-container>
                        <ng-container *ngSwitchCase="DatasetActionType.AiGenerate"
                            [ngTemplateOutlet]="aiGenerateTemplate"></ng-container>
                        <ng-container *ngSwitchCase="DatasetActionType.AiAgent"
                            [ngTemplateOutlet]="aiAgentTemplate"></ng-container>
                    </ng-container>

                    <ng-template #webhookTemplate>
                        <div class="flex flex-col w-full">
                            <app-dyn-form (result)="onSubmit($event)" #dynForm
                                [key]="getActionDynFormKey(formData.type)" [model]="formData" [isEditMode]="true">
                            </app-dyn-form>
                            <ng-container [ngTemplateOutlet]="storeAs"></ng-container>
                        </div>
                    </ng-template>

                    <ng-template #aiGenerateTemplate>
                        <div class="flex flex-col w-full gap-5">
                            <div>
                                <div class="text-base font-medium leading-8">Prompt</div>
                                <app-kendo-editor [menuItems]="promptMenuItems" [value]="promptValue"
                                    (change)="editorChange($event)"></app-kendo-editor>
                            </div>
                            <ng-container [ngTemplateOutlet]="storeAs"></ng-container>
                            <!-- <div class="flex flex-col">
                                <mat-checkbox [(ngModel)]="formData.alwaysRun">Always Run</mat-checkbox>
                                <span class="text-sm text-gray-600">This will make the action runs regardless of the
                                    file or
                                    previous actions statuses</span>
                            </div> -->
                        </div>
                    </ng-template>

                    <ng-template #aiAgentTemplate>
                        <div class="flex flex-col w-full gap-5">
                            <div>
                                <div class="text-base font-medium leading-8">Prompt</div>
                                <app-kendo-editor [menuItems]="promptMenuItems" [value]="promptValue"
                                    (change)="editorChange($event)"></app-kendo-editor>
                            </div>

                            <div>
                                <div class="flex justify-between items-center">
                                    <div class="text-base font-medium leading-8">Tools</div>
                                    <button mat-raised-button color="primary" (click)="openToolSelection()">
                                        <mat-icon>add</mat-icon>
                                    </button>
                                </div>
                                <div class="flex flex-row flex-wrap gap-4 mt-2"
                                    *ngIf="formData?.configuration?.tools?.length > 0">
                                    <div class="tool-card" *ngFor="let tool of formData?.configuration?.tools; let toolIndex = index"
                                        (click)="openToolSelection(tool, toolIndex)">
                                        <div class="flex flex-col">
                                            <div class="flex">
                                                <div class="flex items-start gap-2">
                                                    <img class="min-w-6 max-w-6" [src]="getToolIcon(tool)">
                                                    <div class="flex flex-col gap-1">
                                                        <div class="text-base font-medium">{{tool.display}}</div>
                                                        <div class="flex flex-col">
                                                            <div class="text-sm font-medium text-gray-600">Connection
                                                            </div>
                                                            <div class="flex items-center gap-2">
                                                                <div class="">
                                                                    {{getConnectionById(tool?.configuration?.connection)?.connection_name}}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="remove-tool-container">
                                                    <button class="remove-tool" mat-icon-button color="warn"
                                                        (click)="removeTool(tool)">
                                                        <mat-icon>delete</mat-icon>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <ng-container [ngTemplateOutlet]="storeAs"></ng-container>
                        </div>
                    </ng-template>
                    <ng-template #storeAs>
                        <div class="flex flex-col bg-purple-50 rounded-3xl p-6 border-solid border border-gray-200">
                            <p class="text-gray-500 text-lg flex mb-2 p-2">
                                Store the results of this action in the following fields:
                            </p>
                            <app-schema-fields class="w-full" [isDataLoaded]="isDataLoaded" [useWhiteBackground]="true"
                                [isEditMode]="true" [originalFields]="originalFields" [canDelete]="false"
                                [canAddField]="false" [objectFormGroup]="objectFormGroup" [isNestEdit]="true"
                                (handleChange)="handleItemChange(originalFields)">
                            </app-schema-fields>
                        </div>
                    </ng-template>
                </div>
            </mat-step>
        </mat-stepper>
    </div>
</div>
