import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    ViewEncapsulation
} from '@angular/core';
import {
    ColumnVisibilityChangeEvent,
    PageChangeEvent
} from '@progress/kendo-angular-grid';
import { AggregateDescriptor, GroupDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import {
    AppSettingsService,
    CachedIgniteDataService,
    DiscovererDataService,
    DiscovererFacetTranslationService,
    DiscovererQueryService,
    DRQuery,
    ExportDataTableService,
    Factory,
    IColumnSetting,
    ITableHeader,
    ITableViewState,
    MultiSelectService,
    ReportPersistService,
    TabSettingsService
} from '../services';

import { disLogger, generateHash } from '../services/functions';

import { group } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { process } from '@progress/kendo-data-query';
import { Apollo } from 'apollo-angular';
import { combineLatest, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, first, startWith } from 'rxjs/operators';
import { StandardMapper } from '../data-source/data-source.component';
import { KendoReactWrapperComponent } from '../kendo-react-wrapper/kendo-react-wrapper.component';
import { DataFormattingService } from '../services';
import { LoadingState } from '../services/classes/loading-state';
import { EsQueryService } from '../services/core-data-services/discoverer-esquery-service';
import { TableHeaderService } from '../services/dynamic-reports-services/table-header.service';

const PAGE_SIZE = 40;
const IMAGE_LOAD_COUNT = 20;

declare var $: any;

@Component({
    selector: 'kendo-table-view',
    templateUrl: './kendo-table-view.component.html',
    styleUrls: ['./kendo-table-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class KendoTableViewComponent extends KendoReactWrapperComponent implements OnInit, OnDestroy, OnChanges {
    filters: any;
    sortChanged: boolean = false;
    reRenderGrid: boolean = false;

    @Input()
    set height(value) {
        this.state.style.height = value;
    }
    @Input()
    public hideEditColumn: boolean = false;

    @Input()
    set isPreview(value) {
        this._isPreview = value;
    }

    @Input() stateChanged?: boolean;

    @Input() enableMultiSelect: boolean = false;
    @Input() public tableHeaderService: TableHeaderService;

    @Output()
    public tableBusyEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output()
    public cellClickedEvent: EventEmitter<any> = new EventEmitter<any>();
    @Output()
    public multipleCellsEvent: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    public gridQueryServiceEvent: EventEmitter<DiscovererQueryService> = new EventEmitter<DiscovererQueryService>();
    @Output() public numberOfRecordsEvent?: EventEmitter<number> = new EventEmitter<number>();

    @Input() isEditMode = true;

    public mainQueryService: DiscovererQueryService;

    public gridQueryService: DiscovererQueryService;

    private pageSizeVirtual = PAGE_SIZE;
    private pageSize = PAGE_SIZE;

    private skip = 0;
    private sorts: SortDescriptor[] = [];

    private cachedPaginationDataService: CachedIgniteDataService<any>;

    private $pageChanges = new Subject<PageChangeEvent>();
    private _isPreview = false;

    public loadingState: LoadingState;
    //public isInitial: boolean = true;

    private _currentQuery: DRQuery;
    private _searchUrl = '';
    private _tabName = '';
    private _tabKey = '';

    // To handle horizontal scroll
    private previousScrollTop = 0;

    constructor(
        private _tabSettings: TabSettingsService,

        public reportPersist: ReportPersistService,
        http: HttpClient,
        tabSettings: TabSettingsService,
        appSettings: AppSettingsService,
        dataFormatterService: DataFormattingService,
        router: Router,
        private exportSevice: ExportDataTableService,
        private _cdRef: ChangeDetectorRef,
        private esQueryService: EsQueryService,
        public multiSelecteService: MultiSelectService,
        public facetTranslationService: DiscovererFacetTranslationService,
        public apollo: Apollo,

    ) {
        super(http, tabSettings, appSettings, router, dataFormatterService, multiSelecteService, facetTranslationService, apollo);
    }
    ngOnInit() {
        super.ngOnInit();
        this.subscribeResetGrid();
    }
    public async exportToExcel(ReqId?): Promise<void> {
        // let response;
        // const request = ReqId || this.reportPersist.loadReqId;
        // this._currentQuery.size = this.state?.total || 100000;
        // this._currentQuery.start = this._currentQuery?.start < 0 ? 0 : this._currentQuery.start;
        // this._currentQuery.fields = (await this.reportPersist.getFieldNamesFromConfig(true)).fields;
        // this.exportSevice.ExportToExcel(`${this._searchUrl}?requestId=${request}&outputType=excel`, this._currentQuery).then(x => {
        //     response = x;
        //     const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        //     const url = window.URL.createObjectURL(blob);
        //     const anchor = document.createElement('a');
        //     anchor.href = url;
        //     anchor.download = this.getReportTitle();
        //     anchor.click();
        // }).catch(x => {

        // });
    }
    private getReportTitle(): string {
        const date = new Date().toLocaleDateString().replace('/', '_').replace('/', '_');
        const time = `${new Date().getHours()}_${new Date().getMinutes()}`;
        const reportTitle = `${this._tabName} Report - ${date} ${time}`;
        return reportTitle;
    }
    ngOnDestroy() {
        super.ngOnDestroy();
        if (this.cachedPaginationDataService) {
            this.cachedPaginationDataService.ngOnDestroy();
        }
    }

    private subscribeToTableConfig() {
        this.subscriptions.push(combineLatest([this.reportPersist.tableViewState.oState, this.reportPersist.mainViewState.oState,
        this.reportPersist.tableViewState.oRequiredReloadData.pipe(startWith(true))])
            .subscribe(async ([tableViewState, mainViewState, requiredReloadData]) => {

                if (!!mainViewState && tableViewState?.columnSettings?.length > 0 && !!this.cachedPaginationDataService) {
                    await this.ensureColumnsAreLoaded(tableViewState, requiredReloadData);
                }

            }));
    }

    private subscribeToQueryChanges() {
        var groupSorts: SortDescriptor[] = [];
        var groupFields: Set<string> = new Set();


        this.subscriptions.push(this.mainQueryService.oQuery.subscribe(query => {
            this._currentQuery = query;
            const hasGroup = query?.groups?.length > 0 || this?.state?.group?.length;

            if (this._isPreview && this.mainQueryService.numberOfRecords) {
                this.setPreviewMode();
            }
            else if (hasGroup) {
                var groups = query?.groups?.length > 0 ? query.groups : [];
                this.setGroups(groups);
                groupSorts = this.getGroupSort(query, groupFields);
            }

            //Handel Sort
            const querySorts = this.getOriginalSort(query, groupFields);
            this.sorts = [...groupSorts, ...querySorts];
            this.state.sort = this.sorts;

            if (this.mainQueryService.hasAnyStateChanged() || this.mainQueryService.defaultService().hasAnyStateChanged()) { // reset cache if query has changed
                this.state.totalIsComplete = false; // reset totalIsComplete
                this.cachedPaginationDataService.resetCache();
                this.state.skip = this.skip = 0;
                this.$pageChanges.next({ skip: this.skip, take: this.pageSize });
                this.cachedPaginationDataService.refresh();
            }
        }));
    }
    private getOriginalSort(query: any, groupFields: Set<string>): SortDescriptor[] {
        return query?.sorts
            ?.filter(sort => !groupFields.has(sort.sortField))
            ?.map(sort => ({ field: sort.sortField, dir: sort.dir })) || [];
    }
    private getGroupSort(query: any, groupFields: Set<string>): SortDescriptor[] {
        return query?.groups?.map(group => {
            groupFields.add(group.field);
            return { field: group.field, dir: group.dir || 'asc' };
        }) || [];
    }
    private async subscribeResetGrid() {
        const currentTab = await this.tabSettings.getCurrentTab();
        this._searchUrl = currentTab.serviceUrl;
        this._tabName = currentTab.name
        this._tabKey = currentTab.key;
        this.multiSelecteService.setTabBusinessKey(currentTab.businessKey);
        const mainView = await this.reportPersist.mainViewState.oState.pipe(first()).toPromise();

        disLogger("Report:", mainView.mainTitle);
        this.mainQueryService = this.reportPersist.mainQueryService;

        this.gridQueryService = this.mainQueryService.createChildService('grid');

        this.subscriptions.push(this.gridQueryService.updateSubscription);


        disLogger('isReady !!!!', currentTab);
        disLogger(`kendo tab key ${currentTab.key}, view type ${mainView.type}`);
        this.pageSizeVirtual = (!!currentTab.pageSize) ? currentTab.pageSize : PAGE_SIZE;
        this.pageSize = this.mainQueryService.groups && this.mainQueryService.groups.length ? 2000 : this.pageSizeVirtual;
        this.state.take = this.pageSize;

        if (this._isPreview && this.mainQueryService.numberOfRecords) {
            this.setPreviewMode();
        }
        this.columnSettings = await this.tabSettings.oDefaultColumns.pipe(first()).toPromise();
        this.createDataService(new StandardMapper(this.columnSettings));

        this.cachedPaginationDataService.init(currentTab.serviceUrl, this.gridQueryService, `grid-${currentTab.key}`);
        this.gridQueryServiceEvent.emit(this.cachedPaginationDataService.dataService.baseQueryService);

        this.subscriptions.push(this.cachedPaginationDataService.oLoadingStatusResult.subscribe(s => {
            this.loadingState = s;
            //if (s.status == "Success" || s.status == "Failure")
            //this.isInitial = false;
            this._cdRef.markForCheck();
        }));

        this.skip = (!this.skip) ? 0 : this.skip;
        this.state.skip = this.skip;

        this.cachedPaginationDataService.setPageParams((this.skip === 0) ? 1 :
            Math.floor(this.skip / this.pageSize) + 1, this.pageSize);
        this.enableDataService();

        this.subscribeToData();
        this.subscribeToQueryChanges();
        this.subscribeToTableConfig();

        this.subscriptions.push(this.$pageChanges.pipe(distinctUntilChanged((a, b) => a.skip == b.skip)).subscribe(event => {
            if (!!this.cachedPaginationDataService) {
                const size = Math.min(event.skip + event.take, this._originalTotal);
                this.setBusyAndRender(true);
                this.cachedPaginationDataService.setPageStart(event.skip, this.pageSize);
                this.cachedPaginationDataService.refresh();

            }
        }));

    }

    private setPreviewMode() {
        this.state.scrollable = 'scrollable';
        // this.pageSize = this.state.take = this.state.total = this.mainQueryService.numberOfRecords;
        this.state.skip = this.skip = 0;
    }

    private subscribeToData() {

        this.subscriptions.push(
            this.cachedPaginationDataService.oData.pipe(debounceTime(0)).subscribe(async (data) => {
                if (!!data) {
                    // let length =  await this.cachedPaginationDataService.oResultLength.pipe(first()).toPromise();

                    //const total = (this._isPreview && this.mainQueryService.numberOfRecords) ? this.mainQueryService.numberOfRecords : length ;
                    console.log('**************************************data', data);
                    this.state.originalData = data;
                    this.state.data = this.processData(data, this.state.group);

                    this.sortChanged = false;
                    this.reRenderGrid = false;
                    disLogger('grid state==============================', this.state);
                    this.setBusyAndRender(false, true);
                }
            }));

        this.subscriptions.push(
            this.cachedPaginationDataService.oResultLength.subscribe(async (count) => {
                this.numberOfRecordsEvent.next(Math.abs(count));
                this._originalTotal = this._originalTotal === (Math.abs(count) as number) ? (Math.abs(count) as number) : (Math.abs(count) as number) + 1;
                this.state.total = this.total;
                if (!this.state.totalIsComplete) this.state.totalIsComplete = count >= 0 ? true : false; // Lock the total if it is completed until query changes
                this.emitHeaderData(Math.abs(count), this.state.totalIsComplete);

            })
        )
    }

    private filtersChangedCheck(): boolean {
        if (this.mainQueryService.filters != null && JSON.stringify(this.filters) !== JSON.stringify(this.mainQueryService.filters)) {
            this.filters = this.mainQueryService.filters;
            return true;
        }
        return false;
    }

    private processData(data: any[], groups: GroupDescriptor[]): any[] {
        if (groups && group.length) {
            const aggregates = this.columnSettings.filter(x => x.type === 'numeric').map(x => {
                return {
                    field: x.fieldName,
                    aggregate: 'sum'
                } as AggregateDescriptor;
            });
            groups.forEach(group => group.aggregates = aggregates);
            return process(data, { group: groups }).data;
        }
        return data;
    }

    public async ensureColumnsAreLoaded(tableViewState: ITableViewState, requiredReloadData: boolean = true) {
        const result = await this.reportPersist.getFieldNamesFromConfig(this.hideEditColumn == true);
        this.columnSettings = result.columnSettings;
        this._setGridFields(result.fields);
        this._setGroupsByTableState(tableViewState);
        this._setDataFlattenByTableState(tableViewState);
        this._setSortsByTableState(tableViewState);
        await this.RandomizeByTableState(tableViewState);
        this.showGroupFooter = tableViewState.showGroupFooter;

        if (requiredReloadData)
            this.mainQueryService.refresh();
    }

    private _setGroupsByTableState(tableViewState: ITableViewState) {
        var isChange = false;
        const currentGroups = this.cachedPaginationDataService.dataService.groups;
        if (generateHash(currentGroups) !== generateHash(tableViewState.groups ? tableViewState.groups : [])) {
            disLogger('grid request setGroups');
            isChange = true;
            this.mainQueryService.setGroups(tableViewState.groups);
        } else {
            this.mainQueryService.resetGroupBy()
        }
        return isChange;
    }
    private _setDataFlattenByTableState(tableViewState: ITableViewState) {
        var isChange = false;
        if (this.mainQueryService.dataFlattenBy !== tableViewState.dataFlattenBy) {
            disLogger('grid request setDataFlatenBy');
            this.mainQueryService.setDataFlattenBy(tableViewState.dataFlattenBy);
            this.cachedPaginationDataService.dataService.setDataFlattenBy(tableViewState.dataFlattenBy);
            this.cachedPaginationDataService.dataService.baseQueryService.setDataFlattenBy(tableViewState.dataFlattenBy);
            isChange = true;
        }
        return isChange;
    }

    private async RandomizeByTableState(tableViewState: ITableViewState) {
        var isChange = false;
        if (this.mainQueryService.randomSeed !== tableViewState.randomSeed) {
            isChange = true;
            this.mainQueryService.setRandomSeed(tableViewState.randomSeed);
            this.cachedPaginationDataService.dataService.setRandomSeed(tableViewState.randomSeed);
            this.cachedPaginationDataService.dataService.baseQueryService.setRandomSeed(tableViewState.randomSeed);
            if (tableViewState.randomSeed) {
                this.mainQueryService.resetOrderBy();
                this.cachedPaginationDataService.dataService.resetOrderBy();
                this.cachedPaginationDataService.dataService.baseQueryService.resetOrderBy();
            }

        }
        return isChange;
    }

    private _setSortsByTableState(tableViewState: ITableViewState) {
        const currentSorts = this.sorts;
        var isChange = false;
        if (tableViewState.sorts) {
            if (generateHash(currentSorts) !== generateHash(tableViewState.sorts)) {
                disLogger('grid request setSorts');
                isChange = true;
                this.mainQueryService.resetOrderBy();
                this.cachedPaginationDataService.dataService.resetOrderBy();
                this.cachedPaginationDataService.dataService.baseQueryService.resetOrderBy();
                currentSorts.forEach(({ field, dir }) => this.mainQueryService.setOrderBy(field, dir));
                this.state.sort = this.sorts;
            }
        }
        return isChange;
    }
    private createDataService(dataMapper: Factory<any>) {
        let dataService = new DiscovererDataService(this.http, this.esQueryService, dataMapper);
        dataService.filterRequests = true;
        dataService.enabled = false;

        this.cachedPaginationDataService = new CachedIgniteDataService(dataService);
        this.dataServices.push(dataService);
    }

    private _setGridFields(fieldNames: string[]) {
        var isChange = false;
        const currentGridFields = this.cachedPaginationDataService.dataService.fields;
        if (generateHash(currentGridFields) !== generateHash(fieldNames)) {
            disLogger('grid request setFields');
            isChange = true;
            this.cachedPaginationDataService.dataService.setFields(fieldNames);
        }
        return isChange;
    }




    public identifyColumn(i: number, item: IColumnSetting) {
        return item.fieldName;
    }

    public async pageChange(event: PageChangeEvent): Promise<void> {
        if (this.skip != event.skip) {
            this.skip = event.skip;
            this.state.skip = this.skip;

            this.$pageChanges.next(event);
            this.emitHeaderData(this._originalTotal - 1, this.state.totalIsComplete);
        }
    }


    private setBusyAndRender(isBusy: boolean, render: boolean = false) {
        this.isBusy = isBusy;
        this.tableBusyEvent.emit(isBusy);

        if (render)
            this.render(this.enableMultiSelect);

    }

    public columnVisibilityChange(event: ColumnVisibilityChangeEvent): void {

        const lookup = {};
        event.columns.forEach(column => lookup[column['field']] = (column['hidden'] ? 'hidden' : 'visible'));
        const copy = this.columnSettings.slice();
        copy.forEach(r => {
            if (!!lookup[r.fieldName]) {
                r.hidden = lookup[r.fieldName] === 'hidden';
            }
        });
        this.reportPersist.tableViewState.setValue({ columnSettings: copy });
    }

    public sortChange(sort: SortDescriptor[]): void {
        this.mainQueryService.resetOrderBy();
        this.cachedPaginationDataService.dataService.resetOrderBy();
        this.cachedPaginationDataService.dataService.baseQueryService.resetOrderBy();
        sort.forEach(sortItem => {
            this.mainQueryService.setOrderBy(sortItem.field, sortItem.dir);
            this.cachedPaginationDataService.dataService.setOrderBy(sortItem.field, sortItem.dir);
            this.cachedPaginationDataService.dataService.baseQueryService.setOrderBy(sortItem.field, sortItem.dir);

        });

        this.sortChanged = true;
        this.reportPersist.tableViewState.setValue({ randomSeed: 0 });
        this.reportPersist.tableViewState.setValue({ sorts: sort });
        this.reportPersist.mainViewState.setValue({ isDefaultSorts: false });
        this.setBusyAndRender(true);
        this.mainQueryService.refresh();
        this.cachedPaginationDataService.dataService.refresh();
        this.cachedPaginationDataService.dataService.baseQueryService.refresh()
    }

    public async columnStateChange(reorderColumns: any, requiredReloadData: boolean = true) {

        const updatedColumnSettings = [];

        this.columnSettings.forEach((c) => {
            const copyC = Object.assign({}, c);
            const tc = reorderColumns.find(f => {
                return f.field !== '' && f.field === copyC.fieldName;
            });
            if (!!tc) {
                copyC.order = tc.orderIndex;
                copyC.width = tc.width;
                updatedColumnSettings.push(copyC);
            }
        });

        updatedColumnSettings.forEach((c, i) => c.displayOrder = i);
        this.reportPersist.tableViewState.setValue({ columnSettings: updatedColumnSettings }, false, requiredReloadData);
        this.reportPersist.mainViewState.setValue({ isDefaultColumns: false });

        if (requiredReloadData) {
            this.mainQueryService.refresh();
        }
    }

    public cellClicked(rowIndex: number, currentObject: any) { // CellClickEvent
        disLogger('CellClicked Override, rowIndex: ', rowIndex, ' skip: ', this.skip, ' pageSize: ', this.pageSize);
        // if (this.isBusy) {
        //   return;
        // }
        disLogger('CellClicked ROW NUMBER', rowIndex);

        this.cellClickedEvent.emit({ rowIndex, currentObject, skip: this.skip });
    }

    public cellChecked(objectChecked: any) {
        if (!!objectChecked) {
            this.multiSelecteService.setItemsSelected(objectChecked, this._tabKey);
        }
    }

    public multipleSelectHeaderCellChecker() {
        this.multiSelecteService.setListOfItems(this.state.data.slice(0, this.pageSize + this.skip))
        this.render(this.enableMultiSelect);
    }

    public scrollChange(event) {
        let e = event.nativeEvent;

        // Skip if the scroll was only horizontal
        if (e.target.scrollTop == this.previousScrollTop) {
            return;
        } else {
            this.previousScrollTop = e.target.scrollTop;
        }


        let skip = Math.min(Math.floor(e.target.scrollTop / this.state.rowHeight), this._originalTotal);
        this.skip = skip;
        this.state.skip = this.skip;
        console.log('skip', skip);

        // let visibleRows = Math.floor(e.target.clientHeight / this.state.rowHeight);

        // if ((this.state.total - this.skip) > visibleRows) {



        if ((e.target.scrollTop) >= (e.target.scrollHeight - e.target.clientHeight - 100) && this.state.group.length == 0) {
            this.$pageChanges.next({ skip: this.state.data.length, take: this.pageSize });

        } else {
            this.emitHeaderData(this._originalTotal - 1, this.state.totalIsComplete);

        }

    }

    private setGroups(groups: GroupDescriptor[]) {

        this.state.group = groups;
        this.state.groupable = {
            footer: (groups?.length > 0 && this.showGroupFooter) ? 'visible' : 'none',
            enabled: groups?.length > 0
        };
        this.pageSize = this.state.take = this.pageSize = this.state.total = this.pageSizeVirtual;
        this.state.skip = this.skip = 0;
        this.$pageChanges.next({ skip: 0, take: this.pageSize });
    }

    private fixNoElements(dataLen: number, pageSize: number) {
        const nodeParent = document.querySelectorAll('[kendogridtablebody]')[0];
        if (!nodeParent) {
            return;
        }
        for (let i = 0; i < pageSize; i++) {
            const child = nodeParent.children[i];
            if (child && child.classList) {
                if (dataLen <= i) {
                    child.classList.add('hide');
                } else {
                    child.classList.remove('hide');
                }
            }
        }
    }

    private enableDataService() {
        this.cachedPaginationDataService.dataService.enabled = true;
        this.cachedPaginationDataService.dataService.refresh();
    }

    private emitHeaderData(resultLength: number, lengthIsComplete: boolean) {
        const tHeader: ITableHeader = {
            pageSize: this.pageSize,
            skip: (!!this.skip) ? this.skip : 0,
            resultLength,
            lengthIsComplete
        };
        this.tableHeaderService?.tableHeader?.next(tHeader);
    }

    public groupChange(groups: GroupDescriptor[]) {
        this.setBusyAndRender(true);
        if (groups && groups.length) {
            disLogger('grid request setGroups');
            groups = groups.filter(g => this.state.group.some(e => e.field === g.field));
            this.mainQueryService.setGroups(groups);
        } else {
            this.mainQueryService.resetGroupBy();
        }
        this.reportPersist.tableViewState.setValue({ groups });
        this.mainQueryService.refresh();
    }

    public expandChange(event) {
        // if (event.dataItem) {
        //   event.dataItem[event.target.props.expandField] = event.value;
        //   this.state.data = Object.assign({}, this.state.data);
        //   this.render();
    }

    async ngOnChanges() {
        this.setBusyAndRender(false, true);
        this.reRenderGrid = true;
    }


}



