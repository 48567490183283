import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterConnectionsByKey'
})
export class FilterConnectionsPipe implements PipeTransform {
    transform(connections: any[], key: string): any[] {
        if (!connections || !key) return connections || [];

        // Filter and map the connections based on your requirements
        return connections.filter(connection => connection?.app_name === key);
    }
}
