<div class="dialog-container">
    <div class="title pb-4" >
        <span>
            Edit Dataset Title
        </span>
    </div>
    <div>
        <mat-form-field class="w-full">
            <input matInput placeholder="Name" [(ngModel)]="data.templateName" (blur)="updateDescription()"/>
        </mat-form-field>
        <mat-form-field class="w-full">
            <input matInput placeholder="Description" [(ngModel)]="data.description"/>
        </mat-form-field>

    </div>
    <div class="buttons-row flex justify-end gap-2">
        <button mat-raised-button color="primary" class="round-button" [disabled]="!data.templateName" (click)="save()">
            Save
        </button>
        <button mat-raised-button class="round-button" (click)="close()">
            Cancel
        </button>
    </div>
</div>
