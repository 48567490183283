import { Component, Inject,OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BaseController, DRFacetOption, DRFilter, DiscovererQueryService, FacetValue, ReportPersistService, TabSettingsService } from '@discoverer/core';
import { environment } from '@env/environment';
import moment from 'moment';
import { debounceTime, filter } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { EmitterService } from '../../services/emitter.service';
import { ToolbarService } from '../../services/toolbar.service';
import { VerticalSidebarService } from '../ai-layout/vertical-sidebar/services/vertical-sidebar.service';
import { DiscoReportContext } from '@discoverer/dynamic-reports';
import { Subscription } from 'rxjs';


@Component({
    selector: 'pipeline-queue',
    templateUrl: './queue.component.html',
    styleUrls: ['./queue.component.scss', '../../../../../../../src/styles/ai-shared-styles.scss'],
})
export class QueueComponent extends BaseController implements OnInit {

    datasetId: any;
    showUploader = false
    tabId: string = 'queue';
    appId: string = environment.auth.productKey;
    sub: Subscription;

    constructor(
        public queryService: DiscovererQueryService,
        private _activated: ActivatedRoute,
        private _toolbarService: ToolbarService,
        private _router: Router,
        private _emitterService: EmitterService,
        private _tabSettingsSer: TabSettingsService,
        private _sidebarService: VerticalSidebarService
    ) {
        super();
        this.subscriptions.push(this._activated.queryParams.pipe(filter(params => !!params?.refresh)).subscribe(params => {
            this._router.navigate([], { queryParams: { refresh: null }, queryParamsHandling: 'merge' })
        }));
        this.subscriptions.push(this._sidebarService.oQueueLengthChange.pipe(debounceTime(500)).subscribe(() => {
            this.queryService.refresh();
        }));
        this.subscriptions.push(this._router.events
            .pipe(filter(event => event instanceof NavigationEnd))  // Filter only NavigationEnd events
            .subscribe(async (event: NavigationEnd) => {
                this._activate()
                console.log('Route changed to:', event.urlAfterRedirects);
            }));
    }
    async _activate() {
        this.datasetId = this._activated.snapshot.params['datasetId'];
        this._tabSettingsSer.setCurrentTab(this.datasetId);
        this._emitterService.setDatasetId(this.datasetId);
        await this._toolbarService.setSettings(this.datasetId, this._activated.snapshot.routeConfig.data)
        this.queryService.resetFilters()
        this.queryService.resetDataFlattenBy();
        this.queryService.setFilter("DatasetKey",
            new DRFilter(
                'facet',
                ['DatasetKey'],
                [new FacetValue(new DRFacetOption(this.datasetId, this.datasetId, this.datasetId))],
                [],
                'All',
                true
            )
        );
        this.queryService.refresh();

    }
    async ngOnInit(): Promise<void> {
        await this._activate();
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
        super.ngOnDestroy();
    }

    async displayDetailsDialog(data: any, businessKey: string, workSpaceReport: ReportPersistService) {
        const fileId = data?.currentObject[businessKey];
        const currentReqId = uuidv4()
        const requestCopy = workSpaceReport.oLastRequestData.getValue();
        requestCopy.mainView.dirId = null;
        requestCopy.query.filters.push(this._currentTimeFilter);
        await workSpaceReport.updateRequest(currentReqId, requestCopy, true, false);
        const queryParams = { currentReqId, start: data?.rowIndex + data.skip };
        this._router.navigate([fileId], {
            queryParams,
            relativeTo: this._activated,
            queryParamsHandling: 'merge',
            state: {
                pipelineid: this.datasetId
            }
        });
    }

    setContext(context: DiscoReportContext) {
        if (this.sub) {
            this.sub.unsubscribe();
        }
        this.sub = context.reportPersistService.mainQueryService.oQuery.pipe(debounceTime(200)).subscribe(query => {
            this._sidebarService.setLastRefreshAt(new Date().toISOString());
        });
    }

    private get _currentTimeFilter() {
        const val = "[* TO " + moment().utc().format('YYYY-MM-DDTHH:mm:ss[Z]') + "]";
        const facetValue = new FacetValue(new DRFacetOption(val, val, ''));
        return new DRFilter('facet', ['CreatedAt'], [facetValue])
    }
}
