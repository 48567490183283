import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BaseController, DiscovererQueryService, MultiSelectService } from '@discoverer/core';
import { DatasetTypes } from '../../../models/dataset.model';
import { MultiFileDeletionDto } from '../../../models/datasetFile.models';
import { DatasetFileService } from '../../../services/api/dataset-file.service';
import { DatasetService } from '../../../services/api/dataset.service';
import { ConfirmationDialogComponent } from '../../attoms/confirmation-dialog/confirmation-dialog.component';
import { ERROR_SNACKBAR_OPTIONS, SUCCESS_SNACKBAR_OPTIONS } from '../../home/edit-title-dialog/edit-title-dialog';
import { ActionModel } from '../../../models/action.model';
import { ActionService } from '../../../services/api/action.service';
import { FileWithResultIndex } from "../../../models/dtos/file-with-result-index.dto";
import { RunSequentialActionsDto } from '../../../models/dtos/run-sequential-action-dto';

@Component({
    selector: 'app-multi-select-handler',
    templateUrl: './multi-select-handler.component.html',
    styleUrls: ['./multi-select-handler.component.scss']
})
export class MultiSelectHandlerComponent extends BaseController implements OnInit, OnDestroy {
    enableSelect: boolean = false
    @Input() businessKey: string = 'Id';
    @Input() datasetId: string;
    @Output() toggleChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() queryService: DiscovererQueryService
    actionsList: ActionModel[] = []
    datasetTypes = DatasetTypes;
    constructor(
        public multiSelectService: MultiSelectService,
        private _datasetFileService: DatasetFileService,
        private _snackBar: MatSnackBar,
        private _dialog: MatDialog,
        public _datasetService: DatasetService,
        private _actionService: ActionService
    ) { super(); }

    async ngOnInit(): Promise<void> {
        this.actionsList = (await this._actionService.getAll(this.datasetId).toPromise()).result;
    }

    public ngOnDestroy(): void {
        this.multiSelectService.markListEmpty();
        super.ngOnDestroy();
    }
    public async rerunActionForSelectedItems(action: ActionModel): Promise<void> {

        const confirmed = await this._confirmRerunAction(action);

        if (!confirmed) return;

        const selectedFiles = this._getSelectedFilesWithResult()

        await this._executeRerunAction(action, selectedFiles);
    }
    public async reprocessSelectedItems(): Promise<void> {
        const selectedFiles = this._getSelectedFilesForReprocess();

        if (!selectedFiles.length) {
            this._showErrorMessage('No files selected');
            return;
        }

        if (!await this._confirmReprocess(selectedFiles.length))
            return;


        await this._executeReprocess(selectedFiles);
    }
    public async deleteSelectedItems(): Promise<void> {

        const selectedFiles = this._getSelectedFilesWithResult();

        if (!selectedFiles.length) {
            this._showErrorMessage('No files selected');
            return;
        }

        if (!await this._confirmDeletion(selectedFiles.length))
            return;


        await this._executeFileDeletion(selectedFiles);
    }

    private async _confirmDeletion(fileCount: number): Promise<boolean> {
        const message = `Are you sure you want to delete (${fileCount}) files?`;
        const note = `P.S:- This will delete all selected files (${fileCount}) and can't be undone!!`;
        return await this._confirm(message, note);
    }

    private async _executeFileDeletion(selectedFiles: MultiFileDeletionDto[]): Promise<void> {
        const { isSuccess } = await this._datasetFileService
            .deleteRange(this.datasetId, selectedFiles)
            .toPromise();

        if (isSuccess) {
            this.multiSelectService.markListEmpty();
            this._showSuccessMessage(`(${selectedFiles.length}) files were deleted!`);
        } else {
            this._showErrorMessage('Error deleting files');
        }

        this.queryService.refresh();
    }
    private _getSelectedFilesForReprocess(): string[] {
        const selectedFiles = this._getSelectedFilesWithResult();
        return [...new Set(selectedFiles.map(x => x.fileId))];
    }

    private async _confirmReprocess(fileCount: number): Promise<boolean> {
        return await this._confirm(`Are you sure you want to reprocess (${fileCount}) files?`);
    }

    private async _executeReprocess(selectedFiles: string[]): Promise<void> {
        const { isSuccess } = await this._datasetFileService
            .reprocessMultipleFiles(this.datasetId, selectedFiles)
            .toPromise();

        if (isSuccess) {
            this.multiSelectService.markListEmpty();
            this._showSuccessMessage(`(${selectedFiles.length}) sent to reprocess!`);
        } else {
            this._showErrorMessage('Error reprocessing files');
        }

        this.queryService.refresh();
    }

    private _showErrorMessage(message: string): void {
        this._snackBar.open(message, null, ERROR_SNACKBAR_OPTIONS);
    }

    private _showSuccessMessage(message: string): void {
        this._snackBar.open(message, null, SUCCESS_SNACKBAR_OPTIONS);
    }
    private _confirm(message, note = null) {

        return this._dialog.open(ConfirmationDialogComponent, { data: { message, note } })
            .afterClosed().toPromise();
    }
    private async _confirmRerunAction(action: ActionModel): Promise<boolean> {
        const actionsToRun = this._getSubsequentActions(action);
        const message = `This will rerun the actions (${actionsToRun.join(', ')}) on the selected files. Are you sure you want to proceed?`;
        return await this._confirm(message);
    }
    private _getSubsequentActions(action: ActionModel): string[] {
        const actionIndex = this.actionsList.findIndex(a => a.id === action.id);
        return this.actionsList.slice(actionIndex).map(x => x.name);
    }
    private async _executeRerunAction(action: ActionModel, selectedFiles: FileWithResultIndex[]): Promise<boolean> {

        const request: RunSequentialActionsDto = { selectedFiles };

        const { isSuccess } = await this._actionService.runSequential(this.datasetId, action.id, request).toPromise();

        if (isSuccess)
            this._snackBar.open(`Actions started on (${selectedFiles.length}) files!`, null, SUCCESS_SNACKBAR_OPTIONS);
        else
            this._snackBar.open('Error Rerunning actions', null, ERROR_SNACKBAR_OPTIONS);

        return isSuccess;
    }
    private _getSelectedFilesWithResult() {

        const selectedItems = this.multiSelectService.getItemsSelected();

        if (this.businessKey === 'Id')
            return this._getSelectedFilesFromQueueGrid(selectedItems);

        return this._getSelectedFilesFromResultGrid(selectedItems);
    }

    private _getSelectedFilesFromQueueGrid(selectedItems: any[]): MultiFileDeletionDto[] {

        const fileIdKey = this.getFileIdKey();

        return selectedItems.map(item => {

            var selectedItem = {
                fileId: item[fileIdKey],
                resultIndex: null
            } as MultiFileDeletionDto;

            return selectedItem;
        });
    }

    private _getSelectedFilesFromResultGrid(selectedItems: any[]): MultiFileDeletionDto[] {
        const fileIdKey = this.getFileIdKey();
        const fileWithIndexKey = 'File.file_index_id';
        const oldResultIndexKey = 'File.result_index';


        return selectedItems.map(item => {


            if (this.businessKey === 'File.file_id')
                return {
                    fileId: this.multiSelectService.isNested ? item[fileIdKey][0] : item[fileIdKey],
                    resultIndex: this.multiSelectService.isNested ? item[oldResultIndexKey][0] : item[oldResultIndexKey]
                } as MultiFileDeletionDto;



            const fileWithResultIndexData = this.multiSelectService.isNested ? item[fileWithIndexKey]?.[0] ?? '' : item[fileWithIndexKey] ?? '';
            const [fileId, resultIndex] = fileWithResultIndexData.split('_');

            return {
                fileId,
                resultIndex: Number(resultIndex)
            } as MultiFileDeletionDto;

        });
    }
    private getFileIdKey() {
        var newBusinessKey = ['File.file_id', 'File.file_index_id'];
        console.log('businessKey:', this.businessKey);

        var isResultGridIndex = newBusinessKey.includes(this.businessKey);

        console.log('isNewBusinessKey:', 'File.file_index_id' == this.businessKey);

        const fileIdKey = isResultGridIndex ? 'File.file_id' : 'Id';

        console.log('fileIdKey:', fileIdKey);

        return fileIdKey;
    }
}
