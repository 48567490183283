import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService, UserData, UserDataService } from '@discoverer/app-core';
import { AppSettingsService, BaseController, DiscovererQueryService } from '@discoverer/core';
import { environment } from '@env/environment';
import { distinctUntilChanged } from 'rxjs/operators';
import { DatasetTypes } from '../../models/dataset.model';
import { DatasetService } from '../../services/api/dataset.service';
import { EmitterService } from '../../services/emitter.service';
import { IToolbarSettings, ToolbarService } from '../../services/toolbar.service';
import { MultiFileUploaderComponent } from '../attoms/multi-file-uploader/multi-file-uploader.component';
import { GeneralSettingsService } from '../../services/general-settings.service';
import { SchemaService } from '../../services/schema.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent extends BaseController implements OnInit {
    user: UserData;
    toolbarSettings: IToolbarSettings = { showBackButton: false, title: 'Tables' };
    isSubTitleCopied: boolean = false;
    isEmailCopied = false;
    datasetId: string;
    totalFileCount: number;

    constructor(
        public queryService: DiscovererQueryService,
        public emitterService: EmitterService,
        private _userService: UserDataService,
        private _toolbarService: ToolbarService,
        private _router: Router,
        private _authService: AuthService,
        private _generalSettingsService: GeneralSettingsService,
        private schemaService: SchemaService,
        private _appSettingsService: AppSettingsService,
        private _dialog: MatDialog,
        public datasetService: DatasetService
    ) { super(); }

    async ngOnInit(): Promise<void> {
        await this._appSettingsService.setActiveApp(environment.auth.productKey);
        this.subscriptions.push(this._userService.loggedInUser
            .subscribe((user: UserData) => this.user = user));

        this.subscriptions.push(this._toolbarService.oToolbarSettings
            .subscribe((t: IToolbarSettings) => this.toolbarSettings = t));

        this.subscriptions.push(this.emitterService.oDatasetId.pipe(distinctUntilChanged())
            .subscribe(async (value: string) => {
                if (!!value) {

                    console.log('******************', value)
                    this._generalSettingsService.setDatasetId(value);
                    await this.schemaService.updateDatasetSchema(value);
                    // await this.schemaService.updateDatasetType(value);
                    this.datasetId = value;
                }
            }));

    }
    uploadDialog(datasetId: string) {
        this._dialog.open(MultiFileUploaderComponent, { width: '800px', data: { datasetId, datasetType: DatasetTypes.WORKSPACE }, disableClose: false }).afterClosed();
    }
    public signOut() {
        const redirectUrl = this._router.url;
        this._authService.goToLogin(redirectUrl);
    }

}
