import { Injectable } from '@angular/core';
import { DatasetService } from '@app/ignite/components/reports/services/api/dataset.service';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, take } from 'rxjs/operators';
import { ISidebarItem } from '../models';

@Injectable({ providedIn: 'root', })
export class VerticalSidebarService {

    private _lastRefreshAt$ = new BehaviorSubject<string>(new Date().toISOString());
    oLastRefreshAt = this._lastRefreshAt$.asObservable();

    public _sQueueLengthChange = new BehaviorSubject<number>(null);
    public oQueueLengthChange = this._sQueueLengthChange.asObservable();
    public _sResultsLengthChange = new BehaviorSubject<number>(null);
    public oResultsLengthChange = this._sResultsLengthChange.asObservable();

    constructor(
        private _datasetService: DatasetService) {
    }

    setLastRefreshAt(value: string) {
        this._lastRefreshAt$.next(value);
    }

    setQueueLength(value: number) {
        this._sQueueLengthChange.next(value);
    }

    setProcessedLength(value: number) {
        this._sResultsLengthChange.next(value);
    }

    async getDatasetCounts(datasetId: string) {
        const response = await this._datasetService.getCounts(datasetId).toPromise();
        if (response.isSuccess) {
            const { queueLength, resultLength, updatedAt } = response?.result.find(d => d.datasetId === datasetId);
            const updatedAtIso = new Date(updatedAt).toISOString();
            const lastRefreshAt = await this.oLastRefreshAt.pipe(debounceTime(0), take(1)).toPromise();
            if (updatedAtIso > lastRefreshAt) {
                this._handleQueueLengthChange(queueLength, updatedAtIso);
                this._handleResultLengthChange(resultLength, updatedAtIso);
            }

            return response?.result;
        } else {
            return [{ datasetId, queueLength: 0, resultLength: 0 }];
        }
    }

    private _handleQueueLengthChange(newQueueLength: number, updatedAtIso: string) {
        this._sQueueLengthChange.next(newQueueLength);
        this.setLastRefreshAt(updatedAtIso);
    }

    private _handleResultLengthChange(newResultsLength: number, updatedAtIso: string) {
        this._sResultsLengthChange.next(newResultsLength);
        this.setLastRefreshAt(updatedAtIso);
    }

    getSidebarItemById(sidebarItemId: string) {
        var item;
        DATASET_SIDEBAR_ITEMS.forEach(item => {
            if (!item?.children && (item.id === sidebarItemId)) item = item;
            if (item?.children) item.children.forEach(child => { if (child.id == sidebarItemId) item = child });
        });
        return item;
    }
}

export const DATASET_SIDEBAR_ITEMS: ISidebarItem[] = [
    {
        id: 'configuration', title: '', icon: null, children: [
            {
                id: 'schema', title: 'Fields',
                icon: 'heroicons_outline:view-columns'
                , route: 'schema'
            },
            {
                id: 'integrations', title: 'Connections',
                icon: 'link',
                route: 'integrations'
            },
            { id: 'settings', title: 'Settings', icon: 'settings', route: 'settings' }

        ]
    }, {
        id: 'pipeline', title: 'Datasets', icon: null, children: [
            { id: 'queue', title: 'Queue', icon: 'heroicons_outline:inbox-arrow-down', route: 'queue', rightBadge: '0', isLoading: false },
            { id: 'records', title: 'Records', icon: 'feather:database', route: 'records', rightBadge: '0', isLoading: false },
        ]
    }]

export const PIPELINE_CHOOSER_SIDEBAR_ITEMS: ISidebarItem[] = [
    {
        id: 'pipeline', title: 'Queue', icon: null, children: [
            { id: 'queue', title: 'Files', icon: 'heroicons_outline:inbox-arrow-down', route: 'queue', rightBadge: '0', isLoading: false }
        ]

    },
    {
        id: 'records', title: 'Records', icon: null, children: []
    }
]
