import { Clipboard } from '@angular/cdk/clipboard';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, UserData, UserDataService } from '@discoverer/app-core';
import { BaseController, DiscovererQueryService } from '@discoverer/core';
import { environment } from '@env/environment';
import { interval } from 'rxjs';
import { take } from 'rxjs/operators';
import { DatasetCountDto, DatasetsTypes } from '../../../models/dataset.model';
import { DatasetService } from '../../../services/api/dataset.service';
import { EmitterService } from '../../../services/emitter.service';
import { GeneralSettingsService } from '../../../services/general-settings.service';
import { SchemaService } from '../../../services/schema.service';
import { IToolbarSettings, ToolbarService } from '../../../services/toolbar.service';
import { MultiFileUploaderComponent } from '../../attoms/multi-file-uploader/multi-file-uploader.component';
import { EditTitleDialogComponent } from '../../home/edit-title-dialog/edit-title-dialog';
import { ISidebarItem } from './models';
import { DATASET_SIDEBAR_ITEMS, PIPELINE_CHOOSER_SIDEBAR_ITEMS, VerticalSidebarService } from './services/vertical-sidebar.service';



@Component({
    selector: 'app-vertical-sidebar',
    templateUrl: './vertical-sidebar.component.html',
    styleUrls: ['./vertical-sidebar.component.scss']
})
export class VerticalSidebarComponent extends BaseController implements OnInit {

    isUpdating = false;
    queueLength!: number;
    resultsLength!: number;
    // subs: Subscription[] = [];
    productKey = environment.auth.productKey;
    datasetId!: string;
    chooserDatasetId!: string; // this id will not change when we navigate to subDatasetId
    sidebarItems: ISidebarItem[] = [];
    user!: UserData;
    toolbarSettings: IToolbarSettings = { showBackButton: false, title: 'Dataset' };
    isEmailCopied = false;
    isSubTitleCopied = false;

    constructor(
        public queryService: DiscovererQueryService,
        public emitterService: EmitterService,
        private sidebarService: VerticalSidebarService,
        private toolbarService: ToolbarService,
        private userService: UserDataService,
        private _authService: AuthService,
        private datasetService: DatasetService,
        private router: Router,
        private dialog: MatDialog,
        private clipboard: Clipboard,
        private activatedRoute: ActivatedRoute,
        private schemaService: SchemaService,
        private _generalSettingsService: GeneralSettingsService,
    ) {
        super();
    }

    async ngOnInit(): Promise<void> {
        this.initializeSubscriptions();
        this.datasetId = this.chooserDatasetId = this.activatedRoute.snapshot.params['datasetId'];

        this.updateDatasetCounts();
        this.subscriptions.push(interval(4000).subscribe(async () => await this.updateDatasetCounts()));
        this.emitterService.setDatasetId(this.datasetId);
        this.schemaService.updateDatasetSchema(this.datasetId);

        this.subscriptions.push(this.emitterService.oDatasetId
            .subscribe(async (value: string) => {
                this._generalSettingsService.setDatasetId(value);
            }));
        //whenever we set the dataset id from other pages we will use it to fetch the count.
        this.subscriptions.push(this.emitterService.oDatasetId.subscribe(id => this.datasetId = id));
    }

    private initializeSubscriptions(): void {
        this.subscriptions.push(
            this.userService.loggedInUser.subscribe(user => this.user = user),
            this.toolbarService.oToolbarSettings.subscribe(settings => this.toolbarSettings = settings),
            this.datasetService.oDatasetDetails.subscribe(async details => {
                if (details?.Type === DatasetsTypes.PIPELINE_CHOOSER) {
                    const datasets = (await this.datasetService.getAll().toPromise()).result.filter(d => d.Type === DatasetsTypes.DATASET);
                    this.sidebarItems = PIPELINE_CHOOSER_SIDEBAR_ITEMS;
                    this.sidebarItems[1].children = datasets.map(d => ({
                        id: d.Id, title: d.Title, icon: 'feather:database', route: `${d.Id}/records`, rightBadge: String(d.ProcessedDocs), leftBadge: String(d.ProcessingDocs), isLoading: false
                    })
                    );
                } else {
                    this.sidebarItems = DATASET_SIDEBAR_ITEMS;
                }
            })
        );
    }
    public signOut() {
        const redirectUrl = this.router.url;
        this._authService.goToLogin(redirectUrl);
    }

    private async updateDatasetCounts(): Promise<void> {
        if (!this.datasetId) {
            console.warn("Dataset Id is not configured!");
            return;
        }
        const result = await this.sidebarService.getDatasetCounts(this.datasetId);
        const type = (await this.datasetService.oDatasetDetails.pipe(take(1)).toPromise()).Type;
        if (type === DatasetsTypes.DATASET) {
            this.updateBadgeForDataset(result[0]);
        } else {
            this.updateBadgesForPipeline(result);
        }
    }

    private updateBadgeForDataset(result: DatasetCountDto): void {
        const pipelineItem = this.sidebarItems.find(i => i.id === 'pipeline');
        pipelineItem?.children.forEach(child => {
            if (child.id === 'queue') child.rightBadge = String(result.queueLength);
            if (child.id === 'records') child.rightBadge = String(result.resultLength);
        });
    }

    private updateBadgesForPipeline(result: DatasetCountDto[]): void {
        const pipelineItem = this.sidebarItems.find(i => i.id === 'pipeline');
        const recordsItem = this.sidebarItems.find(i => i.id === 'records');

        pipelineItem?.children.forEach(child => {
            const res = result.find(r => r.datasetId === this.chooserDatasetId);
            child.leftBadge = String(res.queueLength);
            child.rightBadge = String(res.resultLength);
        });

        recordsItem?.children.forEach(child => {
            const res = result.find(r => r.datasetId === child.id);
            child.rightBadge = String(res.resultLength);
        });
    }

    public async editDataSetTitleDialog(): Promise<void> {
        const { Description: description } = await this.datasetService.oDatasetDetails.pipe(take(1)).toPromise();
        const datasetId = this.datasetId;
        const templateName = this.toolbarSettings?.title;

        const dlg = this.dialog.open(EditTitleDialogComponent, {
            width: '650px', data: { datasetId, templateName, description }, autoFocus: true
        });

        dlg.afterClosed().subscribe(async resp => {
            if (resp?.templateName) {
                const baseDatasetDetails = await this.datasetService.getCurrentBaseDatasetDetails();
                this.datasetService.updateDatasetDetails({ ...baseDatasetDetails, Title: resp.templateName, Description: resp.description });
                this.toolbarSettings.title = resp.templateName;
            }
        });
    }

    public async openImportDocument(): Promise<void> {
        const datasetType = (await this.datasetService.oDatasetDetails.pipe(take(1)).toPromise()).Type;
        this.dialog.open(MultiFileUploaderComponent, {
            width: '800px', data: { datasetId: this.datasetId, datasetType }, disableClose: false
        }).afterClosed().subscribe(() => this.queryService.refresh());
    }

    public back(): void {
        this.router.navigate(['datasets']);
    }

    public copyEmail(email: string, type: string): void {
        this.clipboard.copy(email);
        if (type === 'email') {
            this.isEmailCopied = true;
        } else if (type === 'subTitle') {
            this.isSubTitleCopied = true;
        }

        setTimeout(() => {
            if (type === 'email') {
                this.isEmailCopied = false;
            } else if (type === 'subTitle') {
                this.isSubTitleCopied = false;
            }
        }, 4000);
    }
}
