import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BackendResponse } from '../../models/common';
import { TaskDetails, TaskStatus } from '../../models/task.models';
import { BackendRouteEndpoint } from './constants/backend-route-constants';

@Injectable({
  providedIn: 'root'
})
export class TaskService {

  constructor(
    private httpClient: HttpClient
  ) { }
  // TODO: Add Type instead of any
  getDetails(taskId: string) {
    return this.httpClient.get<BackendResponse<TaskDetails>>(`${BackendRouteEndpoint.TASK_BASE_URL}/${taskId}`)
  }

  public async waitForTask(taskId: string, sleep_ms: number = 5000): Promise<boolean> {
    while (true) {
      const {isSuccess, result: taskDetails} = await this.getDetails(taskId).toPromise()
      if (!isSuccess) return false;
      if (taskDetails.status == TaskStatus.SUCCESS && taskDetails.successful) return true;
      if (taskDetails.status == TaskStatus.ERROR) return false;
      await this._sleep(sleep_ms);
    }
  }

  private _sleep(time_ms) {
    return new Promise(resolve => setTimeout(resolve, time_ms));
  }
}
