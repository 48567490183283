<div class="schema-form-wrapper h-full" cdkDropListGroup>

    <div class="flex w-full h-full items-start gap-2">
        <!-- Main content -->
        <div [ngClass]="hideFileViewer ? 'w-full' : 'w-7/12'">
            <div class="page-header-wrapper">
                <div class="page-header">
                    <ng-container >
                        <div class="flex gap-1 cursor-pointer items-center">
                            <b class="whitespace-nowrap">Fields</b>

                        </div>
                        <div class="pl-10 space-x-4 page-header-actions">
                            <div class="left-btn-actions">
                                <!-- Edit Button -->
                                <button mat-flat-button color="primary" *ngIf="!this.isEditMode && !this.isLoading"
                                    (click)="toggleEdit()">
                                    <mat-icon class="material-icons-outlined">edit</mat-icon>
                                    Edit
                                </button>
                                <!-- Save and Cancel Buttons -->
                                <ng-container *ngIf="(this.isEditMode || this.isLoading)">
                                    <button mat-flat-button color="primary" (click)="save()">
                                        <mat-icon *ngIf="!isLoading" class="material-icons-outlined">save</mat-icon>
                                        <mat-spinner *ngIf="isLoading" [diameter]="24"></mat-spinner>
                                        Save
                                    </button>
                                    <button mat-stroked-button class="basic-button" color="basic" (click)="cancel()"
                                        [disabled]="isLoading">
                                        <mat-icon class="material-icons-outlined close-but">close</mat-icon>
                                        Cancel
                                    </button>
                                </ng-container>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="main-fields">
                <div *ngIf="!isDataLoaded">
                    <mat-spinner [diameter]="24"></mat-spinner>
                </div>

                <div class="titles flex">
                    <div class="column-0 drag"></div>
                    <div class="column-1 title">Type</div>
                    <div class="column-2 title">Field Name</div>
                    <div class="column-3 title">Description</div>
                </div>


                <div class="main-schema-form w-full" *ngIf="isDataLoaded" cdkDropList
                    (cdkDropListDropped)="drop($event)">
                    <div *ngFor="let field of getMainFieldsControls(); let i = index">
                        <!-- Add a hidden horizontal line, made visible on hover -->
                        <div *ngIf="isEditMode && i !== getMainFieldsControls().length - 1" class="add-field-line-wrapper"
                            (click)="addFieldAtIndex(originalFields, i)">
                            <div class="add-field-line">
                                <hr>
                                <mat-icon>add_circle_outline</mat-icon>
                                <hr>
                            </div>
                        </div>
                        <field-detail *ngIf="field" [formGroup]="field" [index]="i" [isEditMode]="isEditMode"
                            [additionalItem]="isEditMode && i == (originalFields.length - 1)"
                            [objectFormGroup]="objectFormGroup"
                            (inputChange)="handleItemChange(originalFields, i, $event)"
                            (delete)="deleteField(originalFields, i)">
                        </field-detail>


                        <div class="nested-table"
                            *ngIf="field.controls.fields && !(isEditMode && i == (originalFields.length - 1))"
                            cdkDropList [id]="'nestedList-' + i" [cdkDropListData]="getNestedFieldsControls(field)"
                            [cdkDropListConnectedTo]="getConnectedLists()"
                            (cdkDropListDropped)="dropNested($event,field)">


                            <div class="titles-sub flex">
                                <div class="column-0 drag"></div>
                                <div class="column-1 title">Type</div>
                                <div class="column-2 title">Field Name</div>
                                <div class="column-3 title">Description</div>
                            </div>
                            <div *ngFor="let subField of getNestedFieldsControls(field); let nestedIndex = index">
                                <!-- Add a hidden horizontal line, made visible on hover -->
                                <div *ngIf="isEditMode && nestedIndex !== getNestedFieldsControls(field).length - 1 " class="add-field-line-wrapper"
                                    (click)="addFieldAtIndex(field.controls.fields, nestedIndex, i + '-' + nestedIndex)">
                                    <div class="add-field-line">
                                        <hr>
                                        <mat-icon>add_circle_outline</mat-icon>
                                        <hr>
                                    </div>
                                </div>
                                <field-detail [formGroup]="subField" [index]="nestedIndex" [parentIndex]="i" [isNested]="true"
                                    [isEditMode]="isEditMode"
                                    [additionalItem]="isEditMode && nestedIndex == (getNestedFieldsControls(field).length - 1)"
                                    [objectFormGroup]="objectFormGroup"
                                    (inputChange)="addItem(field.controls.fields, nestedIndex, i + '-' + nestedIndex, $event)"
                                    (delete)="deleteField(field.controls.fields, nestedIndex)"></field-detail>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="px-4 w-full">
                    <button class="auto-import" mat-button color="Primary"
                        (click)="openAutoGeneratedFieldsDialog()"><mat-icon
                            fontSet="material-icons-round">auto_awesome</mat-icon>Auto Import Fields</button>
                </div>
            </div>

        </div>
        <div [ngClass]="(hideFileViewer) ? '' : 'w-5/12'">
            <div class="flex flex-col w-full h-full">
                <ng-content select="[extraActions]"></ng-content>
                <ng-content select="[fileActions]"></ng-content>
            </div>
        </div>
    </div>
</div>
