import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Plugin, Schema, schema } from '@progress/kendo-angular-editor';
import { MentionsPlugin } from './plugins/mentions.service';

export const tagNodeSpec = {
  group: "inline",
  inline: true,
  atom: true,
  attrs: {
    promptDisplay: { default: "" },
    field: { default: "" }
  },
  selectable: false,
  draggable: false,
  toDOM: node => {
    return [
      "span",
      {
        "data-prompt-display": node.attrs.promptDisplay,
        "data-field": node.attrs.field,
        class: "prosemirror-tag-node"
      },
      node.attrs.promptDisplay
    ] as [string, { [key: string]: any }, string];
  },
  parseDOM: [
    {
      tag: "span[data-prompt-display]",
      getAttrs: dom => {
        var promptDisplay = dom.getAttribute("data-prompt-display");
        var field = dom.getAttribute("data-field");
        return {
          field: field,
          promptDisplay: promptDisplay
        };
      }
    }
  ]
};

const extendedNodes = schema.spec.nodes.append({
  tag: tagNodeSpec
});

@Component({
  selector: 'app-kendo-editor',
  templateUrl: './kendo-editor.component.html',
  styleUrls: ['./kendo-editor.component.scss'],
})
export class KendoEditorComponent implements OnDestroy, OnChanges, AfterViewInit {
  @Input() menuItems: Array<any>;
  @Input() value: string = '';
  @Output() change = new EventEmitter<{ html: string, text: string }>(true);

  @ViewChild('editor', { static: true }) editor!: any;
  public fieldsElement!: HTMLElement;
  public tools = [];
  public editorValue = '';


  mySchema = new Schema({
    nodes: extendedNodes,
    marks: schema.spec.marks,
  });

  public plugin: Plugin;

  constructor(private mentionsPlugin: MentionsPlugin) {
    this.editorValue = this.value;
    this.plugin = this.mentionsPlugin.getMentionsPlugin({
      hashtagTrigger: "/",
      getSuggestions: (type, text, done) => {
        let filteredItems = this.menuItems.filter((item) => item.display.toLowerCase().includes(text.toLowerCase()));
        done(filteredItems);
      },
      getSuggestionsHTML: (items, type) => {
        setTimeout(() => {
          this.setMenuPosition();
        }, 50);
        return this.getMentionSuggestionsHTML(items);
      }
    });
  }

  public myPlugins = (defaultPlugins: Plugin[]): Plugin[] => [
    this.plugin,
    ...defaultPlugins,
  ];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.menuItems && changes.menuItems.currentValue) {
      if (this.editor && this.editor.view) {
        this.updateEditorContentWithNodes(this.value);
      }
    }
  }

  onEditorChange(event: any) {
    let text = '';
    this.editor.view.state.doc.descendants((node) => {
      if (node.type.name === 'tag') {
        text += node.attrs.field;
      } else if (node.text) {
        text += node.text;
      }
    });
    this.hideMenuItems();
    this.change.emit({ html: event, text: text });
  }

  ngOnDestroy(): void {
    this.hideMenuItems();
    this.editorValue = '';
  }

  ngAfterViewInit(): void {
    if (this.value) {
      this.updateEditorContentWithNodes(this.value);
    }
  }

  setMenuPosition() {
    const menuElements = document.getElementsByClassName('suggestion-item-container');
    if (menuElements.length > 0) {
      const menuElement = menuElements[0] as HTMLElement;
      const position = this.calculateMenuPosition();
      menuElement.style.position = 'absolute';
      menuElement.style.top = `${position.top}px`;
      menuElement.style.left = `${position.left}px`;
      menuElement.style.display = 'block';
      menuElement.style.visibility = 'visible';
      menuElement.style.zIndex = '9999';
    }
  }

  private calculateMenuPosition() {
    const editorContainer = this.editor.element.nativeElement;
    const containerRect = editorContainer.getBoundingClientRect();

    const selection = this.editor.view.state.selection;
    const cursorCoords = this.editor.view.coordsAtPos(selection.to);

    const top = Math.max(0, cursorCoords.bottom - containerRect.top);
    const left = cursorCoords.left;

    const absoluteTop = top + containerRect.top;
    const absoluteLeft = left;

    return {
      top: absoluteTop,
      left: absoluteLeft
    };

  }

  private getMentionSuggestionsHTML(items) {
    if (items.length === 0) {
      return (
        '<div class="suggestion-item-list"> <div class="suggestion-item"> No results found </div></div>'
      );
    } else {
      return (
        '<div class="suggestion-item-list">' +
        items
          .map((item) => '<div class="suggestion-item"><div class=' + item.nested + '> <mat-icon role="img" class="icon-size-5 text-secondary mat-icon notranslate selected-icon material-icons mat-icon-no-color" aria-hidden="true" data-mat-icon-type="font">' + item.icon + '</mat-icon><span class="text-base font-normal">' + item.menuDisplay + '</span></div></div>')
          .join('') +
        '</div>'
      );
    }

  }

  private hideMenuItems() {
    const menuElements = document.getElementsByClassName('suggestion-item-container');
    if (menuElements.length > 0) {
      const menuElement = menuElements[0] as HTMLElement;
      menuElement.style.visibility = 'hidden';
      menuElement.remove();
    }
  }

  private updateEditorContentWithNodes(text: string): void {
    if (this.editor && this.editor.view) {
      const { state, dispatch } = this.editor.view;
      const nodes = this.convertTextToNodes(text);
      const fragment = state.schema.nodes.doc.create(null, nodes);
      const tr = state.tr.replaceWith(0, state.doc.content.size, fragment);
      dispatch(tr);
    }
  }

  private convertTextToNodes(text: string): any[] {
    const parts = text.split(/(\{\{[\w.]+\}\})/);
    const nodes = [];
    parts.map(part => {
      if (part.match(/\{\{[\w.]+\}\}/)) {
        const field = part;
        const display = this.menuItems.find(item => item.tag === field)?.display || part.replace(/\{\{|\}\}/g, '');
        nodes.push(this.editor.view.state.schema.nodes.tag.create({ field: field, promptDisplay: display }));
      } else if (part.length > 0) {
        nodes.push(this.editor.view.state.schema.text(part));
      }
    });
    return nodes;
  }

}
