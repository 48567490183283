import { Component, Input, OnInit } from '@angular/core';
@Component({
    selector: 'app-document-header-title',
    templateUrl: './document-header-title.component.html',
    styleUrls: ['./document-header-title.component.scss']
})
export class DocumentHeaderTitleComponent implements OnInit {
    @Input() isLoading: boolean = false;
    @Input() fileName: string;
    @Input() fileExtension: string;
    @Input() dateReceived: string;
    @Input() titles: string[] = [];
    public skeletonTheme = { height: '15px', opacity: '0.8', 'margin-bottom': '4px' }
    constructor() {
    }

    public async ngOnInit() {
    }
}
