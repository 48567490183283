<div class="w-full flex min-h-8 flex-shrink-0 items-center gap-2 border-b-2 py-4 pr-4">
    <h2 class="flex items-center justify-between text-balance text-lg font-semibold text-nightshade-950">
        <ng-container *ngIf="currentStep === 1">Create new connection</ng-container>
        <ng-container *ngIf="currentStep === 2">
            <button *ngIf="!disableGoBack" mat-icon-button class="mr-2" (click)="goBack()">
                <mat-icon>chevron_left</mat-icon>
            </button>
            {{!isEdit ? 'Configure ' : 'Edit '}} {{selectedTool?.display}}
        </ng-container>
    </h2>
    <button class="ml-auto" mat-icon-button (click)="onCancel()">
        <mat-icon>close</mat-icon>
    </button>
</div>

<div class="min-h-64 w-full overflow-auto pb-4">
    <!-- Step 1: Tool Selection -->
    <div *ngIf="currentStep === 1">
        <div *ngFor="let tool of tools" (click)="!tool.disabled && selectTool(tool)"
            class="group flex h-12 w-full cursor-pointer flex-row items-center justify-between gap-2 px-4 py-2"
            [class.opacity-50]="tool.disabled">
            <div class="flex flex-row items-center space-x-3">
                <img class="min-w-8 max-w-8" [src]="tool.icon">
                <p class="text-base font-normal">{{ tool.display }}</p>
            </div>
            <mat-icon class="text-nightshade-400 size-4 min-w-4 max-w-4">chevron_right</mat-icon>
        </div>
    </div>

    <!-- Step 2: Configuration -->
    <div *ngIf="currentStep === 2" class="p-4">
        <div class="w-full flex items-center justify-center" *ngIf="selectedTool.needsConnection">
            <mat-form-field class="w-full">
                <mat-label>Select Connection</mat-label>
                <mat-select [(ngModel)]="selectedDropdownValue">
                    <mat-option *ngFor="let option of dropdownOptions | filterConnectionsByKey: selectedTool.app"
                        [value]="option">
                        {{ option.connection_name }} / {{ option.display_name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <button (click)="addConnection()" mat-icon-button>
                <mat-icon>add</mat-icon>
            </button>

        </div>

        <div class="flex justify-end mt-4">
            <button mat-raised-button color="primary" [disabled]="!selectedDropdownValue" (click)="onSelectOption()">
                {{!isEdit ? 'Add' : 'Edit'}}
            </button>
        </div>
    </div>
</div>