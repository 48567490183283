import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BaseController } from '@discoverer/core';
import { Observable, ReplaySubject, Subject, zip } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { ColumnSetting } from '../../../models/column-settings';
import { DatasetFileService } from '../../../services/api/dataset-file.service';
import { DatasetService } from '../../../services/api/dataset.service';
import { IgniteTabService } from '../../../services/ignite-tab.service';
import { FileDetailsHandlerService } from '../../shared-pages/file-details-handler.service';

export type ResultField = { key: string, type: 'single' | 'section' | 'table' | 'document' | 'mat-divider', value: any, display: string, schema: ResultField[] };
export const DIVIDER_FIELD: ResultField = { key: 'divider', type: 'mat-divider', value: null, display: 'divider', schema: [] };
type Result = { schema: any, result: any, columnSettings: ColumnSetting[] };

@Component({
    selector: 'app-file-result-displayer',
    templateUrl: './file-result-displayer.component.html',
    styleUrls: ['./file-result-displayer.component.scss']
})
export class FileResultDisplayerComponent extends BaseController implements OnInit, OnDestroy {
    @Input() datasetId: string;
    @Input() fileId: string;
    @Input() resultIndex: number = 0;

    @Output() dataFetched: EventEmitter<any> = new EventEmitter<any>();
    @Output() fieldsPrepared: EventEmitter<any> = new EventEmitter<any>();

    fields: Observable<Array<ResultField>>;

    schema: Subject<any> = new ReplaySubject(1);
    result: Subject<any> = new ReplaySubject(1);
    columnSettings: Subject<ColumnSetting[]> = new ReplaySubject(1);
    limit: number = 100;
    loading: boolean = false;
    constructor(
        private _datasetService: DatasetService,
        private _datasetFileService: DatasetFileService,
        private _igniteTabService: IgniteTabService,
        public fileDetailsHandlerService: FileDetailsHandlerService
    ) { super() }


    ngOnDestroy(): void {

    }
    onScroll(event: any): void {
      const scrollPosition = event.target.scrollTop;
      const scrollHeight = event.target.scrollHeight;
      const clientHeight = event.target.clientHeight;

      // If we're near the bottom, load more items
      if (scrollHeight - (scrollPosition + clientHeight) < 100) {
            this.limit += 100;
      }
    }

    async ngOnInit(): Promise<void> {
        this.loading = true;
        this.fields =
            zip(
                this.schema,
                this.result,
                this.columnSettings)
                .pipe(map(value =>
                ({
                    schema: value[0],
                    result: value[1],
                    columnSettings: value[2]
                })))
                .pipe(map(this.extractDataFromResult.bind(this)));
        let result = (await this._datasetFileService.getResults(this.datasetId, this.fileId).toPromise()).result?.parser_0;
        this.fileDetailsHandlerService.setFileResults(result);
        this.fieldsPrepared.emit(this.fields);
        this.subscriptions.push(this.fileDetailsHandlerService.schemaService.oCurrentSchema.pipe(distinctUntilChanged()).subscribe(doc => {
            this.schema.next(doc);
            this.getResult()
            this.getColumnSettings();
        }));
    }

    private async getColumnSettings() {
        let columnSettings = (await this._igniteTabService.getColumns(this.datasetId));
        this.columnSettings.next(columnSettings);
    }

    public async getResult() {
        const result = await this.fileDetailsHandlerService.getFileResults()
        this.dataFetched.emit(result);
        this.result.next(result);
    }

    public getKeys(obj: any): string[] {
        return Object.keys(obj);
    }

    extractDataFromResult(data: Result): Array<ResultField> {
        if (!data.schema || !data.result || !data.columnSettings) {
            this.loading = false;
            console.warn('Some of required data empty', data);
            return;
        }

        let fields = []
        if (this.resultIndex >= 0) {
            fields = Object.keys(data.schema).map(key =>
                this.getFieldObject(key, data.schema, data.result[this.resultIndex])
            );
        } else {
            data.result.forEach((result, i) => {
                fields.push(...Object.keys(data.schema).map(key =>
                    this.getFieldObject(key, data.schema, result)
                ));
                (i !== data.result.length - 1) && fields.push(DIVIDER_FIELD);
            })

        }

        this.loading = false;
        return fields
    }

    private getFieldObject(key: string, schema: any, result: any): any {
        return {
            key,
            display: schema[key].display || key,
            type: this._getFieldType(schema[key].type),
            value: result[key] || result[key.toLocaleLowerCase()],
            schema: Object
                .keys(schema[key]['fields'] || {})
                .map((subKey: string) => this.getFieldObject(subKey, schema[key]['fields'], result[key] || {}))
        };
    }

    private _getFieldType(type: string) {
        switch (type) {
            case 'object':
                return 'section';
            case 'document':
                return 'document';
            case 'object_array':
                return 'table';
            default:
                return 'single';
        }
    }

    getDisplay(key: string): string {
        return this.schema[key].display || key;
    }
}
