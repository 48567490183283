
import { CurrencyPipe, DatePipe, DecimalPipe, PercentPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { FormattedNumberPipe } from '../../pipes';
import  moment from 'moment';
import { PhonePipe } from './phone-pipe';
import { IList } from '../classes';

@Injectable()
export class DataFormattingService {
    _locale = 'en-US';
    datePipe = new DatePipe('en-US');
    defaultDateYears = ['9999', '0001', '1000'];
    constructor() {

    }
    public getValue(value: any, columnType: string): string {
        let result = '-';
        if (Array.isArray(value)) {
            return (value as []).map(v => this.getValue(v, columnType) as string).join(', ');
        }
        if (value == null || value === '-') {
            return result;
        }


        switch (columnType) {
            case 'double':
                result = new DecimalPipe(this._locale).transform(parseFloat(value), '1.2-2');
                break;
            case 'int':
            case 'long':
            case 'number':
                result = value > 9999 ? new FormattedNumberPipe().transform(value, 'else') : '' + value;
                break;
            case 'date':
                const dateFormat =  'MM/dd/yyyy';
                result = new DatePipe(this._locale).transform(value, dateFormat);
                if (result == null || result === '12/31/9999') {
                    result = '-';
                }
                break;
            case 'timestamp':
                const format = 'MM/dd/yyyy h:mm a';
                const dateValue = new Date(value);
                result = dateValue.getFullYear() === 9999 || dateValue == null
                    ? '-'
                    : new DatePipe(this._locale).transform(new Date(dateValue.setMinutes(dateValue.getMinutes() - dateValue.getTimezoneOffset())), format);
                break;
            case 'timestamptz':
                const timestamptzFormat = 'MM/dd/yyyy hh:mm:ss a';
                result = new DatePipe(this._locale).transform(value, timestamptzFormat);
                if (result == null || result === '12/31/9999') {
                    result = '-';
                }
                break;
            case 'currency':
                result = '$' + new FormattedNumberPipe().transform(parseFloat(value), 'short' ?? '-');
                break;
            case 'percent':
                result = new PercentPipe(this._locale).transform(value, '1.2-2');
                break;
            case 'phone':
                result = new PhonePipe().transform(value);
                break;
            case 'dateRange':
                result = `${new DatePipe(this._locale).transform(value.slice(0, value.length / 2), 'yyyy-MM-dd')} TO ${new DatePipe(this._locale).transform(value.slice(value.length / 2 + 1), 'yyyy-MM-dd')}`;
                break;
            case 'link':
            case 'url':
            case 'website_link':
                result = value.toString();
                if (!result.startsWith('http://') && !result.startsWith('https://')) {
                    result = 'https://' + result;
                }
                break;
            default:
                result = value;
        }
        return result;
    }


    nFormatter(num: number, digits: number): string {
        let si = [
            { value: 1, symbol: '' },
            { value: 1E3, symbol: 'k' },
            { value: 1E6, symbol: 'm' },
            { value: 1E9, symbol: 'B' },
            { value: 1E12, symbol: 'T' },
            { value: 1E15, symbol: 'P' },
            { value: 1E18, symbol: 'E' }
        ];
        let rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
        let i;
        for (i = si.length - 1; i > 0; i--) {
            if (num >= si[i].value) {
                break;
            }
        }
        return (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol;
    }


    public getMetricsFormat(val, currency = false) {
        const suffixes = ['', 'K', 'M', 'G', 'T', 'P', 'E'];
        if (Number.isNaN(val)) {
            return null;
        }
        if (val && val < 1000 && val > -1000) {
            return (currency ? '$' : '') + val.toFixed(2);
        }
        const exp = Math.floor(Math.log(Math.abs(val)) / Math.log(1000));
        return (currency ? '$' : '') + ((val / Math.pow(1000, exp)).toFixed(2) + suffixes[exp]);
    }

    public getFormat(dataType: string) {
        switch (dataType) {
            case 'currency':
                return '{0:c2}';
            case 'int':
                return '##:#';
            case 'percent':
                return '{0:n2}%';
            case 'double':
                return '{0:n2}';
            case 'date':
            case 'dateRange':
                return '{0:dd-MM-yy}';
            default:
                return null;
        }
    }

    public deformatDate(val, gap): moment.Moment {
        let startResult: moment.Moment;
        if (gap === 'QUARTER') {
            const v = val.split(' ');
            startResult = moment(new Date(v[1] + ' GMT')).add(Math.floor((v[0].replace('Q', '') - 1) * 3), 'month');
        } else {
            startResult = moment(Date.parse(val + ' GMT'));
        }
        return startResult;
    }

}
