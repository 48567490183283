import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { Connection, AuthResponse, App } from './connection.interface';
import { UserDataService } from '@discoverer/app-core';
import { BackendRouteEndpoint } from '../../services/api/constants/backend-route-constants';

@Injectable({
    providedIn: 'root'
})
export class ConnectionService {

    private _connections$ = new Subject<Connection[]>();
    public connectionsList = this._connections$.asObservable();

    constructor(private http: HttpClient, private user: UserDataService) { }

    private getHeaders(token: string): HttpHeaders {
        return new HttpHeaders().set('Authorization', `Bearer ${token}`);
    }

    getApps(token: string): Observable<App[]> {
        return this.http.get<App[]>(`${BackendRouteEndpoint.ACCOUNT_CONNECTION_BASE_URL}/apps`,
            { headers: this.getHeaders(token) });
    }

    async getConnections(token?: string): Promise<Connection[]> {
        var token = token ? token : localStorage.getItem('access-token');
        var connectionsList = await (this.http.get<Connection[]>(`${BackendRouteEndpoint.ACCOUNT_CONNECTION_BASE_URL}/connections`,
            { headers: this.getHeaders(token) }).toPromise())
        this._connections$.next(connectionsList);
        return connectionsList;
    }

    authenticateConnection(appName: string, token: string): Observable<AuthResponse> {
        return this.http.get<AuthResponse>(
            `${BackendRouteEndpoint.ACCOUNT_CONNECTION_BASE_URL}/authenticate?app_name=${encodeURIComponent(appName)}`,
            { headers: this.getHeaders(token) }
        );
    }

    checkConnectionStatus(appName: string, connectionName: string, token: string): Observable<AuthResponse> {
        return this.http.get<AuthResponse>(
            `${BackendRouteEndpoint.ACCOUNT_CONNECTION_BASE_URL}/check-connection-status/${encodeURIComponent(connectionName)}/${encodeURIComponent(appName)}`,
            { headers: this.getHeaders(token) }
        );
    }

    deleteConnection(connectionName: string, token: string): Observable<void> {
        return this.http.delete<void>(
            `${BackendRouteEndpoint.ACCOUNT_CONNECTION_BASE_URL}/connections/${encodeURIComponent(connectionName)}`,
            { headers: this.getHeaders(token) }
        );
    }

    updateConnectionName(connectionId: number, newConnectionName: string, token: string): Observable<void> {
        return this.http.put<void>(
            `${BackendRouteEndpoint.ACCOUNT_CONNECTION_BASE_URL}/connections/${connectionId}`,
            { connection_name: newConnectionName },
            { headers: this.getHeaders(token) }
        );
    }
}
