export class BackendRouteEndpoint {
    static readonly DATASET_BASE_URL: string = `api/datasets`
    static readonly DATASET_FILE_BASE_URL: (datasetId: string) => string = (datasetId: string) => `api/datasets/${datasetId}/files`;
    static readonly TASK_BASE_URL: string = `api/tasks`
    static readonly STRIPE_BASE_URL: string = `api/stripe`
    static readonly ACCOUNT_TOKEN_BASE_URL: string = `api/accounts/token`
    static readonly DATASET_Action_BASE_URL: (datasetId: string) => string = (datasetId: string) => `api/datasets/${datasetId}/actions`;
    static readonly ACCOUNT_CONNECTION_BASE_URL: string = `oidc`
    static readonly DATASET_INTEGRATION_BASE_URL: (datasetId: string) => string = (datasetId: string) => `api/datasets/${datasetId}/integrations`;
}
