<div class="columns-settings" *ngIf="(reportPersist.oLastRequestData | async) as lastrequest">
    <ng-container *ngIf="canChangeColumnSettings && isAllowView(lastrequest?.mainView?.type)">
        <button *ngIf="lastrequest?.mainView?.type === 'kanban-view' else NotKanbanView;"
            (click)="openColumnsSettingsDialog(lastrequest?.mainView?.type)" color="accent" mat-button class="px-1">
            <mat-icon class="text-lg">
                settings
            </mat-icon> Settings
        </button>
        <ng-template #NotKanbanView>
            <!-- <mat-icon [matMenuTriggerFor]="menu" class="more_vert" color="accent">
                more_vert
            </mat-icon>
            <mat-menu #menu="matMenu"> -->
            <button (click)="openColumnsSettingsDialog(lastrequest?.mainView?.type, 'Display Columns')" mat-button
                class="px-1">
                <mat-icon class="text-lg">list</mat-icon> <span>Fields</span>
            </button>
            <button (click)="openColumnsSettingsDialog(lastrequest?.mainView?.type, 'Sorting')" mat-button class="px-1">
                <mat-icon class="text-lg">sort</mat-icon> <span>Sort</span>
            </button>
            <button (click)="openColumnsSettingsDialog(lastrequest?.mainView?.type, 'Grouping')" mat-button
                class="px-1">
                <mat-icon class="text-lg">compress</mat-icon><span>Group</span>
            </button>
            <button *ngIf="columnsToFlattenData.length > 0"
                (click)="openColumnsSettingsDialog(lastrequest?.mainView?.type, 'Data Flatten By')" mat-button
                class="px-1">
                <mat-icon class="text-lg">expand</mat-icon><span>Flatten</span>
            </button>
            <!-- </mat-menu> -->
        </ng-template>
    </ng-container>
</div>
