import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FilterConnectionsPipe } from './filter-connections.pipe';
import { ConnectionService } from '../../connections/connection.service';
import { BaseController } from '@discoverer/core';
import { WARN_SNACKBAR_OPTIONS } from '../../home/edit-title-dialog/edit-title-dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { IActionToolModel } from '../../../models/action.model';

class ToolConfig {
    key: string;
    display: string;
    app: string;
    agent: string;
    icon: string;
    disabled?: boolean;
    required?: any[]
    needsConnection?: boolean
}

export const TOOLS: ToolConfig[] =
    [ //TODO: this should read from DB
        { icon: 'assets/images/logo/outlook.webp', display: 'Booking Using Microsoft outlook', key: 'outlook_booking', app: 'microsoft_exchange', agent: 'parsd', disabled: false, needsConnection: true },
        { icon: 'assets/images/logo/google-calender.png', display: 'Booking Using Google Calendar', key: 'google_booking', app: 'google_exchange', agent: 'parsd', disabled: true, needsConnection: true },
        { icon: 'assets/images/logo/web-scraping.webp', display: 'Scrape', key: 'scrape', app: 'Scrape', agent: 'scrape', disabled: true, needsConnection: false }
        // { icon: 'assets', display: 'QuickBooks Online', key: 'quickbooks', app: 'quickbooks', agent: 'scrape', disabled: true },
    ];

@Component({
    selector: 'app-tool-selection-dialog',
    templateUrl: './tool-selection-dialog.component.html',
    styleUrls: ['./tool-selection-dialog.component.scss'],
    providers: [FilterConnectionsPipe]
})
export class ToolSelectionDialogComponent extends BaseController implements OnInit {
    tools = TOOLS;
    selectedTool: any;
    currentStep = 1;
    dropdownOptions = []
    selectedDropdownValue: any;
    checkAuthInterval: any;
    disableGoBack = false;
    toolIndex: number;
    isEdit = false;
    constructor(
        public dialogRef: MatDialogRef<ToolSelectionDialogComponent>,
        private _connectionService: ConnectionService,
        private _snackBar: MatSnackBar,
        @Inject(MAT_DIALOG_DATA) public data: { connections: any[], selectedTool: IActionToolModel, toolIndex: number }
    ) {
        super();
        this._connectionService.connectionsList.subscribe(connections => this.dropdownOptions = connections.filter(v => v?.app_name === this.selectedTool?.app));
        if (data.selectedTool) {
            this.selectedTool = data.selectedTool;
            this.currentStep = 2;
            this.disableGoBack = true;
            this.selectedTool.needsConnection = true;
            this.toolIndex = data.toolIndex;
            this.isEdit = true;
        }
    }
    async ngOnInit() {
        this.dropdownOptions = await this._connectionService.getConnections(localStorage.getItem('access-token'));
        if (this.selectedTool?.configuration?.connection) {
            this.selectedDropdownValue = this.dropdownOptions.find(v => v?.id == this.selectedTool?.configuration?.connection);
        }
    }

    selectTool(tool: ToolConfig) {
        this.selectedTool = tool;
        this.currentStep = 2;
        this.selectedDropdownValue = null;
    }

    onSelectOption() {
        this.dialogRef.close({
            tool: this.selectedTool,
            toolIndex: this.toolIndex,
            option: this.selectedDropdownValue
        });
    }
    goBack() {
        this.currentStep = 1;
        this.selectedTool = null;
        this.selectedDropdownValue = '';
    }
    addConnection() {
        const userToken = localStorage.getItem('access-token');
        this._connectionService.authenticateConnection(this.selectedTool.app, userToken).subscribe({
            next: (data) => {
                console.log('statusData1', data);
                if (data.status === 'needs_authentication') {
                    const popup = window.open(
                        data.auth_url,
                        'OAuth Login',
                        'width=600,height=800'
                    );

                    this.checkAuthInterval = setInterval(() => {
                        if (popup?.closed) {
                            clearInterval(this.checkAuthInterval);
                            return;
                        }

                        this._connectionService.authenticateConnection(this.selectedTool.app, userToken).subscribe(async statusData => {
                            if (statusData.status === 'authenticated') {
                                popup?.close();
                                clearInterval(this.checkAuthInterval);
                                this.dropdownOptions = await this._connectionService.getConnections(userToken)
                                this.selectedDropdownValue = this.dropdownOptions.filter(v => v?.app_name === this.selectedTool?.app)[0];
                            }
                        });
                    }, 2000);
                } else if (data.status === 'authenticated') {
                    this._showMsg('Connection already exists', WARN_SNACKBAR_OPTIONS);
                }
            },
            error: (error) => {
                console.error('Authentication failed:', error);
            }
        });
    }
    onCancel() {
        this.dialogRef.close();
    }
    private _showMsg(msg: string, config: MatSnackBarConfig) {
        this._snackBar.open(msg, undefined, config);
    }

}
