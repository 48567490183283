import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class MultiSelectService {
    public isDisabled = new BehaviorSubject(true);
    public oAllItems: BehaviorSubject<any[]> = new BehaviorSubject([]);
    public itemsSelected = [];
    public iteamsId = [];
    public tabBusinessKey;
    public defaultColumns;
    public isNested: boolean;
    public currentPageItems: any[] = [];
    public selectedItemsByPage: Map<number, any[]> = new Map();
    public selectedItemsIdsByPage: Map<number, any[]> = new Map();
    public currentPageIndex: number;

    constructor() { }

    public setCurrentPageItems(items: any[]) {
        this.currentPageItems = items;
    }

    public setCurrentPageIndex(index: number) {
        this.currentPageIndex = index;
    }

    public setTabBusinessKey(tabBusinessKey) {
        this.tabBusinessKey = tabBusinessKey;
    }

    public setIsNested(isNested: boolean) {
        this.isNested = isNested;
    }

    public setItemsSelected(objectChecked, selectAll = false, unselectAll = false, tab = '') {
        if (!this.selectedItemsIdsByPage.has(this.currentPageIndex)) {
            this.selectedItemsIdsByPage.set(this.currentPageIndex, []);
            this.selectedItemsByPage.set(this.currentPageIndex, []);
        }

        const currentIds = this.selectedItemsIdsByPage.get(this.currentPageIndex);
        const currentItems = this.selectedItemsByPage.get(this.currentPageIndex);

        if (unselectAll) {
            currentIds.length = 0;
            currentItems.length = 0;
        } else if (selectAll) {
            const allKeys = this.currentPageItems.map(item =>
                this._getObjectChckedWithKey(item)
            );
            this.selectedItemsIdsByPage.set(this.currentPageIndex, allKeys);
            this.selectedItemsByPage.set(this.currentPageIndex, [...this.currentPageItems]);
        }
        else {
            const objectCheckedWithKey = this._getObjectChckedWithKey(objectChecked);
            if (currentIds.includes(objectCheckedWithKey)) {
                this.selectedItemsIdsByPage.set(
                    this.currentPageIndex,
                    currentIds.filter(id => id !== objectCheckedWithKey)
                );
                this.selectedItemsByPage.set(
                    this.currentPageIndex,
                    currentItems.filter(obj => obj[this.tabBusinessKey] !== objectChecked[this.tabBusinessKey])
                );
            } else {
                // Add item
                if (tab) {
                    objectChecked.tab = tab;
                }
                currentIds.push(objectCheckedWithKey);
                currentItems.push(objectChecked);
            }
        }

        this.iteamsId = Array.from(
            Array.from(this.selectedItemsIdsByPage.values()).reduce(
                (acc, arr) => acc.concat(arr), []
            )
        );

        this.itemsSelected = Array.from(
            Array.from(this.selectedItemsByPage.values()).reduce(
                (acc, arr) => acc.concat(arr), []
            )
        );
        this.oAllItems.next(this.itemsSelected);
        this.isDisabled.next(this.iteamsId.length === 0);
    }

    public isItemChecked(objectChecked) {
        const objectCheckedWithKey = this._getObjectChckedWithKey(objectChecked);
        return (
            this.selectedItemsIdsByPage.has(this.currentPageIndex) &&
            this.selectedItemsIdsByPage.get(this.currentPageIndex)!.includes(objectCheckedWithKey)
        );
    }

    public getItemsSelected() {
        return this.itemsSelected;
    }

    public setListOfItems(items: any[]) {
        items.forEach(item => {
            this.setItemsSelected(item);
        })
    }

    public markListEmpty() {
        this.itemsSelected = [];
        this.iteamsId = [];
        this.currentPageIndex = 0;
        this.currentPageItems = [];
        this.selectedItemsByPage.clear();
        this.selectedItemsIdsByPage.clear();
        this.isDisabled.next(true);
        this.oAllItems.next(this.itemsSelected);
    }

    public isAllCurrentPageSelected() {
        const currentIds = this.selectedItemsIdsByPage.get(this.currentPageIndex) || [];
        if (this.currentPageItems.length === 0) return false;
        return this.currentPageItems.every(item => {
            const objectCheckedWithKey = this._getObjectChckedWithKey(item);
            return currentIds.includes(objectCheckedWithKey);
        });
    }

    private _getObjectChckedWithKey(objectChecked) {
        if (this.isNested) {
            return !!objectChecked[this.tabBusinessKey] ? objectChecked[this.tabBusinessKey][0] : null;
        } else {
            return objectChecked[this.tabBusinessKey];
        }
    }
}
