<disco-tab [appId]="'docpipeline'" [tabId]="datasetId" (context)="setContext($event)">
    <ng-template disco-tab-content>
        <div class="flex flex-col">
            <div class="body">
                <div *ngIf="loading" class="flex align-center justify-center px-8">
                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                </div>
                <div *ngIf="showSkeleton" class="px-8">
                        <ngx-skeleton-loader class="flex w-50" count="1" animation="progress-dark" [theme]="skeletonTheme">
                        </ngx-skeleton-loader>
                        <ngx-skeleton-loader class="flex w-full justify-between gap-8" count="2" animation="progress-dark" [theme]="skeletonTheme">
                        </ngx-skeleton-loader>
                        <ngx-skeleton-loader class="flex w-50" count="1" animation="progress-dark" [theme]="skeletonTheme">
                        </ngx-skeleton-loader>
                        <ngx-skeleton-loader class="flex w-full justify-between gap-8" count="2" animation="progress-dark" [theme]="skeletonTheme">
                        </ngx-skeleton-loader>
                        <ngx-skeleton-loader class="flex w-50" count="1" animation="progress-dark" [theme]="skeletonTheme">
                        </ngx-skeleton-loader>
                        <ngx-skeleton-loader class="flex w-full justify-between gap-8" count="2" animation="progress-dark" [theme]="skeletonTheme">
                        </ngx-skeleton-loader>
                        <ngx-skeleton-loader class="flex w-50" count="1" animation="progress-dark" [theme]="skeletonTheme">
                        </ngx-skeleton-loader>
                        <ngx-skeleton-loader class="flex w-full justify-between gap-8" count="2" animation="progress-dark" [theme]="skeletonTheme">
                        </ngx-skeleton-loader>
                        <ngx-skeleton-loader class="flex w-50" count="1" animation="progress-dark" [theme]="skeletonTheme">
                        </ngx-skeleton-loader>
                </div>

                <app-file-result-editor *ngIf="showFileResultEditor" [data]="newRecord" [datasetId]="datasetId"
                    (checkValidity)="checkValid($event)"></app-file-result-editor>
            </div>
            <div class="flex flex-row justify-end mt-4">
                <button class="flex gap-2" mat-flat-button color="primary" (click)="save()"
                    [disabled]="loading || !valid">
                    <mat-spinner *ngIf="loading" [diameter]="20"></mat-spinner>
                    Save
                </button>
            </div>
        </div>
    </ng-template>
</disco-tab>