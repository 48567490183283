import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DatasetFileService } from '../../../services/api/dataset-file.service';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { EmitterService } from '../../../services/emitter.service';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { SUCCESS_SNACKBAR_OPTIONS, WARN_SNACKBAR_OPTIONS } from '../../home/edit-title-dialog/edit-title-dialog';
import { fileIconMap } from '../../../models/constants';
import { DatasetsTypes } from '../../../models/dataset.model';

export interface IFileDetails {
    id: string
    name: string
    extension: string
    icon: string
    status?: FileUploadStatus
    uploadPercentage?: number
}

enum FileUploadStatus {
    UPLOADING = 'uploading',
    COMPLETE = 'complete',
    ERROR = 'error'
}

@Component({
    selector: 'app-multi-file-uploader',
    templateUrl: './multi-file-uploader.component.html',
    styleUrls: ['./multi-file-uploader.component.scss']
})
export class MultiFileUploaderComponent implements OnInit {

    isUploading: boolean;
    fileList: IFileDetails[] = [];
    datasetId: string
    renderdView: 'upload' | 'manual' = 'upload';
    datasetType = DatasetsTypes.DATASET
    datasetTypes = DatasetsTypes
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { datasetId: string, datasetType: DatasetsTypes },
        public dialogRef: MatDialogRef<MultiFileUploaderComponent>,
        private _datasetFileService: DatasetFileService,
        private _emitterService: EmitterService,
        private _snackbar: MatSnackBar,
    ) { }

    ngOnInit(): void {
        this.datasetId = this.data.datasetId
        this.datasetType = this.data.datasetType || DatasetsTypes.DATASET;
        if (!this.datasetId) this._emitterService.oDatasetId.subscribe((val) => this.datasetId = val)
    }

    closeDialog() {
        this.dialogRef.close();
    }

    async processUploadedFiles(files: FileList) {
        for (let i = 0; i < files.length; i++) {
            const fileName = `${files[i].name}`;

            const isFileExists: boolean = !!this.fileList.find(fl => fl.id === fileName);
            const isFileUploadComplete = !!this.fileList.find(fl => fl.id === fileName && fl.status === FileUploadStatus.COMPLETE);
            if (isFileUploadComplete) { this._showMsg(`File ${files[i].name} already uploaded`, WARN_SNACKBAR_OPTIONS); return; }

            const lastDotIndex = fileName.lastIndexOf('.');
            const nameWithoutExtension = fileName.substring(0, lastDotIndex);
            const extension = fileName.substring(lastDotIndex + 1);

            this.fileList.push({ id: fileName, name: fileName, extension: extension, icon: this.getFileIcon(extension) });

            let formData = new FormData();
            formData.append(`inputFile`, files[i]);
            this._datasetFileService.uploadWithProgress(this.datasetId, formData, this.datasetType).subscribe(
                (eve) => {
                    if (eve.type === HttpEventType.UploadProgress) {
                        this.fileList.find(fl => fl.id == fileName).uploadPercentage = Math.round(100 * eve.loaded / eve.total);
                        this.fileList.find(fl => fl.id == fileName).status = FileUploadStatus.UPLOADING
                        this.isUploading = true;
                    } else if (eve instanceof HttpResponse) {
                        this.fileList.find(fl => fl.id == fileName).status = FileUploadStatus.COMPLETE
                        // this.isUploading = false;
                        this._showMsg(`File ${files[i].name} uploaded successfully`, SUCCESS_SNACKBAR_OPTIONS);
                    }
                },
                () => {
                    this.fileList.find(fl => fl.id == fileName).uploadPercentage = -1;
                    this.fileList.find(fl => fl.id == fileName).status = FileUploadStatus.ERROR
                },
                () => {
                    this.isUploading = false;
                }
            );
        }
    }

    getFileIcon(fileExt: string): string {
        return fileIconMap.get(fileExt) || fileExt;
    }

    private _showMsg(msg: string, config: MatSnackBarConfig) {
        this._snackbar.open(msg, 'close', config);
    }
}
